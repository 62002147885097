import { useState, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { authenticationService } from "../services/authentication.service";

import logo from "../assets/images/logo.png";
import mobileBg from "../assets/images/mobile-bg.png";

const styles = {
    mobileBg: {
        backgroundImage: "url('../assets/images/mobile-bg.jpg')",
        backgroundSize: "cover",
    },
    mobileLogin: {
        backgroundColor: "#fff",
        border: "2px solid #e1b144",
        borderRadius: "10px",
    },
    mobileLogo: {
        display: "none",
    },
};

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const passwordRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (event: React.FormEvent) => {
        if (loading === false) {
            authenticationService.login(username, password).then(
                (user) => {
                    window.location.href = "/";
                },
                (error) => {
                    setLoading(false);
                    setError(JSON.stringify(error));
                }
            );
            setLoading(true);
        }
        event.preventDefault();
        return false;
    };

    return (
        <div className="loading auth-fluid-pages pb-0">
            <div className="auth-fluid">
                <div className="auth-fluid-right">
                    <div className="auth-user-testimonial">
                        <h3 className="mb-3 text-white">
                            ERETURNSMADEEASY IS AN INCREDIBLY EASY EFILE
                            SERVICE.
                        </h3>
                        <p className="lead fw-normal">
                            eReturnsMadeEasy is specifically tailored for
                            process servers to batch file pleadings (i.e.
                            Returns of Service) in seconds. Batch file 5, 10, or
                            100 pleadings at once by simply dropping them.
                        </p>
                        <p className="lead fw-normal">
                            The pleadings will be filed with the state ePortal
                            and served to the service list. Robust, real-time
                            reporting gives you complete access to the status
                            and information.
                        </p>
                        <h5 className="text-white">- eReturnsMadeEasy</h5>
                    </div>
                </div>
                <div className="auth-fluid-form-box">
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body">
                            <div className="auth-brand text-center text-lg-start">
                                <div className="auth-logo">
                                    <a
                                        href="/"
                                        className="logo logo-dark text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                width="100%"
                                                src={logo}
                                                alt="eReturnsMadeEasy"
                                            />
                                        </span>
                                    </a>
                                    <a
                                        href="index.html"
                                        className="logo logo-light text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                src="../assets/images/logo-light.png"
                                                alt=""
                                                height="22"
                                            />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <Form action="#" onSubmit={handleSubmit}>
                                <div className="mb-2">
                                    <label
                                        htmlFor="username"
                                        className="form-label"
                                    >
                                        Username
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        required={true}
                                        placeholder="Enter your username"
                                        onChange={(event: React.FormEvent) => {
                                            const usernameInput =
                                                event.currentTarget as HTMLInputElement;
                                            setUsername(usernameInput.value);
                                        }}
                                    />
                                </div>
                                <div className="mb-2">
                                    <a
                                        href="/forgot-password"
                                        className="text-muted float-end"
                                    >
                                        <small>Forgot your password?</small>
                                    </a>
                                    <label
                                        htmlFor="password"
                                        className="form-label"
                                    >
                                        Password
                                    </label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            ref={passwordRef}
                                            type="password"
                                            id="password"
                                            className="form-control"
                                            placeholder="Enter your password"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const passwordInput =
                                                    event.currentTarget as HTMLInputElement;
                                                setPassword(
                                                    passwordInput.value
                                                );
                                            }}
                                        />
                                        <div
                                            className="input-group-text"
                                            data-password="false"
                                            onClick={() => {
                                                if (
                                                    passwordRef.current!
                                                        .type === "password"
                                                )
                                                    passwordRef.current!.type =
                                                        "text";
                                                else {
                                                    passwordRef.current!.type =
                                                        "password";
                                                }
                                            }}
                                        >
                                            <span className="password-eye"></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-grid text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {loading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        )}
                                        {!loading && <>Log In</>}
                                    </button>
                                </div>
                                <div className="d-grid text-center">
                                    {error}
                                </div>
                            </Form>
                            <p
                                className="text-muted"
                                style={{ marginTop: "10px" }}
                            >
                                Don't have an account?{" "}
                                <a
                                    href="https://www.efilemadeeasy.com/signup/"
                                    className="text-primary fw-medium ms-1"
                                    target="_blank"
                                >
                                    Sign Up
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="login-bullets">
                        <ul>
                            <li>
                                Batch process: Drop all of your returns at
                                once... in seconds!
                            </li>
                            <li>
                                Integrate with your current case management
                                system
                            </li>
                            <li>Add value to your processing service</li>
                            <li>No Offshoring...ever!</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
