import axios from "axios";
import { serviceOptions as submissionServiceOptions } from "../models/swagger/generated-submission-service";
import { serviceOptions as dataServiceOptions } from "../models/swagger/generated-data-service";
import { serviceOptions as recordsServiceOptions } from "../models/swagger/generated-records-service";
import { serviceOptions as clientServiceOptions } from "../models/swagger/generated-client-service";
import { serviceOptions as eReturnServiceOptions } from "../models/swagger/generated-ereturn-service";
import qs from "qs";
import { authenticationService } from "../services/authentication.service";
import { Endpoints } from "./Endpoints";
import axiosRetry from "axios-retry";

export const submissionApiInstance = axios.create({
    baseURL: Endpoints.SubmssionServices, //process.env.REACT_APP_API_BASE,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "repeat" }),
});

export const eReturnApiInstance = axios.create({
    baseURL: Endpoints.EReturnService, //process.env.REACT_APP_API_BASE,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "repeat" }),
});

export const dataApiInstance = axios.create({
    baseURL: Endpoints.DataServices, //process.env.REACT_APP_API_BASE,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "repeat" }),
});

export const recordsApiInstance = axios.create({
    baseURL: Endpoints.CaseRecords, //process.env.REACT_APP_API_BASE,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "repeat" }),
});

export const clientApiInstance = axios.create({
    baseURL: Endpoints.ClientServices, //process.env.REACT_APP_API_BASE,
    paramsSerializer: (p) => qs.stringify(p, { arrayFormat: "repeat" }),
});

submissionServiceOptions.axios = submissionApiInstance;
dataServiceOptions.axios = dataApiInstance;
recordsServiceOptions.axios = recordsApiInstance;
clientServiceOptions.axios = clientApiInstance;
eReturnServiceOptions.axios = eReturnApiInstance;

export function setApiToken() {
    const currentUser = authenticationService.currentUserValue;

    submissionApiInstance.defaults.headers.common[
        "Access-Control-Allow-Origin"
    ] = "*";

    if (currentUser && currentUser.authToken) {
        submissionApiInstance.defaults.headers.common.Authorization = `Bearer ${currentUser.authToken}`;
        dataApiInstance.defaults.headers.common.Authorization = `Bearer ${currentUser.authToken}`;
        recordsApiInstance.defaults.headers.common.Authorization = `Bearer ${currentUser.authToken}`;
        clientApiInstance.defaults.headers.common.Authorization = `Bearer ${currentUser.authToken}`;
        eReturnApiInstance.defaults.headers.common.Authorization = `Bearer ${currentUser.authToken}`;

        submissionApiInstance.defaults.headers.common["X-User-AccessToken"] =
            currentUser.accessToken;
        submissionApiInstance.defaults.headers.common["X-User-RefreshToken"] =
            currentUser.refreshToken;

        eReturnApiInstance.defaults.headers.common["X-User-AccessToken"] =
            currentUser.accessToken;
        eReturnApiInstance.defaults.headers.common["X-User-RefreshToken"] =
            currentUser.refreshToken;

        dataApiInstance.defaults.headers.common["X-User-AccessToken"] =
            currentUser.accessToken;
        dataApiInstance.defaults.headers.common["X-User-RefreshToken"] =
            currentUser.refreshToken;

        recordsApiInstance.defaults.headers.common["X-User-AccessToken"] =
            currentUser.accessToken;
        recordsApiInstance.defaults.headers.common["X-User-RefreshToken"] =
            currentUser.refreshToken;

        clientApiInstance.defaults.headers.common["X-User-AccessToken"] =
            currentUser.accessToken;
        clientApiInstance.defaults.headers.common["X-User-RefreshToken"] =
            currentUser.refreshToken;
    } else {
        delete submissionApiInstance.defaults.headers.common.Authorization;
        delete dataApiInstance.defaults.headers.common.Authorization;
        delete recordsApiInstance.defaults.headers.common.Authorization;
        delete clientApiInstance.defaults.headers.common.Authorization;
        delete eReturnApiInstance.defaults.headers.common.Authorization;

        delete submissionApiInstance.defaults.headers.common[
            "X-User-AccessToken"
        ];
        delete submissionApiInstance.defaults.headers.common[
            "X-User-RefreshToken"
        ];

        delete eReturnApiInstance.defaults.headers.common["X-User-AccessToken"];

        delete eReturnApiInstance.defaults.headers.common[
            "X-User-RefreshToken"
        ];

        delete dataApiInstance.defaults.headers.common["X-User-AccessToken"];
        delete dataApiInstance.defaults.headers.common["X-User-RefreshToken"];

        delete recordsApiInstance.defaults.headers.common["X-User-AccessToken"];
        delete recordsApiInstance.defaults.headers.common[
            "X-User-RefreshToken"
        ];

        delete clientApiInstance.defaults.headers.common["X-User-AccessToken"];
        delete clientApiInstance.defaults.headers.common["X-User-RefreshToken"];
    }

    submissionServiceOptions.axios?.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            let retry = 1;
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(JSON.stringify(error));
            if (error.response.status === 401) {
                return authenticationService
                    .refreshToken()
                    .then(
                        (_) => {
                            setApiToken();
                            error.config.headers["Authorization"] =
                                "Bearer " +
                                authenticationService.currentUserValue
                                    .authToken;
                            return axios.request(error.config);
                        },
                        (error) => {
                            //console.log(JSON.stringify(error));
                            //authenticationService.logout();
                            //window.location.href = "/login";
                        }
                    )
                    .catch((reason) => {
                        //console.log(JSON.stringify(reason));
                        //authenticationService.logout();
                        //window.location.href = "/login";
                    });
            } else if (error.response.status === 504 && retry > 0) {
                console.log("504 retrying");
                retry = retry - 1;
                return axios.request(error.config);
            }

            return Promise.reject(error);
        }
    );

    eReturnServiceOptions.axios?.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            let retry = 1;
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(JSON.stringify(error));
            if (error.response.status === 401) {
                return authenticationService
                    .refreshToken()
                    .then(
                        (_) => {
                            setApiToken();
                            error.config.headers["Authorization"] =
                                "Bearer " +
                                authenticationService.currentUserValue
                                    .authToken;
                            return axios.request(error.config);
                        },
                        (error) => {
                            //console.log(JSON.stringify(error));
                            //authenticationService.logout();
                            //window.location.href = "/login";
                        }
                    )
                    .catch((reason) => {
                        //console.log(JSON.stringify(reason));
                        //authenticationService.logout();
                        //window.location.href = "/login";
                    });
            } else if (error.response.status === 504 && retry > 0) {
                console.log("504 retrying");
                retry = retry - 1;
                return axios.request(error.config);
            }

            return Promise.reject(error);
        }
    );

    clientServiceOptions.axios?.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error) {
            let retry = 1;
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(JSON.stringify(error));
            if (error.response.status === 401) {
                return authenticationService
                    .refreshToken()
                    .then(
                        (_) => {
                            setApiToken();
                            error.config.headers["Authorization"] =
                                "Bearer " +
                                authenticationService.currentUserValue
                                    .authToken;
                            return axios.request(error.config);
                        },
                        (error) => {
                            console.log(JSON.stringify(error));
                            authenticationService.logout();
                            window.location.href = "/login";
                        }
                    )
                    .catch((reason) => {
                        //console.log(JSON.stringify(reason));
                        //authenticationService.logout();
                        //window.location.href = "/login";
                    });
            } else if (error.response.status === 504 && retry > 0) {
                console.log("504 retrying");
                retry = retry - 1;
                return axios.request(error.config);
            }

            return Promise.reject(error);
        }
    );

    axiosRetry(dataServiceOptions.axios!, {
        retries: 3,
        retryCondition: (error) => {
            // if retry condition is not specified, by default idempotent requests are retried
            console.log("axios retry error");
            console.log(error);
            return error?.response?.status === 504;
        },
    });

    dataServiceOptions.axios?.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            //console.log(response.headers);
            if (response.headers && response.headers["x-user-authtoken"]) {
                currentUser.authToken = response.headers["x-user-authtoken"];
            }
            return response;
        },
        function (error) {
            let retry = 1;
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(JSON.stringify(error));
            if (
                error.response.status === 401 &&
                error.config.url.indexOf("Users/me") > -1
            ) {
                return authenticationService
                    .refreshToken()
                    .then(
                        (_) => {
                            setApiToken();
                            error.config.headers["Authorization"] =
                                "Bearer " +
                                authenticationService.currentUserValue
                                    .authToken;
                            return axios.request(error.config);
                        },
                        (error) => {
                            //console.log(JSON.stringify(error));
                            authenticationService.logout();
                            window.location.href = "/login";
                        }
                    )
                    .catch((reason) => {
                        //console.log(JSON.stringify(reason));
                        authenticationService.logout();
                        window.location.href = "/login";
                    });
            } else if (error.response.status === 504 && retry > 0) {
                console.log("504 retrying");
                retry = retry - 1;
                return axios.request(error.config);
            }

            return Promise.reject(error);
        }
    );

    axiosRetry(recordsServiceOptions.axios!, {
        retries: 3,
        retryCondition: (error) => {
            // if retry condition is not specified, by default idempotent requests are retried
            return error?.response?.status === 504;
        },
    });

    recordsServiceOptions.axios?.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            //console.log(response.headers);
            if (response.headers && response.headers["x-user-authtoken"]) {
                currentUser.authToken = response.headers["x-user-authtoken"];
            }

            return response;
        },
        function (error) {
            let retry = 1;
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            console.log(JSON.stringify(error));
            if (error.response.status === 401) {
                return authenticationService
                    .refreshToken()
                    .then(
                        (_) => {
                            setApiToken();
                            error.config.headers["Authorization"] =
                                "Bearer " +
                                authenticationService.currentUserValue
                                    .authToken;
                            return axios.request(error.config);
                        },
                        (error) => {
                            //console.log(JSON.stringify(error));
                            //authenticationService.logout();
                            //window.location.href = "/login";
                        }
                    )
                    .catch((reason) => {
                        //console.log(JSON.stringify(reason));
                        //authenticationService.logout();
                        //window.location.href = "/login";
                    });
            } else if (error.response.status === 504 && retry > 0) {
                console.log("504 retrying");
                retry = retry - 1;
                return axios.request(error.config);
            }

            return Promise.reject(error);
        }
    );
}
