import * as Services from "../models/swagger/generated-client-service";

export const ClientService = {
    me: Services.UsersService.me,

    myFirms: Services.FirmsService.my,

    firmAttorneys: Services.AttorneysService.firm,

    getDocType: Services.RulesService.documenttype2,

    getAddresses: Services.RulesService.addresses,

    getFees: Services.RulesService.fees,

    getFirms: Services.FirmsService.firms1,

    getParty: Services.RulesService.party,
};

export const AttorneyService = {
    get: Services.AttorneysService.attorneys2,

    saveProfile: Services.AttorneysService.saveprofile,

    serviceContacts: Services.ServiceContactService.attorney,

    addServiceContacts: Services.ServiceContactService.serviceContact1,

    updateServiceContacts: Services.ServiceContactService.serviceContact3,

    deleteServiceContacts: Services.ServiceContactService.serviceContact4,

    getFTPInfo: Services.FtpInfoService.attorney,

    saveAutomation: Services.AttorneysService.saveautomation,

    savePendingQueue: Services.AttorneysService.savependingqueue,

    saveService: Services.AttorneysService.saveservice,

    caseNumberRegex: Services.CaseNumberRegexService.attorney,
    addCaseNumberRegex: Services.CaseNumberRegexService.caseNumberRegex1,
    updateCaseNumberRegex: Services.CaseNumberRegexService.caseNumberRegex3,
    deleteCaseNumberRegex: Services.CaseNumberRegexService.caseNumberRegex4,

    correctionQueue: Services.AttorneysService.correctionqueue,

    //getPortalConfigurations: Services.AttorneysService.portalConfiguration,
};

export const PortalConfigurationService = {
    getList: Services.PortalConfigurationService.getlist,

    add: Services.PortalConfigurationService.add,

    update: Services.PortalConfigurationService.update,

    getCredentials: Services.PortalConfigurationService.getCredentials,

    delete: Services.PortalConfigurationService.portalConfiguration4,
};

export const OrganizationService = {
    getList: Services.OrganizationsService.organizations,

    get: Services.OrganizationsService.organizations2,

    add: Services.OrganizationsService.organizations1,

    update: Services.OrganizationsService.organizations3,

    delete: Services.OrganizationsService.organizations4,

    users: Services.OrganizationsService.users,
};

export const UserService = {
    register: Services.UsersService.register,

    verify: Services.UsersService.verify,

    getList: Services.UsersService.users1,

    add: Services.UsersService.users,

    update: Services.UsersService.users2,

    delete: Services.UsersService.users4,

    roles: Services.RolesService.roles,

    me: Services.UsersService.me,

    forgotPassword: Services.UsersService.forgotPassword,

    resetPassword: Services.UsersService.resetPassword,
};

export const DocumentRulesService = {
    getRule: Services.DocumentTypeRulesService.rule,

    getList: Services.DocumentTypeRulesService.attorney,

    update: Services.DocumentTypeRulesService.documentTypeRules3,

    add: Services.DocumentTypeRulesService.documentTypeRules1,

    delete: Services.DocumentTypeRulesService.documentTypeRules4,
};

export const CaseTypeRulesService = {
    getRule: Services.CaseTypeRulesService.rule,

    getList: Services.CaseTypeRulesService.attorney,

    update: Services.CaseTypeRulesService.caseTypeRules3,

    add: Services.CaseTypeRulesService.caseTypeRules1,

    delete: Services.CaseTypeRulesService.caseTypeRules4,
};

export const DivisionRulesService = {
    getRule: Services.DivisionRulesService.rule,

    getList: Services.DivisionRulesService.attorney,

    update: Services.DivisionRulesService.divisionRules3,

    add: Services.DivisionRulesService.divisionRules1,

    delete: Services.DivisionRulesService.divisionRules4,
};

export const MessageRulesService = {
    getRule: Services.MessageRulesService.rule,

    getList: Services.MessageRulesService.attorney,

    update: Services.MessageRulesService.messageRules3,

    add: Services.MessageRulesService.messageRules1,

    delete: Services.MessageRulesService.messageRules4,
};

export const FeeRuleService = {
    getRule: Services.FeeRuleService.rule,

    getList: Services.FeeRuleService.attorney,

    get: Services.FeeRuleService.feeRule,

    update: Services.FeeRuleService.feeRule3,

    add: Services.FeeRuleService.feeRule1,

    delete: Services.FeeRuleService.feeRule4,
};
