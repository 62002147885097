import { BehaviorSubject } from "rxjs";
import APIResponse from "../models/responses/APIResponse";
import Token from "../models/Token";
import { Endpoints } from "../helpers/Endpoints";
import { Globals } from "../helpers/globals";
import { setApiToken } from "../helpers/setupApis";

const currentUserSubject = new BehaviorSubject(
    JSON.parse(localStorage.getItem("currentUser")!)
);

export const authenticationService = {
    login,
    refreshToken,
    logout,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() {
        return currentUserSubject.value;
    },
};

async function login(
    username: string,
    password: string
): Promise<Token | null> {
    const requestOptions = {
        method: "POST",
        //  mode: "cors" as RequestMode,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            username,
            password,
            organizationId: "5BE7B393-3E05-4EF1-9A1A-BC4323D2734C",
        }),
    };

    return fetch(Endpoints.ClientServices + "/api/users/login", requestOptions)
        .then((response) => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    authenticationService.logout();
                    window.location.reload();
                }
            }
            return response.json().then((data) => data as APIResponse<Token>);
        })
        .then(async (apiResponse) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes

            localStorage.setItem(
                "currentUser",
                JSON.stringify(apiResponse.data)
            );
            currentUserSubject.next(apiResponse.data);

            setApiToken();
            await Globals.retrieveMe();

            return apiResponse.data;
        });
}

function refreshToken(): Promise<Token | null> {
    const currentUser = authenticationService.currentUserValue;

    const requestOptions = {
        method: "GET",
        //  mode: "cors" as RequestMode,
        headers: {
            "Content-Type": "application/json",
            "X-User-AuthToken": currentUser.authToken,
            "X-User-AccessToken": currentUser.accessToken,
            "X-User-RefreshToken": currentUser.refreshToken,
        },
    };

    return fetch(
        Endpoints.ClientServices + "/api/users/refresh",
        requestOptions
    )
        .then((response) => {
            console.log(response);
            if (!response.ok) {
                authenticationService.logout();
                window.location.reload();
            }
            return response.json().then((data) => data as APIResponse<Token>);
        })
        .then(async (apiResponse) => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            if (apiResponse.successful && apiResponse.data) {
                localStorage.setItem(
                    "currentUser",
                    JSON.stringify(apiResponse.data)
                );
                currentUserSubject.next(apiResponse.data);

                setApiToken();
            } else {
                authenticationService.logout();
                window.location.reload();
            }
            //await Globals.retrieveMe();

            return apiResponse.data;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");

    currentUserSubject.next(null);

    Globals.removeMe();
}
