import { Endpoints } from "../../helpers/Endpoints";
import { authenticationService } from "../../services/authentication.service";
import SearchControl from "./SearchController";

export default function Topbar() {
    const currentUser = authenticationService.currentUserValue;
    return (
        <div className="navbar-custom">
            <div className="container-fluid">
                <ul className="list-unstyled topnav-menu float-end mb-0">
                    {/*

                    <li
                        className="d-none d-lg-block"
                        style={{ width: "400px" }}
                    >
                        <SearchControl />
                        
                    </li>
                    <li className="dropdown d-inline-block d-lg-none">
                        <a
                            className="nav-link dropdown-toggle arrow-none waves-effect waves-light"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-haspopup="false"
                            aria-expanded="false"
                        >
                            <i className="fe-search noti-icon"></i>
                        </a>
                        <div className="dropdown-menu dropdown-lg dropdown-menu-end p-0">
                            <form className="p-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search ..."
                                    aria-label="Search"
                                />
                            </form>
                        </div>
                    </li>
*/}

                    <li className="dropdown notification-list topbar-dropdown">
                        <a
                            className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                            data-bs-toggle="dropdown"
                            href="#"
                            role="button"
                            aria-haspopup="false"
                            aria-expanded="false"
                        >
                            <img
                                src={`${Endpoints.ClientServices}/api/users/icon/${currentUser.userID}`}
                                alt="user-image"
                                className="rounded-circle"
                            />
                            <span className="pro-user-name ms-1">
                                {currentUser.firstName}&nbsp;
                                {currentUser.lastName}{" "}
                                <i className="mdi mdi-chevron-down"></i>
                            </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-end profile-dropdown ">
                            <div className="dropdown-header noti-title">
                                <h6 className="text-overflow m-0">Welcome !</h6>
                            </div>

                            <a
                                href="javascript:void(0);"
                                className="dropdown-item notify-item"
                            >
                                <i className="ri-account-circle-line"></i>
                                <span>My Account</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a
                                href="javascript:void(0);"
                                onClick={() => {
                                    authenticationService.logout();
                                    window.location.href = "/login";
                                }}
                                className="dropdown-item notify-item"
                            >
                                <i className="ri-logout-box-line"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </li>
                </ul>

                <div className="logo-box">
                    <a href="index.html" className="logo logo-dark text-center">
                        <span className="logo-sm">
                            <img
                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                alt="eFileMadeEasy"
                                height="32"
                            />
                        </span>
                        <span className="logo-lg">
                            <img
                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                alt="eFileMadeEasy"
                                height="60"
                            />
                        </span>
                    </a>

                    <a
                        href="index.html"
                        className="logo logo-light text-center"
                    >
                        <span className="logo-sm">
                            <img
                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                alt="eFileMadeEasy"
                                height="32"
                            />
                        </span>
                        <span className="logo-lg">
                            <img
                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                alt=""
                                height="60"
                            />
                        </span>
                    </a>
                </div>
                {/*}
                <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                    <li>
                        <button
                            onClick={() => {
                                const sideBarSize =
                                    localStorage.getItem("sidebar-size");
                                localStorage.setItem(
                                    "sidebar-size",
                                    !sideBarSize || sideBarSize === "default"
                                        ? "condensed"
                                        : "default"
                                );
                            }}
                            className="button-menu-mobile waves-effect waves-light"
                        >
                            <i className="fe-menu"></i>
                        </button>
                    </li>
                      
                    <li>
                        <a
                            className="navbar-toggle nav-link"
                            data-bs-toggle="collapse"
                            data-bs-target="#topnav-menu-content"
                        >
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>
                    </li>
                </ul>
                  */}
                <div className="clearfix"></div>
            </div>
        </div>
    );
}
