import React, { useEffect } from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";
import "reflect-metadata";

import { history } from "./helpers/history";
import { authenticationService } from "./services/authentication.service";

import "./assets/libs/admin-resources/jquery.vectormap/jquery-jvectormap-1.2.2.css";
import "./assets/css/modern/bootstrap-modern.css";
import "./assets/css/modern/app-modern.css";
import "./assets/css/icons.css";
import "./assets/libs/dropzone/min/dropzone.min.css";
import "./App.css";

import UnAuthLayout from "./layouts/unauth-layout";
import AuthLayout from "./layouts/auth-layout";

import { setApiToken } from "./helpers/setupApis";

function App() {
    const currentUser = authenticationService.currentUserValue;

    // 1. Set up the browser history with the updated location
    // (minus the # sign)
    const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
    if (path) {
        history.replace(path);
    }

    setApiToken();

    useEffect(() => {
        setApiToken();
    }, []);

    return (
        <BrowserRouter>
            {currentUser && <AuthLayout />}
            {!currentUser && <UnAuthLayout />}
        </BrowserRouter>
    );
}

export default App;
