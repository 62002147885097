import Footer from "../components/shared/Footer";
import FileManager from "../components/shared/FileManager";
import DailyReportWidget from "../components/shared/DailyReportWidget";

export default function Home() {
    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box page-title-box-alt">
                            <h4 className="page-title">Uploads</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">
                                            eReturnMadeEasy
                                        </a>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Upload
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <FileManager />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl-12">
                        <DailyReportWidget />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
