import { Endpoints } from "../helpers/Endpoints";
import { authenticationService } from "../services/authentication.service";

export const fileService = {
    get,
    upload,
};

function get({
    submissionId,
    documentId,
    source,
}: {
    submissionId: string;
    documentId: string;
    source: string | null;
}): Promise<Response> {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser != null) {
        const requestOptions = {
            method: "GET",
            //  mode: "cors" as RequestMode,
            headers: { Authorization: `${currentUser.authToken}` },
        };

        return fetch(
            `${
                Endpoints.FileManager
            }?key=${submissionId}/${documentId}&source=${source ?? ""}`,
            requestOptions
        );
    }

    return fetch(`${Endpoints.FileManager}`);
}

async function upload(
    params: {
        /**  */
        file: any;
        filename: string;
    } = {} as any
): Promise<Response> {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser != null) {
        const urlResponse = await uploadUrl({
            uid: currentUser.userID,
            key: params.filename,
        });

        const UrlData = await urlResponse.json();

        let data = null;
        data = new FormData();
        const multipleFiles =
            Object.prototype.toString.call(params["file"]) === "[object Array]";
        if (params["file"]) {
            if (multipleFiles) {
                for (const item of params["file"]) {
                    data.append("file", item as any);
                }
            }
        }

        let contentType = "application/octet-stream";
        if (params.filename.indexOf(".pdf") > 0)
            contentType = "application/pdf";

        const requestOptions = {
            method: "PUT",
            //mode: "no-cors" as RequestMode,
            headers: {
                "Content-Type": contentType,
            },

            body: multipleFiles ? data : params["file"],
        };

        return fetch(
            UrlData.upload_url,
            // `https://xx0yydmcx3.execute-api.us-east-1.amazonaws.com/Prod?uid=${currentUser.userID}&key=${params.filename}`,
            requestOptions
        );
    }

    return fetch(`${Endpoints.FileManager}`, { method: "GET" });
}

function uploadUrl({ uid, key }: { uid: string; key: string }) {
    const currentUser = authenticationService.currentUserValue;
    if (currentUser != null) {
        const requestOptions = {
            method: "POST",
            //  mode: "cors" as RequestMode,
            headers: {
                Authorization: `${currentUser.authToken}`,
                "Content-Type": "application/json",
            },
        };

        return fetch(
            `${Endpoints.FileManager}?uid=${uid}&key=${key}`,
            requestOptions
        );
    }

    return fetch(`${Endpoints.FileManager}`);
}
