import { useState, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { RegisterRequest } from "../models";
import { UserService } from "../services/ClientService";

export default function Register() {
    const [request, setRequest] = useState(new RegisterRequest());
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);

    const handleSubmit = (event: React.FormEvent) => {
        if (loading === false) {
            UserService.register({ body: request }).then(
                (resp) => {
                    if (resp.successful) {
                        window.location.href = "/register-success";
                    } else {
                        setError(resp.message ?? "");
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            setLoading(true);
        }
        event.preventDefault();
        return false;
    };

    return (
        <div className="loading auth-fluid-pages pb-0">
            <div className="auth-fluid">
                <div className="auth-fluid-right">
                    <div className="auth-user-testimonial">
                        <h3 className="mb-3 text-white">
                            EFILEMADEEASY IS AN INCREDIBLY EASY EFILE SERVICE.
                        </h3>
                        <p className="lead fw-normal">
                            <i className="mdi mdi-format-quote-open"></i>{" "}
                            eFileMadeEasy is a unique, one of a kind service,
                            that eFiles with the Florida E-Portal and eServes
                            your service list with the required service by
                            email. With a few mouse clicks your document is
                            completely prepared, filed and served. No more back
                            and forth between programs, selecting files,
                            scanning documents multiple times, typing in email
                            addresses, and the other time wasting steps required
                            to eFile. Save time, reduce mistakes, and do what
                            you do best practice law.
                            <i className="mdi mdi-format-quote-close"></i>
                        </p>
                        <h5 className="text-white">- eFileMadeEasy</h5>
                    </div>
                </div>
                <div
                    className="auth-fluid-form-box"
                    style={{ maxWidth: "700px" }}
                >
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body" style={{ paddingTop: 0 }}>
                            <div className="text-center text-lg-start">
                                <div className="auth-logo">
                                    <a
                                        href="index.html"
                                        className="logo logo-dark text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                width="256"
                                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                                alt="eFileMadeEasy"
                                            />
                                        </span>
                                    </a>
                                    <a
                                        href="index.html"
                                        className="logo logo-light text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                src="../assets/images/logo-light.png"
                                                alt=""
                                                height="22"
                                            />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <h4 className="mt-0">Sign Up</h4>
                            <p className="text-muted mb-4">
                                Don't have an account? Create your own account,
                                it takes less than a minute
                            </p>
                            <Form action="#" onSubmit={handleSubmit}>
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="fname"
                                            className="form-label"
                                        >
                                            First Name
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="fname"
                                            required={true}
                                            placeholder="First Name"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    firstName: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="lname"
                                            className="form-label"
                                        >
                                            Last Name
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="lname"
                                            required={true}
                                            placeholder="Last Name"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    lastName: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label
                                        htmlFor="firmName"
                                        className="form-label"
                                    >
                                        Firm Name
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="firmName"
                                        required={true}
                                        placeholder="Enter your firm name"
                                        onChange={(event: React.FormEvent) => {
                                            const input =
                                                event.currentTarget as HTMLInputElement;
                                            setRequest({
                                                ...request,
                                                firmName: input.value,
                                            });
                                        }}
                                    />
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-8">
                                        <label
                                            htmlFor="address1"
                                            className="form-label"
                                        >
                                            Street Address
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="address1"
                                            required={true}
                                            placeholder="Street Address"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    streetName: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label
                                            htmlFor="address2"
                                            className="form-label"
                                        >
                                            Unit #
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="address2"
                                            required={true}
                                            placeholder="Unit #"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    unitName: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-4">
                                        <label
                                            htmlFor="city"
                                            className="form-label"
                                        >
                                            City
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="city"
                                            required={true}
                                            placeholder="City"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    city: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label
                                            htmlFor="state"
                                            className="form-label"
                                        >
                                            State
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="state"
                                            required={true}
                                            placeholder="State"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    state: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label
                                            htmlFor="zip"
                                            className="form-label"
                                        >
                                            Zip Code
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="zip"
                                            required={true}
                                            placeholder="Zip Code"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    zip: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="email"
                                            className="form-label"
                                        >
                                            Email Address
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="Email Address"
                                            required={true}
                                            placeholder="Email Address"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    email: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="username"
                                            className="form-label"
                                        >
                                            Username
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            id="username"
                                            required={true}
                                            placeholder="Username"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const input =
                                                    event.currentTarget as HTMLInputElement;
                                                setRequest({
                                                    ...request,
                                                    username: input.value,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="password"
                                            className="form-label"
                                        >
                                            Password
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <input
                                                ref={passwordRef}
                                                type="password"
                                                id="password"
                                                className="form-control"
                                                placeholder="Password"
                                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$"
                                                onChange={(
                                                    event: React.FormEvent
                                                ) => {
                                                    const input =
                                                        event.currentTarget as HTMLInputElement;
                                                    setRequest({
                                                        ...request,
                                                        password: input.value,
                                                    });
                                                    setConfirmPassword("");
                                                }}
                                            />
                                            <div
                                                className="input-group-text"
                                                data-password="false"
                                                onClick={() => {
                                                    if (
                                                        passwordRef.current!
                                                            .type === "password"
                                                    )
                                                        passwordRef.current!.type =
                                                            "text";
                                                    else {
                                                        passwordRef.current!.type =
                                                            "password";
                                                    }
                                                }}
                                            >
                                                <span className="password-eye"></span>
                                            </div>
                                        </div>
                                        <ul>
                                            <li>8 - 15 Characters</li>
                                            <li>One number required</li>
                                            <li>
                                                One special character required
                                            </li>
                                            <li>
                                                One uppercase letter required
                                            </li>
                                            <li>
                                                One lowercase letter required
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <label
                                            htmlFor="confirmPassword"
                                            className="form-label"
                                        >
                                            Confirm Password
                                        </label>
                                        <div className="input-group input-group-merge">
                                            <input
                                                ref={confirmPasswordRef}
                                                type="password"
                                                id="confirmPassword"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                onChange={(
                                                    event: React.FormEvent
                                                ) => {
                                                    const passwordInput =
                                                        event.currentTarget as HTMLInputElement;
                                                    setConfirmPassword(
                                                        passwordInput.value
                                                    );
                                                    if (
                                                        passwordInput.value !==
                                                        request.password
                                                    ) {
                                                        passwordInput.setCustomValidity(
                                                            "Passwords Don't Match"
                                                        );
                                                    } else {
                                                        passwordInput.setCustomValidity(
                                                            ""
                                                        );
                                                    }
                                                }}
                                            />
                                            <div
                                                className="input-group-text"
                                                data-password="false"
                                                onClick={() => {
                                                    if (
                                                        confirmPasswordRef.current!
                                                            .type === "password"
                                                    )
                                                        confirmPasswordRef.current!.type =
                                                            "text";
                                                    else {
                                                        confirmPasswordRef.current!.type =
                                                            "password";
                                                    }
                                                }}
                                            >
                                                <span className="password-eye"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-grid text-center mb-2">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {loading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        )}
                                        {!loading && <>Sign Up</>}
                                    </button>
                                </div>
                                <div className="d-grid text-center">
                                    {error}
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
