import * as Services from "../models/swagger/generated-submission-service";
import { fileService } from "./file.service";

export const FileService = {
    upload: fileService.upload,
    get: fileService.get,
    list: Services.FileUploadService.list,
    delete: Services.FileUploadService.delete,
};

export const SubmissionService = {
    submit: Services.QueueService.submit,
    submitDFS: Services.QueueService.dfs,
    cancel: Services.QueueService.cancel,
    cancelAll: Services.QueueService.cancelAll,
    fail: Services.QueueService.fail,
    hold: Services.QueueService.hold,
    viewDocument: Services.DocumentsService.view,
    uploadExisting: Services.EFileService.existing,
    uploadNewCase: Services.EFileService.newCase,
    uploadBatch: Services.EFileService.batch,
    uploadOrder: Services.EFileService.order,
    get: Services.SubmissionDetailService.submissionDetail2,
    autofile: Services.QueueService.attorney2,
    sync: Services.QueueService.sync,
    syncByAttorney: Services.QueueService.attorney1,
    update: Services.SubmissionDetailService.submissionUpdate,
    search: Services.SubmissionDetailService.searchSubmissions,
};

export const QueueService = {
    getQueue: Services.QueueService.queue,
    getQueueByAttorney: Services.QueueService.byAttorney,
    getQueueCount: Services.QueueService.count,
    get: Services.QueueService.submission,
    next: Services.QueueService.next,
    hide: Services.QueueService.hide,
    reloadCase: Services.QueueService.submission1,
};

export const ReportService = {
    getSubmissionsToday: Services.ReportingService.today,
    getUploadsToday: Services.UploadsService.today,
    getExistingToday: Services.ReportingService.existing,
    getNewToday: Services.ReportingService.new,
    getDfsToday: Services.ReportingService.dfs,
    getMonthly: Services.ReportingService.monthly,
    getByState: Services.ReportingService.portalIdentifier,
    getLast90days: Services.ReportingService.last90Days,
    getEFileReporting: Services.ReportingService.efilereport,
};

export const DocumentService = {
    getPageText: Services.DocumentsService.page,
};

export const eFileService = {
    begin: Services.EFileService.begin,
    get: Services.EFileService.eFile,
};

export const EmailService = {
    add: Services.EmailService.email,
    update: Services.EmailService.email1,
    delete: Services.EmailService.email2,
    getAll: Services.EmailService.emails,
};

export const PartyService = {
    add: Services.PartyService.party,
    update: Services.PartyService.party1,
    delete: Services.PartyService.party2,
    getAll: Services.PartyService.parties,
};

export const CaseDataService = {
    update: Services.CaseDataDetailService.submission,
};

export const EmailReciptService = {
    add: Services.EmailRecieptService.emailReciept1,
    getList: Services.EmailRecieptService.submission,
    sendReceipts: Services.EmailRecieptService.resend,
};
