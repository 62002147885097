/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class EReturnsService {
  /**
   *
   */
  static ereturn(
    params: {
      /** requestBody */
      body?: eFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eReturns/ereturn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static today(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      itemsPerPage?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportSubmissionPagedResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eReturns/today';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], ItemsPerPage: params['itemsPerPage'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ReportSubmissionPagedResult, response)), reject);
    });
  }
}

export class ValuesService {
  /**
   *
   */
  static values(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Values';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static values1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Values';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static values2(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Values/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static values3(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Values/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static values4(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Values/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class BooleanDetailedResponse {
  /**  */
  'data'?: boolean;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class eFile {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadTypeKey'?: number;

  /**  */
  @Expose()
  'userID'?: string;

  /**  */
  'submittedBy'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadTypeKey'] = data['uploadTypeKey'];
    this['userID'] = data['userID'];
    this['submittedBy'] = data['submittedBy'];
    this['attorneyID'] = data['attorneyID'];
    this['attorneyName'] = data['attorneyName'];
    this['filename'] = data['filename'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class ReportSubmission {
  /**  */
  'filename'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'matterNumber'?: string;

  /**  */
  'statusDescription'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'referenceNumber'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedDateTime'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'filedDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['filename'] = data['filename'];
    this['caseNumber'] = data['caseNumber'];
    this['matterNumber'] = data['matterNumber'];
    this['statusDescription'] = data['statusDescription'];
    this['attorneyName'] = data['attorneyName'];
    this['referenceNumber'] = data['referenceNumber'];
    this['uploadedDateTime'] = data['uploadedDateTime'];
    this['filedDateTime'] = data['filedDateTime'];
  }

  public static validationModel = {};
}

export class ReportSubmissionPagedResult {
  /**  */
  'data'?: ReportSubmission[];

  /**  */
  @Expose()
  'totalCount'?: number;

  /**  */
  @Expose()
  'currentPage'?: number;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['totalCount'] = data['totalCount'];
    this['currentPage'] = data['currentPage'];
  }

  public static validationModel = {};
}
