/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AttorneysService {
  /**
   *
   */
  static byv1(
    params: {
      /**  */
      uid: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/byv1/{uid}';
      url = url.replace('{uid}', params['uid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static directory(
    params: {
      /**  */
      directory: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/directory/{directory}';
      url = url.replace('{directory}', params['directory'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      directory: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/update/{directory}';
      url = url.replace('{directory}', params['directory'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalConfiguration(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/portalConfiguration';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PortalConfigurationListDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static portalConfiguration1(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: PortalConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/portalConfiguration';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalConfigurationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static firm(
    params: {
      /**  */
      firmId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/Firm/{firmId}';
      url = url.replace('{firmId}', params['firmId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorneys(
    params: {
      /** requestBody */
      body?: Attorney;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorneys1(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static saveprofile(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: AttorneyProfileRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/saveprofile';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static saveservice(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: AttorneyServiceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/saveservice';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static saveautomation(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: AttorneyAutomationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/saveautomation';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static savependingqueue(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: AttorneyPendingQueue;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/savependingqueue';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static correctionqueue(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CorrectionQueueListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{attorneyId}/correctionqueue';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CorrectionQueueListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorneys2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorneys3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Attorney;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorneys4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Attorneys/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyAPIResponse, response)), reject);
    });
  }
}

export class CaseNumberRegexService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseNumberRegex(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseNumberRegex1(
    params: {
      /** requestBody */
      body?: CaseNumberRegex;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseNumberRegex2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseNumberRegex3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CaseNumberRegex;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseNumberRegex4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseNumberRegexAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseNumberRegex/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseNumberRegexAPIResponse, response)), reject);
    });
  }
}

export class CaseTypeRulesService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rule(
    params: {
      /** requestBody */
      body?: CaseTypeRuleTestRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules/rule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypeRules(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypeRules1(
    params: {
      /** requestBody */
      body?: CaseTypeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypeRules2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypeRules3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CaseTypeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypeRules4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleAPIResponse, response)), reject);
    });
  }
}

export class DivisionRulesService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rule(
    params: {
      /** requestBody */
      body?: DivisionRuleTestRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules/rule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static divisionRules(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static divisionRules1(
    params: {
      /** requestBody */
      body?: DivisionRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static divisionRules2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static divisionRules3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DivisionRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static divisionRules4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DivisionRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleAPIResponse, response)), reject);
    });
  }
}

export class DocumentTypeRulesService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rule(
    params: {
      /** requestBody */
      body?: DocumentTypeRuleTestRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules/rule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documentTypeRules(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DocumentTypeRuleIEnumerableAPIResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static documentTypeRules1(
    params: {
      /** requestBody */
      body?: DocumentTypeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documentTypeRules2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documentTypeRules3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DocumentTypeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documentTypeRules4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentTypeRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleAPIResponse, response)), reject);
    });
  }
}

export class FeeRuleService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rule(
    params: {
      /** requestBody */
      body?: FeeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule/rule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static feeRule(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static feeRule1(
    params: {
      /** requestBody */
      body?: FeeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static feeRule2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static feeRule3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: FeeRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static feeRule4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FeeRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FeeRule/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FeeRuleAPIResponse, response)), reject);
    });
  }
}

export class FirmsService {
  /**
   *
   */
  static firms(
    params: {
      /** requestBody */
      body?: Firm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FirmAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Firms';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static firms1(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FirmIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Firms';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static my(options: IRequestOptions = {}): Promise<FirmListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/my';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static firms2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FirmAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Firms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static firms3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Firm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FirmAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Firms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static firms4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FirmAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Firms/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FirmAPIResponse, response)), reject);
    });
  }
}

export class FtpInfoService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static ftpInfo(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static ftpInfo1(
    params: {
      /** requestBody */
      body?: FTPInfo;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static ftpInfo2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static ftpInfo3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: FTPInfo;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static ftpInfo4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FTPInfoAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FTPInfo/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FTPInfoAPIResponse, response)), reject);
    });
  }
}

export class MessageRulesService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rule(
    params: {
      /** requestBody */
      body?: MessageRuleTestRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules/rule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static messageRules(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static messageRules1(
    params: {
      /** requestBody */
      body?: MessageRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static messageRules2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static messageRules3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: MessageRule;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static messageRules4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageRuleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageRules/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(MessageRuleAPIResponse, response)), reject);
    });
  }
}

export class OrganizationsService {
  /**
   *
   */
  static registerUser(
    params: {
      /** requestBody */
      body?: UserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations/RegisterUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static users(
    params: {
      /**  */
      organizationId: string;
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations/{organizationId}/users';
      url = url.replace('{organizationId}', params['organizationId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(UserResponseIEnumerableDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static organizations(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OrganizationIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static organizations1(
    params: {
      /** requestBody */
      body?: Organization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OrganizationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static organizations2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OrganizationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static organizations3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Organization;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OrganizationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static organizations4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrganizationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Organizations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OrganizationAPIResponse, response)), reject);
    });
  }
}

export class PortalConfigurationService {
  /**
   *
   */
  static update(
    params: {
      /**  */
      portalConfigId: string;
      /** requestBody */
      body?: PortalConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationRequestDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/update/{portalConfigId}';
      url = url.replace('{portalConfigId}', params['portalConfigId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PortalConfigurationRequestDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static add(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: PortalConfigurationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationRequestDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/add/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PortalConfigurationRequestDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static getlist(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationRequestListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/getlist/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PortalConfigurationRequestListDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static getCredentials(
    params: {
      /**  */
      portalConfigId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalCredentialsDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/getCredentials/{portalConfigId}';
      url = url.replace('{portalConfigId}', params['portalConfigId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalCredentialsDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalConfiguration(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PortalConfigurationIEnumerableAPIResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static portalConfiguration1(
    params: {
      /** requestBody */
      body?: PortalConfiguration;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalConfigurationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalConfiguration2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalConfigurationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalConfiguration3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PortalConfiguration;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalConfigurationAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalConfiguration4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PortalConfigurationAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PortalConfiguration/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PortalConfigurationAPIResponse, response)), reject);
    });
  }
}

export class RolesService {
  /**
   *
   */
  static roles(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Roles';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RoleIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static roles1(
    params: {
      /** requestBody */
      body?: Role;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Roles';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RoleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static roles2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Roles/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RoleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static roles3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Role;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Roles/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RoleAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static roles4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RoleAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Roles/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(RoleAPIResponse, response)), reject);
    });
  }
}

export class RulesService {
  /**
   *
   */
  static messages(
    params: {
      /**  */
      attorneyId: string;
      /**  */
      county?: string;
      /**  */
      filename?: string;
      /**  */
      claimAmount?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/messages/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { County: params['county'], Filename: params['filename'], ClaimAmount: params['claimAmount'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(StringDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static addresses(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IServiceContactListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/Addresses/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IServiceContactListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static county(
    params: {
      /** requestBody */
      body?: PopulateCountyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ICountyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/county';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ICountyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static division(
    params: {
      /** requestBody */
      body?: PopulateDivisionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IDivisionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/division';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IDivisionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static division1(
    params: {
      /** requestBody */
      body?: DivisionRuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DivisionRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/save/division';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static division2(options: IRequestOptions = {}): Promise<DivisionRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/list/division';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DivisionRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static casetype(
    params: {
      /** requestBody */
      body?: PopulateCaseTypeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ICaseSubTypeDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/casetype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ICaseSubTypeDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static party(
    params: {
      /** requestBody */
      body?: PopulatePartyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IPartyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/party';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IPartyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static casetype1(
    params: {
      /** requestBody */
      body?: CaseTypeRuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/save/casetype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static casetype2(options: IRequestOptions = {}): Promise<CaseTypeRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/list/casetype';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documenttype(
    params: {
      /** requestBody */
      body?: DocumentTypeRuleRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentTypeRuleDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/save/documenttype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documenttype1(options: IRequestOptions = {}): Promise<DocumentTypeRuleListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/list/documenttype';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentTypeRuleListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documenttype2(
    params: {
      /** requestBody */
      body?: PopulateDocumentTypeRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IDocumentListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/documenttype';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IDocumentListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static fees(
    params: {
      /** requestBody */
      body?: PopulateFeesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IFeeIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Rules/fees';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IFeeIEnumerableDetailedResponse, response)), reject);
    });
  }
}

export class ServiceContactService {
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ServiceContactIEnumerableDetailedResponse, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static serviceContact(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServiceContactIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static serviceContact1(
    params: {
      /** requestBody */
      body?: ServiceContact;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServiceContactAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static serviceContact2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServiceContactAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static serviceContact3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: ServiceContact;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServiceContactAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static serviceContact4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServiceContactAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ServiceContact/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServiceContactAPIResponse, response)), reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static users(
    params: {
      /** requestBody */
      body?: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static users1(
    params: {
      /**  */
      active?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { active: params['active'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static users2(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: User;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static users3(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static users4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /** requestBody */
      body?: RegisterRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserResponseAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/Register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: UserRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TokenAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(TokenAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static refresh(options: IRequestOptions = {}): Promise<TokenAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/Refresh';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(TokenAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static me(options: IRequestOptions = {}): Promise<UserResponseDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UserResponseDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static verify(
    params: {
      /** requestBody */
      body?: Credentials;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/verify';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ResetRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/ForgotPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static updateUsername(
    params: {
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/UpdateUsername';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: CredentialRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/ResetPassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static icon(
    params: {
      /**  */
      userId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/icon/{userId}';
      url = url.replace('{userId}', params['userId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: Stream;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Stream> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/icon/update';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Stream, response)), reject);
    });
  }
  /**
   *
   */
  static icon1(options: IRequestOptions = {}): Promise<Stream> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Users/me/icon';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Stream, response)), reject);
    });
  }
}

export class Address {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'firmID'?: string;

  /**  */
  'streetAddress'?: string;

  /**  */
  'unit'?: string;

  /**  */
  'city'?: string;

  /**  */
  'state'?: string;

  /**  */
  'zip'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'fullAddress'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['attorneyID'] = data['attorneyID'];
    this['firmID'] = data['firmID'];
    this['streetAddress'] = data['streetAddress'];
    this['unit'] = data['unit'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zip'] = data['zip'];
    this['active'] = data['active'];
    this['fullAddress'] = data['fullAddress'];
  }

  public static validationModel = {};
}

export class Attorney {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'firmID'?: string;

  /**  */
  @Expose()
  'v1_ID'?: number;

  /**  */
  'fullName'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'state'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'efmeDirectory'?: string;

  /**  */
  'processPendingQueue'?: boolean;

  /**  */
  'monitorPendingQueue'?: boolean;

  /**  */
  'pendingQueueEmail'?: string;

  /**  */
  'useDocumentEmails'?: boolean;

  /**  */
  'usePortalEmails'?: boolean;

  /**  */
  'serveThroughPortal'?: boolean;

  /**  */
  'noService'?: boolean;

  /**  */
  'noPortalService'?: boolean;

  /**  */
  'controlNumberInFilename'?: boolean;

  /**  */
  'holdIncoming'?: boolean;

  /**  */
  'allowOCR'?: boolean;

  /**  */
  'autoFileExisting'?: boolean;

  /**  */
  'autoFileNew'?: boolean;

  /**  */
  @Expose()
  'addressID'?: string;

  /**  */
  'address'?: Address;

  /**  */
  'firm'?: Firm;

  /**  */
  'portalConfigurations'?: PortalConfiguration[];

  /**  */
  'ePortalID'?: string;

  /**  */
  'serviceEmails'?: string;

  /**  */
  'assistantEmails'?: string;

  /**  */
  'extraEmails'?: string;

  /**  */
  'blacklistEmails'?: string;

  /**  */
  'bccEmails'?: string;

  /**  */
  'replyEmail'?: string;

  /**  */
  'failedEmail'?: string;

  /**  */
  'useClientControlID'?: boolean;

  /**  */
  'cvv'?: string;

  /**  */
  @Expose()
  'newCasePrice'?: number;

  /**  */
  @Expose()
  'existingCasePrice'?: number;

  /**  */
  'signature'?: string;

  /**  */
  'cleanFilename'?: boolean;

  /**  */
  'stampPDF'?: boolean;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firmID'] = data['firmID'];
    this['v1_ID'] = data['v1_ID'];
    this['fullName'] = data['fullName'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['state'] = data['state'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['barNumber'] = data['barNumber'];
    this['efmeDirectory'] = data['efmeDirectory'];
    this['processPendingQueue'] = data['processPendingQueue'];
    this['monitorPendingQueue'] = data['monitorPendingQueue'];
    this['pendingQueueEmail'] = data['pendingQueueEmail'];
    this['useDocumentEmails'] = data['useDocumentEmails'];
    this['usePortalEmails'] = data['usePortalEmails'];
    this['serveThroughPortal'] = data['serveThroughPortal'];
    this['noService'] = data['noService'];
    this['noPortalService'] = data['noPortalService'];
    this['controlNumberInFilename'] = data['controlNumberInFilename'];
    this['holdIncoming'] = data['holdIncoming'];
    this['allowOCR'] = data['allowOCR'];
    this['autoFileExisting'] = data['autoFileExisting'];
    this['autoFileNew'] = data['autoFileNew'];
    this['addressID'] = data['addressID'];
    this['address'] = data['address'];
    this['firm'] = data['firm'];
    this['portalConfigurations'] = data['portalConfigurations'];
    this['ePortalID'] = data['ePortalID'];
    this['serviceEmails'] = data['serviceEmails'];
    this['assistantEmails'] = data['assistantEmails'];
    this['extraEmails'] = data['extraEmails'];
    this['blacklistEmails'] = data['blacklistEmails'];
    this['bccEmails'] = data['bccEmails'];
    this['replyEmail'] = data['replyEmail'];
    this['failedEmail'] = data['failedEmail'];
    this['useClientControlID'] = data['useClientControlID'];
    this['cvv'] = data['cvv'];
    this['newCasePrice'] = data['newCasePrice'];
    this['existingCasePrice'] = data['existingCasePrice'];
    this['signature'] = data['signature'];
    this['cleanFilename'] = data['cleanFilename'];
    this['stampPDF'] = data['stampPDF'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class AttorneyAPIResponse {
  /**  */
  'data'?: Attorney;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class AttorneyAutomationRequest {
  /**  */
  'controlNumberInFilename'?: boolean;

  /**  */
  'holdIncoming'?: boolean;

  /**  */
  'allowOCR'?: boolean;

  /**  */
  'autoFileExisting'?: boolean;

  /**  */
  'autoFileNew'?: boolean;

  constructor(data: undefined | any = {}) {
    this['controlNumberInFilename'] = data['controlNumberInFilename'];
    this['holdIncoming'] = data['holdIncoming'];
    this['allowOCR'] = data['allowOCR'];
    this['autoFileExisting'] = data['autoFileExisting'];
    this['autoFileNew'] = data['autoFileNew'];
  }

  public static validationModel = {};
}

export class AttorneyDetailedResponse {
  /**  */
  'data'?: Attorney;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class AttorneyIEnumerableAPIResponse {
  /**  */
  'data'?: Attorney[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class AttorneyListDetailedResponse {
  /**  */
  'data'?: Attorney[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class AttorneyPendingQueue {
  /**  */
  'processPendingQueue'?: boolean;

  /**  */
  'monitorPendingQueue'?: boolean;

  /**  */
  'pendingQueueEmail'?: string;

  constructor(data: undefined | any = {}) {
    this['processPendingQueue'] = data['processPendingQueue'];
    this['monitorPendingQueue'] = data['monitorPendingQueue'];
    this['pendingQueueEmail'] = data['pendingQueueEmail'];
  }

  public static validationModel = {};
}

export class AttorneyProfileRequest {
  /**  */
  'fullName'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'state'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'failedEmail'?: string;

  /**  */
  'useClientControlID'?: boolean;

  /**  */
  @Expose()
  'newCasePrice'?: number;

  /**  */
  @Expose()
  'existingCasePrice'?: number;

  constructor(data: undefined | any = {}) {
    this['fullName'] = data['fullName'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['state'] = data['state'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['barNumber'] = data['barNumber'];
    this['failedEmail'] = data['failedEmail'];
    this['useClientControlID'] = data['useClientControlID'];
    this['newCasePrice'] = data['newCasePrice'];
    this['existingCasePrice'] = data['existingCasePrice'];
  }

  public static validationModel = {};
}

export class AttorneyServiceRequest {
  /**  */
  'serviceEmails'?: string;

  /**  */
  'assistantEmails'?: string;

  /**  */
  'extraEmails'?: string;

  /**  */
  'blacklistEmails'?: string;

  /**  */
  'bccEmails'?: string;

  /**  */
  'replyEmail'?: string;

  /**  */
  'signature'?: string;

  /**  */
  'useDocumentEmails'?: boolean;

  /**  */
  'usePortalEmails'?: boolean;

  /**  */
  'serveThroughPortal'?: boolean;

  /**  */
  'noService'?: boolean;

  /**  */
  'noPortalService'?: boolean;

  /**  */
  'cleanFilename'?: boolean;

  /**  */
  'stampPDF'?: boolean;

  constructor(data: undefined | any = {}) {
    this['serviceEmails'] = data['serviceEmails'];
    this['assistantEmails'] = data['assistantEmails'];
    this['extraEmails'] = data['extraEmails'];
    this['blacklistEmails'] = data['blacklistEmails'];
    this['bccEmails'] = data['bccEmails'];
    this['replyEmail'] = data['replyEmail'];
    this['signature'] = data['signature'];
    this['useDocumentEmails'] = data['useDocumentEmails'];
    this['usePortalEmails'] = data['usePortalEmails'];
    this['serveThroughPortal'] = data['serveThroughPortal'];
    this['noService'] = data['noService'];
    this['noPortalService'] = data['noPortalService'];
    this['cleanFilename'] = data['cleanFilename'];
    this['stampPDF'] = data['stampPDF'];
  }

  public static validationModel = {};
}

export class BooleanAPIResponse {
  /**  */
  'data'?: boolean;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class BooleanDetailedResponse {
  /**  */
  'data'?: boolean;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseNumberRegex {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorney'?: Attorney;

  /**  */
  'county'?: string;

  /**  */
  'regex'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['attorney'] = data['attorney'];
    this['county'] = data['county'];
    this['regex'] = data['regex'];
  }

  public static validationModel = {};
}

export class CaseNumberRegexAPIResponse {
  /**  */
  'data'?: CaseNumberRegex;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class CaseNumberRegexIEnumerableAPIResponse {
  /**  */
  'data'?: CaseNumberRegex[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class CaseNumberRegexListDetailedResponse {
  /**  */
  'data'?: CaseNumberRegex[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseTypeRule {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  'csvCaseType'?: string;

  /**  */
  'csvSubCaseType'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'caseType'?: string;

  /**  */
  'global'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['csvCaseType'] = data['csvCaseType'];
    this['csvSubCaseType'] = data['csvSubCaseType'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['caseType'] = data['caseType'];
    this['global'] = data['global'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleAPIResponse {
  /**  */
  'data'?: CaseTypeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleDetailedResponse {
  /**  */
  'data'?: CaseTypeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleIEnumerableAPIResponse {
  /**  */
  'data'?: CaseTypeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleListDetailedResponse {
  /**  */
  'data'?: CaseTypeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  'csvCaseType'?: string;

  /**  */
  'csvSubCaseType'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'caseType'?: string;

  /**  */
  'global'?: boolean;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['csvCaseType'] = data['csvCaseType'];
    this['csvSubCaseType'] = data['csvSubCaseType'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['caseType'] = data['caseType'];
    this['global'] = data['global'];
  }

  public static validationModel = {};
}

export class CaseTypeRuleTestRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  'csvCaseType'?: string;

  /**  */
  'csvSubCaseType'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  @Expose()
  'amount'?: number;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['csvCaseType'] = data['csvCaseType'];
    this['csvSubCaseType'] = data['csvSubCaseType'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['amount'] = data['amount'];
  }

  public static validationModel = {};
}

export class CorrectionQueue {
  /**  */
  'ucn'?: string;

  /**  */
  'caseTitle'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'referenceNumber'?: string;

  /**  */
  'notes'?: string;

  constructor(data: undefined | any = {}) {
    this['ucn'] = data['ucn'];
    this['caseTitle'] = data['caseTitle'];
    this['caseNumber'] = data['caseNumber'];
    this['referenceNumber'] = data['referenceNumber'];
    this['notes'] = data['notes'];
  }

  public static validationModel = {};
}

export class CorrectionQueueListDetailedResponse {
  /**  */
  'data'?: CorrectionQueue[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CredentialRequest {
  /**  */
  'username'?: string;

  /**  */
  'password'?: string;

  /**  */
  'code'?: string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['password'] = data['password'];
    this['code'] = data['code'];
  }

  public static validationModel = {};
}

export class Credentials {
  /**  */
  'requestID'?: string;

  /**  */
  'username'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'password'?: string;

  /**  */
  'newPassword'?: string;

  /**  */
  'code'?: string;

  constructor(data: undefined | any = {}) {
    this['requestID'] = data['requestID'];
    this['username'] = data['username'];
    this['emailAddress'] = data['emailAddress'];
    this['password'] = data['password'];
    this['newPassword'] = data['newPassword'];
    this['code'] = data['code'];
  }

  public static validationModel = {};
}

export class DivisionRule {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'divisionName'?: string;

  /**  */
  'countyName'?: string;

  /**  */
  'csvDivision'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'global'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['divisionName'] = data['divisionName'];
    this['countyName'] = data['countyName'];
    this['csvDivision'] = data['csvDivision'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['global'] = data['global'];
  }

  public static validationModel = {};
}

export class DivisionRuleAPIResponse {
  /**  */
  'data'?: DivisionRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class DivisionRuleDetailedResponse {
  /**  */
  'data'?: DivisionRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class DivisionRuleIEnumerableAPIResponse {
  /**  */
  'data'?: DivisionRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class DivisionRuleListDetailedResponse {
  /**  */
  'data'?: DivisionRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class DivisionRuleRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'divisionName'?: string;

  /**  */
  'countyName'?: string;

  /**  */
  'csvDivision'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'global'?: boolean;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['divisionName'] = data['divisionName'];
    this['countyName'] = data['countyName'];
    this['csvDivision'] = data['csvDivision'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['global'] = data['global'];
  }

  public static validationModel = {};
}

export class DivisionRuleTestRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'countyName'?: string;

  /**  */
  'csvDivision'?: string;

  /**  */
  @Expose()
  'claimAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['countyName'] = data['countyName'];
    this['csvDivision'] = data['csvDivision'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class Document {
  /**  */
  'documentTypeID'?: string;

  /**  */
  'documentType'?: string;

  /**  */
  'filename'?: string;

  /**  */
  @Expose()
  'filingCodeID'?: string;

  /**  */
  @Expose()
  'securityID'?: string;

  /**  */
  @Expose()
  'filingComponentID'?: string;

  /**  */
  'predictedType'?: string;

  /**  */
  'predictedTypeID'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'parentID'?: string;

  /**  */
  'filePath'?: string;

  /**  */
  'confidential'?: boolean;

  /**  */
  'documentGroup'?: string;

  /**  */
  'referenceNumber'?: string;

  /**  */
  'status'?: string;

  /**  */
  'downloadCopyUrl'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'statusUpdatedDate'?: Date;

  /**  */
  'statusUpdatedReason'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateServed'?: Date;

  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'filingComponentCode'?: string;

  /**  */
  'filingCode'?: string;

  /**  */
  'comments'?: string;

  /**  */
  @Expose()
  'copies'?: number;

  /**  */
  'action'?: string;

  /**  */
  'filingAttorneyGUID'?: string;

  /**  */
  'filingPartyGUID'?: string;

  /**  */
  'optionalServices'?: OptionalServicesCode[];

  /**  */
  'attachments'?: Document[];

  /**  */
  'mergeDocuments'?: Document[];

  /**  */
  'url'?: string;

  constructor(data: undefined | any = {}) {
    this['documentTypeID'] = data['documentTypeID'];
    this['documentType'] = data['documentType'];
    this['filename'] = data['filename'];
    this['filingCodeID'] = data['filingCodeID'];
    this['securityID'] = data['securityID'];
    this['filingComponentID'] = data['filingComponentID'];
    this['predictedType'] = data['predictedType'];
    this['predictedTypeID'] = data['predictedTypeID'];
    this['submissionID'] = data['submissionID'];
    this['id'] = data['id'];
    this['parentID'] = data['parentID'];
    this['filePath'] = data['filePath'];
    this['confidential'] = data['confidential'];
    this['documentGroup'] = data['documentGroup'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['downloadCopyUrl'] = data['downloadCopyUrl'];
    this['statusUpdatedDate'] = data['statusUpdatedDate'];
    this['statusUpdatedReason'] = data['statusUpdatedReason'];
    this['dateServed'] = data['dateServed'];
    this['description'] = data['description'];
    this['fee'] = data['fee'];
    this['filingComponentCode'] = data['filingComponentCode'];
    this['filingCode'] = data['filingCode'];
    this['comments'] = data['comments'];
    this['copies'] = data['copies'];
    this['action'] = data['action'];
    this['filingAttorneyGUID'] = data['filingAttorneyGUID'];
    this['filingPartyGUID'] = data['filingPartyGUID'];
    this['optionalServices'] = data['optionalServices'];
    this['attachments'] = data['attachments'];
    this['mergeDocuments'] = data['mergeDocuments'];
    this['url'] = data['url'];
  }

  public static validationModel = {};
}

export class DocumentTypeRule {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'fileText'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'global'?: boolean;

  /**  */
  'newCases'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'validUntil'?: Date;

  /**  */
  'documentType'?: string;

  /**  */
  'description'?: string;

  /**  */
  'securityType'?: string;

  /**  */
  'component'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['filename'] = data['filename'];
    this['fileText'] = data['fileText'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['global'] = data['global'];
    this['newCases'] = data['newCases'];
    this['validUntil'] = data['validUntil'];
    this['documentType'] = data['documentType'];
    this['description'] = data['description'];
    this['securityType'] = data['securityType'];
    this['component'] = data['component'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleAPIResponse {
  /**  */
  'data'?: DocumentTypeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleDetailedResponse {
  /**  */
  'data'?: DocumentTypeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleIEnumerableAPIResponse {
  /**  */
  'data'?: DocumentTypeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleListDetailedResponse {
  /**  */
  'data'?: DocumentTypeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'fileText'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'global'?: boolean;

  /**  */
  'newCases'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'validUntil'?: Date;

  /**  */
  'documentType'?: string;

  /**  */
  'description'?: string;

  /**  */
  'securityType'?: string;

  /**  */
  'component'?: string;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['filename'] = data['filename'];
    this['fileText'] = data['fileText'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['global'] = data['global'];
    this['newCases'] = data['newCases'];
    this['validUntil'] = data['validUntil'];
    this['documentType'] = data['documentType'];
    this['description'] = data['description'];
    this['securityType'] = data['securityType'];
    this['component'] = data['component'];
  }

  public static validationModel = {};
}

export class DocumentTypeRuleTestRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'fileText'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  @Expose()
  'claimAmount'?: number;

  /**  */
  'newCases'?: boolean;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['filename'] = data['filename'];
    this['fileText'] = data['fileText'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['claimAmount'] = data['claimAmount'];
    this['newCases'] = data['newCases'];
  }

  public static validationModel = {};
}

export class FeeRule {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'fileText'?: string;

  /**  */
  'documentType'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  'global'?: boolean;

  /**  */
  'newCases'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'validUntil'?: Date;

  /**  */
  'feeName'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['filename'] = data['filename'];
    this['fileText'] = data['fileText'];
    this['documentType'] = data['documentType'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['global'] = data['global'];
    this['newCases'] = data['newCases'];
    this['validUntil'] = data['validUntil'];
    this['feeName'] = data['feeName'];
  }

  public static validationModel = {};
}

export class FeeRuleAPIResponse {
  /**  */
  'data'?: FeeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FeeRuleDetailedResponse {
  /**  */
  'data'?: FeeRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class FeeRuleIEnumerableAPIResponse {
  /**  */
  'data'?: FeeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FeeRuleListDetailedResponse {
  /**  */
  'data'?: FeeRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class Firm {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'v1_ID'?: number;

  /**  */
  'name'?: string;

  /**  */
  'processPendingQueue'?: boolean;

  /**  */
  'monitorPendingQueue'?: boolean;

  /**  */
  'pendingQueueEmail'?: string;

  /**  */
  'useDocumentEmails'?: boolean;

  /**  */
  'usePortalEmails'?: boolean;

  /**  */
  'serveThroughPortal'?: boolean;

  /**  */
  'noService'?: boolean;

  /**  */
  'noPortalService'?: boolean;

  /**  */
  'controlNumberInFilename'?: boolean;

  /**  */
  'holdIncoming'?: boolean;

  /**  */
  'allowOCR'?: boolean;

  /**  */
  'autoFileExisting'?: boolean;

  /**  */
  'autoFileNew'?: boolean;

  /**  */
  @Expose()
  'addressID'?: string;

  /**  */
  'address'?: Address;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['v1_ID'] = data['v1_ID'];
    this['name'] = data['name'];
    this['processPendingQueue'] = data['processPendingQueue'];
    this['monitorPendingQueue'] = data['monitorPendingQueue'];
    this['pendingQueueEmail'] = data['pendingQueueEmail'];
    this['useDocumentEmails'] = data['useDocumentEmails'];
    this['usePortalEmails'] = data['usePortalEmails'];
    this['serveThroughPortal'] = data['serveThroughPortal'];
    this['noService'] = data['noService'];
    this['noPortalService'] = data['noPortalService'];
    this['controlNumberInFilename'] = data['controlNumberInFilename'];
    this['holdIncoming'] = data['holdIncoming'];
    this['allowOCR'] = data['allowOCR'];
    this['autoFileExisting'] = data['autoFileExisting'];
    this['autoFileNew'] = data['autoFileNew'];
    this['addressID'] = data['addressID'];
    this['address'] = data['address'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class FirmAPIResponse {
  /**  */
  'data'?: Firm;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FirmIEnumerableAPIResponse {
  /**  */
  'data'?: Firm[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FirmListDetailedResponse {
  /**  */
  'data'?: Firm[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class FTPInfo {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorney'?: Attorney;

  /**  */
  'credentialsHash'?: string;

  /**  */
  'url'?: string;

  /**  */
  @Expose()
  'port'?: number;

  /**  */
  'appendClientMatter'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['attorney'] = data['attorney'];
    this['credentialsHash'] = data['credentialsHash'];
    this['url'] = data['url'];
    this['port'] = data['port'];
    this['appendClientMatter'] = data['appendClientMatter'];
  }

  public static validationModel = {};
}

export class FTPInfoAPIResponse {
  /**  */
  'data'?: FTPInfo;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FTPInfoIEnumerableAPIResponse {
  /**  */
  'data'?: FTPInfo[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class FTPInfoListDetailedResponse {
  /**  */
  'data'?: FTPInfo[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IAddress {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'fullAddress'?: string;

  /**  */
  'streetAddress'?: string;

  /**  */
  'unit'?: string;

  /**  */
  'city'?: string;

  /**  */
  'state'?: string;

  /**  */
  'zip'?: string;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullAddress'] = data['fullAddress'];
    this['streetAddress'] = data['streetAddress'];
    this['unit'] = data['unit'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zip'] = data['zip'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class ICaseSubType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'code'?: string;

  /**  */
  'caseTypePortalID'?: string;

  /**  */
  @Expose()
  'matchingRuleID'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  'caseType'?: ICaseType;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['code'] = data['code'];
    this['caseTypePortalID'] = data['caseTypePortalID'];
    this['matchingRuleID'] = data['matchingRuleID'];
    this['caseTypeID'] = data['caseTypeID'];
    this['caseType'] = data['caseType'];
  }

  public static validationModel = {};
}

export class ICaseSubTypeDetailedResponse {
  /**  */
  'data'?: ICaseSubType;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class ICaseType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'description'?: string;

  /**  */
  'caseTypeCode'?: string;

  /**  */
  'localNodeID'?: string;

  /**  */
  'portalId'?: string;

  /**  */
  'useAsDefault'?: boolean;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'initial'?: boolean;

  /**  */
  'category'?: string;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['caseTypeCode'] = data['caseTypeCode'];
    this['localNodeID'] = data['localNodeID'];
    this['portalId'] = data['portalId'];
    this['useAsDefault'] = data['useAsDefault'];
    this['fee'] = data['fee'];
    this['initial'] = data['initial'];
    this['category'] = data['category'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class ICounty {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'circuit'?: string;

  /**  */
  'generateCoverSheet'?: boolean;

  /**  */
  'initialFiling'?: boolean;

  /**  */
  'subsequentFiling'?: boolean;

  /**  */
  'state'?: IState;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['ePortalID'] = data['ePortalID'];
    this['circuit'] = data['circuit'];
    this['generateCoverSheet'] = data['generateCoverSheet'];
    this['initialFiling'] = data['initialFiling'];
    this['subsequentFiling'] = data['subsequentFiling'];
    this['state'] = data['state'];
  }

  public static validationModel = {};
}

export class ICountyDetailedResponse {
  /**  */
  'data'?: ICounty;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class ICourtType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'code'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['divisionID'] = data['divisionID'];
    this['code'] = data['code'];
    this['name'] = data['name'];
  }

  public static validationModel = {};
}

export class IDivision {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'claimAmountSupported'?: boolean;

  /**  */
  'courtTypes'?: ICourtType[];

  /**  */
  'code'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['countyID'] = data['countyID'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['claimAmountSupported'] = data['claimAmountSupported'];
    this['courtTypes'] = data['courtTypes'];
    this['code'] = data['code'];
  }

  public static validationModel = {};
}

export class IDivisionDetailedResponse {
  /**  */
  'data'?: IDivision;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IDocument {
  /**  */
  'documentTypeID'?: string;

  /**  */
  'documentType'?: string;

  /**  */
  'filename'?: string;

  /**  */
  @Expose()
  'filingCodeID'?: string;

  /**  */
  @Expose()
  'securityID'?: string;

  /**  */
  @Expose()
  'filingComponentID'?: string;

  /**  */
  'predictedType'?: string;

  /**  */
  'predictedTypeID'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  constructor(data: undefined | any = {}) {
    this['documentTypeID'] = data['documentTypeID'];
    this['documentType'] = data['documentType'];
    this['filename'] = data['filename'];
    this['filingCodeID'] = data['filingCodeID'];
    this['securityID'] = data['securityID'];
    this['filingComponentID'] = data['filingComponentID'];
    this['predictedType'] = data['predictedType'];
    this['predictedTypeID'] = data['predictedTypeID'];
    this['submissionID'] = data['submissionID'];
  }

  public static validationModel = {};
}

export class IDocumentListDetailedResponse {
  /**  */
  'data'?: IDocument[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IFee {
  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'amount'?: number;

  /**  */
  @Expose()
  'quantity'?: number;

  /**  */
  'ePortalID'?: string;

  constructor(data: undefined | any = {}) {
    this['description'] = data['description'];
    this['amount'] = data['amount'];
    this['quantity'] = data['quantity'];
    this['ePortalID'] = data['ePortalID'];
  }

  public static validationModel = {};
}

export class IFeeIEnumerableDetailedResponse {
  /**  */
  'data'?: IFee[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IParty {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'partyType'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'partyTypeCode'?: string;

  /**  */
  'address'?: IAddress;

  /**  */
  'emailAddress'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'username'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['name'] = data['name'];
    this['partyType'] = data['partyType'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['partyTypeCode'] = data['partyTypeCode'];
    this['address'] = data['address'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['barNumber'] = data['barNumber'];
    this['username'] = data['username'];
  }

  public static validationModel = {};
}

export class IPartyDetailedResponse {
  /**  */
  'data'?: IParty;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IServiceContact {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'fullName'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'userType'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'address'?: IAddress;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['fullName'] = data['fullName'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['emailAddress'] = data['emailAddress'];
    this['userType'] = data['userType'];
    this['caseNumber'] = data['caseNumber'];
    this['address'] = data['address'];
  }

  public static validationModel = {};
}

export class IServiceContactListDetailedResponse {
  /**  */
  'data'?: IServiceContact[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IState {
  /**  */
  'name'?: string;

  /**  */
  'abbreviation'?: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['abbreviation'] = data['abbreviation'];
  }

  public static validationModel = {};
}

export class MessageRule {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'message'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'validUntil'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['county'] = data['county'];
    this['filename'] = data['filename'];
    this['message'] = data['message'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['validUntil'] = data['validUntil'];
  }

  public static validationModel = {};
}

export class MessageRuleAPIResponse {
  /**  */
  'data'?: MessageRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class MessageRuleDetailedResponse {
  /**  */
  'data'?: MessageRule;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class MessageRuleIEnumerableAPIResponse {
  /**  */
  'data'?: MessageRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class MessageRuleListDetailedResponse {
  /**  */
  'data'?: MessageRule[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class MessageRuleTestRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'filename'?: string;

  /**  */
  @Expose()
  'claimAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['county'] = data['county'];
    this['filename'] = data['filename'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class OptionalServicesCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'code'?: string;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  @Expose()
  'multiplier'?: number;

  /**  */
  @Expose()
  'quantity'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['code'] = data['code'];
    this['fee'] = data['fee'];
    this['multiplier'] = data['multiplier'];
    this['quantity'] = data['quantity'];
  }

  public static validationModel = {};
}

export class Organization {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['name'] = data['name'];
  }

  public static validationModel = {};
}

export class OrganizationAPIResponse {
  /**  */
  'data'?: Organization;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class OrganizationIEnumerableAPIResponse {
  /**  */
  'data'?: Organization[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class Party {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  @Expose()
  'partyID'?: number;

  /**  */
  'barNumber'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'suffix'?: string;

  /**  */
  'organization'?: string;

  /**  */
  'guid'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'representedPartyGUID'?: string;

  /**  */
  'partyType'?: string;

  /**  */
  'partyTypeCode'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'fullName'?: string;

  /**  */
  'interestedParty'?: Party;

  /**  */
  'routingNumber'?: string;

  /**  */
  'accountNumber'?: string;

  /**  */
  'accountName'?: string;

  /**  */
  @Expose()
  'efmeFilingFee'?: number;

  /**  */
  @Expose()
  'efmeFilingTaxAmount'?: number;

  /**  */
  'portalID'?: string;

  /**  */
  'attorneyID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'address'?: Address;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'username'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['partyID'] = data['partyID'];
    this['barNumber'] = data['barNumber'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['suffix'] = data['suffix'];
    this['organization'] = data['organization'];
    this['guid'] = data['guid'];
    this['paymentToken'] = data['paymentToken'];
    this['representedPartyGUID'] = data['representedPartyGUID'];
    this['partyType'] = data['partyType'];
    this['partyTypeCode'] = data['partyTypeCode'];
    this['emailAddress'] = data['emailAddress'];
    this['fullName'] = data['fullName'];
    this['interestedParty'] = data['interestedParty'];
    this['routingNumber'] = data['routingNumber'];
    this['accountNumber'] = data['accountNumber'];
    this['accountName'] = data['accountName'];
    this['efmeFilingFee'] = data['efmeFilingFee'];
    this['efmeFilingTaxAmount'] = data['efmeFilingTaxAmount'];
    this['portalID'] = data['portalID'];
    this['attorneyID'] = data['attorneyID'];
    this['name'] = data['name'];
    this['address'] = data['address'];
    this['phoneNumber'] = data['phoneNumber'];
    this['username'] = data['username'];
  }

  public static validationModel = {};
}

export class PopulateCaseTypeRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  'caseSubType'?: string;

  /**  */
  @Expose()
  'claimAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['countyID'] = data['countyID'];
    this['divisionID'] = data['divisionID'];
    this['caseType'] = data['caseType'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['caseSubType'] = data['caseSubType'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class PopulateCountyRequest {
  /**  */
  'portalIdentifier'?: string;

  /**  */
  'countyName'?: string;

  /**  */
  'divisionName'?: string;

  constructor(data: undefined | any = {}) {
    this['portalIdentifier'] = data['portalIdentifier'];
    this['countyName'] = data['countyName'];
    this['divisionName'] = data['divisionName'];
  }

  public static validationModel = {};
}

export class PopulateDivisionRequest {
  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'claimAmount'?: number;

  /**  */
  'division'?: string;

  constructor(data: undefined | any = {}) {
    this['countyID'] = data['countyID'];
    this['attorneyID'] = data['attorneyID'];
    this['claimAmount'] = data['claimAmount'];
    this['division'] = data['division'];
  }

  public static validationModel = {};
}

export class PopulateDocumentTypeRequest {
  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  'docs'?: Document[];

  /**  */
  'newCase'?: boolean;

  /**  */
  @Expose()
  'claimAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['submissionID'] = data['submissionID'];
    this['attorneyID'] = data['attorneyID'];
    this['countyID'] = data['countyID'];
    this['divisionID'] = data['divisionID'];
    this['caseTypeID'] = data['caseTypeID'];
    this['docs'] = data['docs'];
    this['newCase'] = data['newCase'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class PopulateFeesRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'filenames'?: string[];

  /**  */
  'newCase'?: boolean;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['submissionID'] = data['submissionID'];
    this['countyID'] = data['countyID'];
    this['divisionID'] = data['divisionID'];
    this['caseTypeID'] = data['caseTypeID'];
    this['filename'] = data['filename'];
    this['filenames'] = data['filenames'];
    this['newCase'] = data['newCase'];
  }

  public static validationModel = {};
}

export class PopulatePartyRequest {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  @Expose()
  'caseSubTypeID'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  'party'?: Party;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['countyID'] = data['countyID'];
    this['caseTypeID'] = data['caseTypeID'];
    this['caseSubTypeID'] = data['caseSubTypeID'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['party'] = data['party'];
  }

  public static validationModel = {};
}

export class PortalConfiguration {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'state'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'credentialHash'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'cvv'?: string;

  /**  */
  'displayName'?: string;

  /**  */
  'authCookieString'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['state'] = data['state'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['credentialHash'] = data['credentialHash'];
    this['paymentToken'] = data['paymentToken'];
    this['cvv'] = data['cvv'];
    this['displayName'] = data['displayName'];
    this['authCookieString'] = data['authCookieString'];
  }

  public static validationModel = {};
}

export class PortalConfigurationAPIResponse {
  /**  */
  'data'?: PortalConfiguration;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class PortalConfigurationIEnumerableAPIResponse {
  /**  */
  'data'?: PortalConfiguration[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class PortalConfigurationListDetailedResponse {
  /**  */
  'data'?: PortalConfiguration[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class PortalConfigurationRequest {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'state'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'cvv'?: string;

  /**  */
  'displayName'?: string;

  /**  */
  'username'?: string;

  /**  */
  'password'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['state'] = data['state'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['paymentToken'] = data['paymentToken'];
    this['cvv'] = data['cvv'];
    this['displayName'] = data['displayName'];
    this['username'] = data['username'];
    this['password'] = data['password'];
  }

  public static validationModel = {};
}

export class PortalConfigurationRequestDetailedResponse {
  /**  */
  'data'?: PortalConfigurationRequest;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class PortalConfigurationRequestListDetailedResponse {
  /**  */
  'data'?: PortalConfigurationRequest[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class PortalCredentials {
  /**  */
  'username'?: string;

  /**  */
  'password'?: string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['password'] = data['password'];
  }

  public static validationModel = {};
}

export class PortalCredentialsDetailedResponse {
  /**  */
  'data'?: PortalCredentials;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class RegisterRequest {
  /**  */
  'username'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'email'?: string;

  /**  */
  'password'?: string;

  /**  */
  'firmName'?: string;

  /**  */
  'streetName'?: string;

  /**  */
  'unitName'?: string;

  /**  */
  'city'?: string;

  /**  */
  'state'?: string;

  /**  */
  'zip'?: string;

  constructor(data: undefined | any = {}) {
    this['username'] = data['username'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['phoneNumber'] = data['phoneNumber'];
    this['email'] = data['email'];
    this['password'] = data['password'];
    this['firmName'] = data['firmName'];
    this['streetName'] = data['streetName'];
    this['unitName'] = data['unitName'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zip'] = data['zip'];
  }

  public static validationModel = {};
}

export class ResetRequest {
  /**  */
  'userName'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['userName'] = data['userName'];
    this['email'] = data['email'];
  }

  public static validationModel = {};
}

export class Role {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'title'?: string;

  /**  */
  @Expose()
  'key'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['title'] = data['title'];
    this['key'] = data['key'];
  }

  public static validationModel = {};
}

export class RoleAPIResponse {
  /**  */
  'data'?: Role;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class RoleIEnumerableAPIResponse {
  /**  */
  'data'?: Role[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class ServiceContact {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'fullName'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'userType'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'address'?: IAddress;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['attorneyID'] = data['attorneyID'];
    this['fullName'] = data['fullName'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['emailAddress'] = data['emailAddress'];
    this['userType'] = data['userType'];
    this['caseNumber'] = data['caseNumber'];
    this['address'] = data['address'];
  }

  public static validationModel = {};
}

export class ServiceContactAPIResponse {
  /**  */
  'data'?: ServiceContact;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class ServiceContactIEnumerableAPIResponse {
  /**  */
  'data'?: ServiceContact[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class ServiceContactIEnumerableDetailedResponse {
  /**  */
  'data'?: ServiceContact[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class Stream {
  /**  */
  'canRead'?: boolean;

  /**  */
  'canSeek'?: boolean;

  /**  */
  'canTimeout'?: boolean;

  /**  */
  'canWrite'?: boolean;

  /**  */
  @Expose()
  'length'?: number;

  /**  */
  @Expose()
  'position'?: number;

  /**  */
  @Expose()
  'readTimeout'?: number;

  /**  */
  @Expose()
  'writeTimeout'?: number;

  constructor(data: undefined | any = {}) {
    this['canRead'] = data['canRead'];
    this['canSeek'] = data['canSeek'];
    this['canTimeout'] = data['canTimeout'];
    this['canWrite'] = data['canWrite'];
    this['length'] = data['length'];
    this['position'] = data['position'];
    this['readTimeout'] = data['readTimeout'];
    this['writeTimeout'] = data['writeTimeout'];
  }

  public static validationModel = {};
}

export class StringDetailedResponse {
  /**  */
  'data'?: string;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class Token {
  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'title'?: string;

  /**  */
  'userID'?: string;

  /**  */
  'authToken'?: string;

  /**  */
  'accessToken'?: string;

  /**  */
  'refreshToken'?: string;

  /**  */
  'username'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['title'] = data['title'];
    this['userID'] = data['userID'];
    this['authToken'] = data['authToken'];
    this['accessToken'] = data['accessToken'];
    this['refreshToken'] = data['refreshToken'];
    this['username'] = data['username'];
    this['name'] = data['name'];
  }

  public static validationModel = {};
}

export class TokenAPIResponse {
  /**  */
  'data'?: Token;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class User {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'organizationID'?: string;

  /**  */
  @Expose()
  'roleID'?: string;

  /**  */
  'username'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'name'?: string;

  /**  */
  'iconUrl'?: string;

  /**  */
  'role'?: Role;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'title'?: string;

  /**  */
  'userID'?: string;

  /**  */
  'authToken'?: string;

  /**  */
  'accessToken'?: string;

  /**  */
  'refreshToken'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['organizationID'] = data['organizationID'];
    this['roleID'] = data['roleID'];
    this['username'] = data['username'];
    this['emailAddress'] = data['emailAddress'];
    this['name'] = data['name'];
    this['iconUrl'] = data['iconUrl'];
    this['role'] = data['role'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['title'] = data['title'];
    this['userID'] = data['userID'];
    this['authToken'] = data['authToken'];
    this['accessToken'] = data['accessToken'];
    this['refreshToken'] = data['refreshToken'];
  }

  public static validationModel = {};
}

export class UserRequest {
  /**  */
  @Expose()
  'organizationID'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'username'?: string;

  /**  */
  'password'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['organizationID'] = data['organizationID'];
    this['emailAddress'] = data['emailAddress'];
    this['username'] = data['username'];
    this['password'] = data['password'];
    this['name'] = data['name'];
  }

  public static validationModel = {};
}

export class UserResponse {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'organizationID'?: string;

  /**  */
  @Expose()
  'roleID'?: string;

  /**  */
  'username'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'name'?: string;

  /**  */
  'iconUrl'?: string;

  /**  */
  'role'?: Role;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'title'?: string;

  /**  */
  'userID'?: string;

  /**  */
  'authToken'?: string;

  /**  */
  'accessToken'?: string;

  /**  */
  'refreshToken'?: string;

  /**  */
  'organizationName'?: string;

  /**  */
  'roleTitle'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['active'] = data['active'];
    this['organizationID'] = data['organizationID'];
    this['roleID'] = data['roleID'];
    this['username'] = data['username'];
    this['emailAddress'] = data['emailAddress'];
    this['name'] = data['name'];
    this['iconUrl'] = data['iconUrl'];
    this['role'] = data['role'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['title'] = data['title'];
    this['userID'] = data['userID'];
    this['authToken'] = data['authToken'];
    this['accessToken'] = data['accessToken'];
    this['refreshToken'] = data['refreshToken'];
    this['organizationName'] = data['organizationName'];
    this['roleTitle'] = data['roleTitle'];
  }

  public static validationModel = {};
}

export class UserResponseAPIResponse {
  /**  */
  'data'?: UserResponse;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class UserResponseDetailedResponse {
  /**  */
  'data'?: UserResponse;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class UserResponseIEnumerableAPIResponse {
  /**  */
  'data'?: UserResponse[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class UserResponseIEnumerableDetailedResponse {
  /**  */
  'data'?: UserResponse[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}
