export default function Footer() {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <script>
                            document.write(new Date().getFullYear())
                        </script>
                        &copy; eFileMadeEasy by{" "}
                        <a href="">Legal Automation Systems</a>
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-end footer-links d-none d-sm-block">
                            <a href="javascript:void(0);">About Us</a>
                            <a href="javascript:void(0);">Help</a>
                            <a href="javascript:void(0);">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
