import React, { useEffect } from "react";
import useScript from "../helpers/hooks/useScript";
import Topbar from "../components/shared/Topbar";
import { Globals } from "../helpers/globals";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams,
} from "react-router-dom";
import Home from "../pages/Home";

export default function AuthLayout() {
    useScript("/assets/js/vendor.min.js");
    useScript("/assets/libs/jquery-knob/jquery.knob.min.js");
    useScript("/assets/libs/apexcharts/apexcharts.min.js");
    useScript(
        "/assets/libs/admin-resources/jquery.vectormap/jquery-jvectormap-1.2.2.min.js"
    );
    useScript(
        "/assets/libs/admin-resources/jquery.vectormap/maps/jquery-jvectormap-world-mill-en.js"
    );
    useScript("/assets/js/pages/dashboard-sales.init.js");

    useScript(
        "/assets/libs/twitter-bootstrap-wizard/jquery.bootstrap.wizard.min.js"
    );
    useScript("/assets/libs/dropzone/min/dropzone.min.js");
    useScript("/assets/js/efme.js");
    useScript("/assets/js/app.js");
    useEffect(() => {
        const sideBarSize = localStorage.getItem("sidebar-size");
        document.body.setAttribute("data-layout-mode", "detached");
        document.body.setAttribute(
            "data-layout",
            '{"mode": "light", "width": "fluid", "menuPosition": "fixed", "sidebar": { "color": "gradient", "size": "default", "showuser": true}, "topbar": {"color": "light"}, "showRightSidebarOnPageLoad": true}'
        );
        document.body.setAttribute(
            "data-sidebar-size",
            sideBarSize ?? "default"
        );
        document.body.setAttribute("data-sidebar-color", "gradient");
        document.body.setAttribute("data-layout-width", "fluid");
        document.body.setAttribute("data-layout-menu-position", "fixed");
        document.body.setAttribute("data-sidebar-showuser", "true");
        document.body.setAttribute("data-topbar-color", "light");
    }, []);

    useEffect(() => {
        Globals.retrieveMe();
    }, []);

    const currentUser = Globals.currentUserValue;

    return (
        <>
            <div id="wrapper">
                <Topbar />
                <div className="content-page">
                    <Routes>
                        <Route path="/" element={<Home />}></Route>
                    </Routes>
                </div>
            </div>
        </>
    );
}
