import { useState, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import qs from "query-string";
import { UserService } from "../services/ClientService";
import { authenticationService } from "../services/authentication.service";

interface QueryParams {
    code?: string;
    username?: string;
}

export default function ResetPasswordPage() {
    const query = qs.parse(location.search) as QueryParams;
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);
    const [username, setUsername] = useState(query.username ?? "");
    const [verificationCode, setVerificationCode] = useState(query.code ?? "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = (event: React.FormEvent) => {
        if (loading === false) {
            UserService.resetPassword({
                body: {
                    username,
                    code: verificationCode,
                    password: password,
                },
            }).then(
                (resp) => {
                    if (resp.successful) {
                        authenticationService.login(username, password).then(
                            () => {
                                window.location.href = "/";
                            },
                            (error) => {
                                setLoading(false);
                                setError(JSON.stringify(error));
                                window.location.href = "/";
                            }
                        );
                    } else {
                        setLoading(false);
                        setError(resp.message ?? "");
                    }
                },
                (error) => {
                    setLoading(false);
                    setError(JSON.stringify(error));
                }
            );
            setLoading(true);
        }
        event.preventDefault();
        return false;
    };

    return (
        <div className="loading auth-fluid-pages pb-0">
            <div className="auth-fluid">
                <div className="auth-fluid-right">
                    <div className="auth-user-testimonial">
                        <h3 className="mb-3 text-white">
                            EFILEMADEEASY IS AN INCREDIBLY EASY EFILE SERVICE.
                        </h3>
                        <p className="lead fw-normal">
                            <i className="mdi mdi-format-quote-open"></i>{" "}
                            eFileMadeEasy is a unique, one of a kind service,
                            that eFiles with the Florida E-Portal and eServes
                            your service list with the required service by
                            email. With a few mouse clicks your document is
                            completely prepared, filed and served. No more back
                            and forth between programs, selecting files,
                            scanning documents multiple times, typing in email
                            addresses, and the other time wasting steps required
                            to eFile. Save time, reduce mistakes, and do what
                            you do best practice law.
                            <i className="mdi mdi-format-quote-close"></i>
                        </p>
                        <h5 className="text-white">- eFileMadeEasy</h5>
                    </div>
                </div>
                <div
                    className="auth-fluid-form-box"
                    style={{ paddingBottom: "0px" }}
                >
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body">
                            <div className="auth-brand text-center text-lg-start">
                                <div className="auth-logo">
                                    <a
                                        href="index.html"
                                        className="logo logo-dark text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                width="300"
                                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                                alt="eFileMadeEasy"
                                            />
                                        </span>
                                    </a>
                                    <a
                                        href="index.html"
                                        className="logo logo-light text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                src="../assets/images/logo-light.png"
                                                alt=""
                                                height="22"
                                            />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <h4 className="mt-0">Create Password</h4>
                            <p className="text-muted mb-4">
                                Please enter the verification code that was sent
                                to your email.
                            </p>
                            <p className="text-muted mb-4">
                                If you do not recieve a link please email us at{" "}
                                <a href="mailto:support@efilemadeeasy.com?subject=Forgot%20Password">
                                    support.
                                </a>
                            </p>
                            <Form action="#" onSubmit={handleSubmit}>
                                <div className="mb-2">
                                    <label
                                        htmlFor="username"
                                        className="form-label"
                                    >
                                        Username
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        required={true}
                                        placeholder="Enter your username"
                                        defaultValue={username}
                                        onChange={(event: React.FormEvent) => {
                                            const usernameInput =
                                                event.currentTarget as HTMLInputElement;
                                            setUsername(usernameInput.value);
                                        }}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label
                                        htmlFor="code"
                                        className="form-label"
                                    >
                                        Verification Code
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="code"
                                        required={true}
                                        placeholder="Enter your verification code"
                                        defaultValue={verificationCode}
                                        onChange={(event: React.FormEvent) => {
                                            const usernameInput =
                                                event.currentTarget as HTMLInputElement;
                                            setVerificationCode(
                                                usernameInput.value
                                            );
                                        }}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label
                                        htmlFor="password"
                                        className="form-label"
                                    >
                                        New Password
                                    </label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="password"
                                            ref={passwordRef}
                                            id="password"
                                            required={true}
                                            className="form-control"
                                            placeholder="Enter your password"
                                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$"
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const passwordInput =
                                                    event.currentTarget as HTMLInputElement;
                                                setPassword(
                                                    passwordInput.value
                                                );

                                                setConfirmPassword("");
                                            }}
                                        />
                                        <div
                                            className="input-group-text"
                                            data-password="false"
                                            onClick={() => {
                                                if (
                                                    passwordRef.current!
                                                        .type === "password"
                                                )
                                                    passwordRef.current!.type =
                                                        "text";
                                                else {
                                                    passwordRef.current!.type =
                                                        "password";
                                                }
                                            }}
                                        >
                                            <span className="password-eye"></span>
                                        </div>
                                    </div>
                                    <ul>
                                        <li>8 - 15 Characters</li>
                                        <li>One number required</li>
                                        <li>One special character required</li>
                                        <li>One uppercase letter required</li>
                                        <li>One lowercase letter required</li>
                                    </ul>
                                </div>
                                <div className="mb-2">
                                    <label
                                        htmlFor="password"
                                        className="form-label"
                                    >
                                        Confirm New Password
                                    </label>
                                    <div className="input-group input-group-merge">
                                        <input
                                            type="password"
                                            ref={confirmPasswordRef}
                                            id="passwordConfirm"
                                            required={true}
                                            className="form-control"
                                            placeholder="Confirm your password"
                                            value={confirmPassword}
                                            onChange={(
                                                event: React.FormEvent
                                            ) => {
                                                const passwordInput =
                                                    event.currentTarget as HTMLInputElement;
                                                setConfirmPassword(
                                                    passwordInput.value
                                                );
                                                if (
                                                    passwordInput.value !==
                                                    password
                                                ) {
                                                    passwordInput.setCustomValidity(
                                                        "Passwords Don't Match"
                                                    );
                                                } else {
                                                    passwordInput.setCustomValidity(
                                                        ""
                                                    );
                                                }
                                            }}
                                        />
                                        <div
                                            className="input-group-text"
                                            data-password="false"
                                            onClick={() => {
                                                if (
                                                    confirmPasswordRef.current!
                                                        .type === "password"
                                                )
                                                    confirmPasswordRef.current!.type =
                                                        "text";
                                                else {
                                                    confirmPasswordRef.current!.type =
                                                        "password";
                                                }
                                            }}
                                        >
                                            <span className="password-eye"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {loading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        )}
                                        {!loading && <>Reset Password</>}
                                    </button>
                                </div>
                                <div className="d-grid text-center">
                                    {error}
                                </div>
                            </Form>
                            <footer className="footer footer-alt">
                                <p className="text-muted">
                                    Don't have an account?{" "}
                                    <a
                                        href="/register"
                                        className="text-primary fw-medium ms-1"
                                    >
                                        Sign Up
                                    </a>
                                </p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
