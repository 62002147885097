import MaterialTable, { Query, QueryResult } from "material-table";
import { ReportService } from "../../services/SubmissionService";
import * as APIModels from "../../models";
import { EReturnsService } from "../../services/EReturnService";
export default function DailyReportWidget() {
    return (
        <div className="card">
            <div className="card-body">
                <MaterialTable
                    columns={[
                        { title: "Filename", field: "filename" },
                        {
                            title: "Case Number",
                            field: "caseNumber",
                        },
                        {
                            title: "Matter Number",
                            field: "matterNumber",
                        },
                        {
                            title: "Status",
                            field: "statusDescription",
                        },
                        {
                            title: "Filing Attorney",
                            field: "attorneyName",
                        },
                        {
                            title: "Reference Number",
                            field: "referenceNumber",
                        },
                        {
                            title: "Time Uploaded",
                            field: "uploadedDatetime",
                        },
                        {
                            title: "Time Filed",
                            field: "filedDatetime",
                        },
                    ]}
                    data={(query) =>
                        new Promise((resolve, reject) => {
                            EReturnsService.getSubmissionsToday({
                                pageNumber: query.page + 1,
                                itemsPerPage: query.pageSize,
                            }).then(
                                (resp) => {
                                    resolve({
                                        data: resp.data as any,
                                        page: (resp.currentPage ?? 1) - 1,
                                        totalCount: resp.totalCount ?? 0,
                                    });
                                },
                                (error) => {
                                    console.log(error);
                                    //setError(error);
                                }
                            );
                        })
                    }
                    title="e-Filings Today"
                />
            </div>
        </div>
    );
}
