/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CaseDataDetailService {
  /**
   *
   */
  static submission(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CaseDataRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseDataDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/CaseDataDetail/submission/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseDataDetailedResponse, response)), reject);
    });
  }
}

export class DocumentsService {
  /**
   *
   */
  static view(
    params: {
      /**  */
      submissionId: string;
      /**  */
      documentId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Stream> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/view/{submissionId}/{documentId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Stream, response)), reject);
    });
  }
  /**
   *
   */
  static page(
    params: {
      /**  */
      documentId: string;
      /**  */
      pageNumber: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/{documentId}/page/{pageNumber}';
      url = url.replace('{documentId}', params['documentId'] + '');
      url = url.replace('{pageNumber}', params['pageNumber'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(StringDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static insert(
    params: {
      /** requestBody */
      body?: Document;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/insert';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documents(options: IRequestOptions = {}): Promise<DocumentIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documents1(
    params: {
      /** requestBody */
      body?: Document;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documents2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documents3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Document;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static documents4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Documents/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentAPIResponse, response)), reject);
    });
  }
}

export class EFileService {
  /**
   *
   */
  static eFile(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeginUploadDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BeginUploadDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static begin(
    params: {
      /** requestBody */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BeginUploadDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/begin';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BeginUploadDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static existing(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: eFile;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/existing/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static order(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Order;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/order/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static newCase(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: NewCase;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/newCase/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static batch(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: NewCase;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/batch/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static dfs(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DFS;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/eFile/dfs/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
}

export class EmailService {
  /**
   *
   */
  static emails(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Email/{id}/Emails';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static email(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: EmailRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Email/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static email1(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: EmailRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Email/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static email2(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: EmailRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Email/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailDetailedResponse, response)), reject);
    });
  }
}

export class EmailRecieptService {
  /**
   *
   */
  static resend(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept/submission/{id}/resend';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept/submission/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static emailReciept(options: IRequestOptions = {}): Promise<EmailReceiptIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static emailReciept1(
    params: {
      /** requestBody */
      body?: EmailReceipt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static emailReciept2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static emailReciept3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: EmailReceipt;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static emailReciept4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmailReceiptAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/EmailReciept/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EmailReceiptAPIResponse, response)), reject);
    });
  }
}

export class FileUploadService {
  /**
   *
   */
  static list(options: IRequestOptions = {}): Promise<IFileInfoIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FileUpload/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(IFileInfoIEnumerableDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static upload(
    params: {
      /**  */
      contentType: string;
      /**  */
      contentDisposition: string;
      /**  */
      headers: object;
      /**  */
      length: number;
      /**  */
      name: string;
      /**  */
      fileName: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FileUpload/upload';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['contentType']) {
        if (Object.prototype.toString.call(params['contentType']) === '[object Array]') {
          for (const item of params['contentType']) {
            data.append('ContentType', item as any);
          }
        } else {
          data.append('ContentType', params['contentType'] as any);
        }
      }

      if (params['contentDisposition']) {
        if (Object.prototype.toString.call(params['contentDisposition']) === '[object Array]') {
          for (const item of params['contentDisposition']) {
            data.append('ContentDisposition', item as any);
          }
        } else {
          data.append('ContentDisposition', params['contentDisposition'] as any);
        }
      }

      if (params['headers']) {
        if (Object.prototype.toString.call(params['headers']) === '[object Array]') {
          for (const item of params['headers']) {
            data.append('Headers', item as any);
          }
        } else {
          data.append('Headers', params['headers'] as any);
        }
      }

      if (params['length']) {
        if (Object.prototype.toString.call(params['length']) === '[object Array]') {
          for (const item of params['length']) {
            data.append('Length', item as any);
          }
        } else {
          data.append('Length', params['length'] as any);
        }
      }

      if (params['name']) {
        if (Object.prototype.toString.call(params['name']) === '[object Array]') {
          for (const item of params['name']) {
            data.append('Name', item as any);
          }
        } else {
          data.append('Name', params['name'] as any);
        }
      }

      if (params['fileName']) {
        if (Object.prototype.toString.call(params['fileName']) === '[object Array]') {
          for (const item of params['fileName']) {
            data.append('FileName', item as any);
          }
        } else {
          data.append('FileName', params['fileName'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      filename: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FileUpload/delete/{filename}';
      url = url.replace('{filename}', params['filename'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
}

export class PartyService {
  /**
   *
   */
  static parties(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Party/parties/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static party(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PartyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Party/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static party1(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PartyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Party/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static party2(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PartyRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Party/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyDetailedResponse, response)), reject);
    });
  }
}

export class QueueService {
  /**
   *
   */
  static import(
    params: {
      /** requestBody */
      body?: ImportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/Import';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static queue(
    params: {
      /**  */
      filter: string;
      /**  */
      state: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AttorneyQueueListAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/queue/{filter}/{state}';
      url = url.replace('{filter}', params['filter'] + '');
      url = url.replace('{state}', params['state'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(AttorneyQueueListAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static byAttorney(
    params: {
      /**  */
      attorneyId: string;
      /**  */
      filter: string;
      /**  */
      state: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QueuedSubmissionListAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/ByAttorney/{attorneyId}/{filter}/{state}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');
      url = url.replace('{filter}', params['filter'] + '');
      url = url.replace('{state}', params['state'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(QueuedSubmissionListAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static count(options: IRequestOptions = {}): Promise<QueueCountDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(QueueCountDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static next(
    params: {
      /**  */
      attorneyId: string;
      /**  */
      filter: string;
      /**  */
      state: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/next/{attorneyId}/{filter}/{state}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');
      url = url.replace('{filter}', params['filter'] + '');
      url = url.replace('{state}', params['state'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/submission/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submit(
    params: {
      /**  */
      attorneyId?: string;
      /**  */
      filter?: string;
      /**  */
      state?: string;
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/submit';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'], filter: params['filter'], state: params['state'] };
      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static dfs(
    params: {
      /**  */
      attorneyId?: string;
      /**  */
      filter?: string;
      /**  */
      state?: string;
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/submit/dfs';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'], filter: params['filter'], state: params['state'] };
      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static filed(
    params: {
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/filed';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static served(
    params: {
      /** requestBody */
      body?: ServedRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/served';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static cancelAll(
    params: {
      /**  */
      attorneyId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/cancelAll/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/cancel/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static hold(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/hold/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static hide(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/hide/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static fail(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/fail/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static errorsubmitting(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/errorsubmitting/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission1(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/readd/submission/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorney(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/readd/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static sync(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/sync/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorney1(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/sync/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static attorney2(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Queue/autofile/attorney/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
}

export class ReportingService {
  /**
   *
   */
  static last90Days(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ArrayNameValue[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/last90days';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Int32ArrayNameValue, response)), reject);
    });
  }
  /**
   *
   */
  static portalIdentifier(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32NameValue[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/PortalIdentifier';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Int32NameValue, response)), reject);
    });
  }
  /**
   *
   */
  static monthly(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32ArrayNameValue[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/Monthly';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Int32ArrayNameValue, response)), reject);
    });
  }
  /**
   *
   */
  static today(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      itemsPerPage?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReportSubmissionPagedResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/today';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], ItemsPerPage: params['itemsPerPage'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ReportSubmissionPagedResult, response)), reject);
    });
  }
  /**
   *
   */
  static existing(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/today/Existing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ProgressResult, response)), reject);
    });
  }
  /**
   *
   */
  static new(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/today/New';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ProgressResult, response)), reject);
    });
  }
  /**
   *
   */
  static dfs(
    params: {
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/today/DFS';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ProgressResult, response)), reject);
    });
  }
  /**
   *
   */
  static totalFilings(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgressResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/TotalFilings/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ProgressResult, response)), reject);
    });
  }
  /**
   *
   */
  static reporting(
    params: {
      /**  */
      attorneyId?: string;
      /** requestBody */
      body?: ReportingRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionPagedResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/reporting';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionPagedResult, response)), reject);
    });
  }
  /**
   *
   */
  static efilereport(
    params: {
      /**  */
      attorneyId?: string;
      /** requestBody */
      body?: EFileReportRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EFileReportListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Reporting/efilereport';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { attorneyId: params['attorneyId'] };
      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(EFileReportListDetailedResponse, response)), reject);
    });
  }
}

export class SubmissionService {
  /**
   *
   */
  static fix(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/fix/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static fileId(
    params: {
      /**  */
      fileId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/fileId/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static filed(
    params: {
      /**  */
      attorneyId: string;
      /**  */
      startDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/filed/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { startDate: params['startDate'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionIEnumerableDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static filed1(
    params: {
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/filed';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static served(
    params: {
      /** requestBody */
      body?: ServedRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/served';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static errorsubmitting(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: ActionRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/errorsubmitting/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static errorSendingEmail(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/ErrorSendingEmail/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static permRemoveFromQueue(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/PermRemoveFromQueue/{submissionID}';
      url = url.replace('{submissionID}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static autofile(
    params: {
      /**  */
      attorneyId: string;
      /**  */
      pattern: string;
      /**  */
      limit: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GuidListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/autofile/{attorneyId}/{pattern}/{limit}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');
      url = url.replace('{pattern}', params['pattern'] + '');
      url = url.replace('{limit}', params['limit'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(GuidListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static resendEmails(options: IRequestOptions = {}): Promise<SubmissionListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/ResendEmails';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static removefromqueue(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/removefromqueue/{submissionID}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static hold(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/hold/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static accepted(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: AcceptedRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/accepted/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static rejected(
    params: {
      /**  */
      submissionId: string;
      /** requestBody */
      body?: RejectedRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/rejected/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static queue(
    params: {
      /**  */
      attorneyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionListAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/Queue/{attorneyId}';
      url = url.replace('{attorneyId}', params['attorneyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionListAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalStatus(
    params: {
      /**  */
      submissionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/PortalStatus/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(StringDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static filename(
    params: {
      /**  */
      filename?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionListAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/PendingApproval/filename';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filename: params['filename'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionListAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static pendingCaseNumber(
    params: {
      /**  */
      days: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionIEnumerableDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/pending-case-number/{days}';
      url = url.replace('{days}', params['days'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionIEnumerableDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static pendingCaseNumber1(
    params: {
      /** requestBody */
      body?: Submission[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/pending-case-number';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission(options: IRequestOptions = {}): Promise<SubmissionIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission1(
    params: {
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submission4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
}

export class SubmissionDetailService {
  /**
   *
   */
  static searchSubmissions(
    params: {
      /**  */
      search: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail/SearchSubmissions/{search}';
      url = url.replace('{search}', params['search'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionUpdate(
    params: {
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail/submissionUpdate';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionDetail(options: IRequestOptions = {}): Promise<SubmissionIEnumerableAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionIEnumerableAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionDetail1(
    params: {
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionDetail2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionDetail3(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: Submission;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
  /**
   *
   */
  static submissionDetail4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionAPIResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/auth/SubmissionDetail/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionAPIResponse, response)), reject);
    });
  }
}

export class ThirdPartyService {
  /**
   *
   */
  static existingpackage(
    params: {
      /**  */
      attorneyUid: string;
      /**  */
      package: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/ThirdParty/file/existingpackage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['attorneyUid']) {
        if (Object.prototype.toString.call(params['attorneyUid']) === '[object Array]') {
          for (const item of params['attorneyUid']) {
            data.append('AttorneyUID', item as any);
          }
        } else {
          data.append('AttorneyUID', params['attorneyUid'] as any);
        }
      }

      if (params['package']) {
        if (Object.prototype.toString.call(params['package']) === '[object Array]') {
          for (const item of params['package']) {
            data.append('Package', item as any);
          }
        } else {
          data.append('Package', params['package'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
}

export class UploadsService {
  /**
   *
   */
  static today(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      itemsPerPage?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UploadPagedResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Uploads/today';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PageNumber: params['pageNumber'], ItemsPerPage: params['itemsPerPage'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(UploadPagedResult, response)), reject);
    });
  }
  /**
   *
   */
  static cancel(
    params: {
      /**  */
      uploadId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Uploads/cancel/{uploadId}';
      url = url.replace('{uploadId}', params['uploadId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SubmissionListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static sethash(
    params: {
      /**  */
      uploadId: string;
      /**  */
      filehash?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Uploads/sethash/{uploadId}';
      url = url.replace('{uploadId}', params['uploadId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { filehash: params['filehash'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(BooleanDetailedResponse, response)), reject);
    });
  }
}

export class V1Service {
  /**
   *
   */
  static queue(
    params: {
      /**  */
      uid: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<V1FileListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/V1/Queue/{uid}';
      url = url.replace('{uid}', params['uid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(V1FileListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static file(
    params: {
      /**  */
      fileId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<V1FileDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/V1/File/{fileId}';
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(V1FileDetailedResponse, response)), reject);
    });
  }
}

export class AcceptedRequest {
  /**  */
  @Expose()
  @Type(() => Date)
  'acceptedDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['acceptedDateTime'] = data['acceptedDateTime'];
  }

  public static validationModel = {};
}

export class ActionRequest {
  /**  */
  @Expose()
  'defaultMessage'?: number;

  /**  */
  'message'?: string;

  /**  */
  'filter'?: string;

  /**  */
  'state'?: string;

  constructor(data: undefined | any = {}) {
    this['defaultMessage'] = data['defaultMessage'];
    this['message'] = data['message'];
    this['filter'] = data['filter'];
    this['state'] = data['state'];
  }

  public static validationModel = {};
}

export class Address {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'fullAddress'?: string;

  /**  */
  'streetAddress'?: string;

  /**  */
  'unit'?: string;

  /**  */
  'city'?: string;

  /**  */
  'state'?: string;

  /**  */
  'zip'?: string;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fullAddress'] = data['fullAddress'];
    this['streetAddress'] = data['streetAddress'];
    this['unit'] = data['unit'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['zip'] = data['zip'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class Attorney {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'firmID'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  @Expose()
  'v1_ID'?: number;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'fullName'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'serviceEmails'?: string;

  /**  */
  'assistantEmails'?: string;

  /**  */
  'extraEmails'?: string;

  /**  */
  'blacklistEmails'?: string;

  /**  */
  'bccEmails'?: string;

  /**  */
  'replyEmail'?: string;

  /**  */
  'failedEmail'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'state'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'efmeDirectory'?: string;

  /**  */
  'processPendingQueue'?: boolean;

  /**  */
  'monitorPendingQueue'?: boolean;

  /**  */
  'pendingQueueEmail'?: string;

  /**  */
  'useDocumentEmails'?: boolean;

  /**  */
  'usePortalEmails'?: boolean;

  /**  */
  'serveThroughPortal'?: boolean;

  /**  */
  'noService'?: boolean;

  /**  */
  'noPortalService'?: boolean;

  /**  */
  'useClientControlID'?: boolean;

  /**  */
  'controlNumberInFilename'?: boolean;

  /**  */
  'holdIncoming'?: boolean;

  /**  */
  'allowOCR'?: boolean;

  /**  */
  'autoFileExisting'?: boolean;

  /**  */
  'autoFileNew'?: boolean;

  /**  */
  'cvv'?: string;

  /**  */
  @Expose()
  'newCasePrice'?: number;

  /**  */
  @Expose()
  'existingCasePrice'?: number;

  /**  */
  'signature'?: string;

  /**  */
  'cleanFilename'?: boolean;

  /**  */
  'stampPDF'?: boolean;

  /**  */
  'active'?: boolean;

  /**  */
  'firm'?: Firm;

  /**  */
  'address'?: Address;

  /**  */
  'portalConfigurations'?: PortalConfiguration[];

  /**  */
  'directory'?: string;

  /**  */
  @Expose()
  'addressID'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['firmID'] = data['firmID'];
    this['ePortalID'] = data['ePortalID'];
    this['v1_ID'] = data['v1_ID'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['fullName'] = data['fullName'];
    this['emailAddress'] = data['emailAddress'];
    this['serviceEmails'] = data['serviceEmails'];
    this['assistantEmails'] = data['assistantEmails'];
    this['extraEmails'] = data['extraEmails'];
    this['blacklistEmails'] = data['blacklistEmails'];
    this['bccEmails'] = data['bccEmails'];
    this['replyEmail'] = data['replyEmail'];
    this['failedEmail'] = data['failedEmail'];
    this['phoneNumber'] = data['phoneNumber'];
    this['state'] = data['state'];
    this['barNumber'] = data['barNumber'];
    this['efmeDirectory'] = data['efmeDirectory'];
    this['processPendingQueue'] = data['processPendingQueue'];
    this['monitorPendingQueue'] = data['monitorPendingQueue'];
    this['pendingQueueEmail'] = data['pendingQueueEmail'];
    this['useDocumentEmails'] = data['useDocumentEmails'];
    this['usePortalEmails'] = data['usePortalEmails'];
    this['serveThroughPortal'] = data['serveThroughPortal'];
    this['noService'] = data['noService'];
    this['noPortalService'] = data['noPortalService'];
    this['useClientControlID'] = data['useClientControlID'];
    this['controlNumberInFilename'] = data['controlNumberInFilename'];
    this['holdIncoming'] = data['holdIncoming'];
    this['allowOCR'] = data['allowOCR'];
    this['autoFileExisting'] = data['autoFileExisting'];
    this['autoFileNew'] = data['autoFileNew'];
    this['cvv'] = data['cvv'];
    this['newCasePrice'] = data['newCasePrice'];
    this['existingCasePrice'] = data['existingCasePrice'];
    this['signature'] = data['signature'];
    this['cleanFilename'] = data['cleanFilename'];
    this['stampPDF'] = data['stampPDF'];
    this['active'] = data['active'];
    this['firm'] = data['firm'];
    this['address'] = data['address'];
    this['portalConfigurations'] = data['portalConfigurations'];
    this['directory'] = data['directory'];
    this['addressID'] = data['addressID'];
  }

  public static validationModel = {};
}

export class AttorneyQueue {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'emergency'?: boolean;

  /**  */
  @Expose()
  'submissionCount'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'oldestFileDate'?: Date;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['name'] = data['name'];
    this['emergency'] = data['emergency'];
    this['submissionCount'] = data['submissionCount'];
    this['oldestFileDate'] = data['oldestFileDate'];
  }

  public static validationModel = {};
}

export class AttorneyQueueListAPIResponse {
  /**  */
  'data'?: AttorneyQueue[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class BeginUpload {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class BeginUploadDetailedResponse {
  /**  */
  'data'?: BeginUpload;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class BooleanAPIResponse {
  /**  */
  'data'?: boolean;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class BooleanDetailedResponse {
  /**  */
  'data'?: boolean;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseData {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'ucn'?: string;

  /**  */
  'county'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'division'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  @Expose()
  'caseTypeRuleID'?: string;

  /**  */
  'caseSubType'?: string;

  /**  */
  @Expose()
  'caseSubTypeID'?: string;

  /**  */
  'courtCaseNumber'?: string;

  /**  */
  'clientCaseNumber'?: string;

  /**  */
  'caseTitle'?: string;

  /**  */
  @Expose()
  'year'?: number;

  /**  */
  'sequenceNumber'?: string;

  /**  */
  'clientMatterNumber'?: string;

  /**  */
  'isClassAction'?: boolean;

  /**  */
  'hasRelatedCases'?: boolean;

  /**  */
  'demandForJuryTrial'?: boolean;

  /**  */
  @Expose()
  'totalDefendants'?: number;

  /**  */
  @Expose()
  'causeOfActionCount'?: number;

  /**  */
  'isMonetary'?: boolean;

  /**  */
  'isNonMonetary'?: boolean;

  /**  */
  'isInjunctiveRelief'?: boolean;

  /**  */
  'isPunitive'?: boolean;

  /**  */
  'isComplexBusiness'?: boolean;

  /**  */
  @Expose()
  'subpoenaCount'?: number;

  /**  */
  @Expose()
  'smallClaimsAmount'?: number;

  /**  */
  'relatedCaseNumber'?: string;

  /**  */
  'claimAmount'?: string;

  /**  */
  'beginDateOfService'?: string;

  /**  */
  'endDateOfService'?: string;

  /**  */
  'dateOfAccident'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  @Expose()
  'summonsCount'?: number;

  /**  */
  'cvv'?: string;

  /**  */
  'judicialOfficerID'?: string;

  /**  */
  @Expose()
  'damagesCodeID'?: string;

  /**  */
  @Expose()
  'divisionAddressID'?: string;

  /**  */
  'divisionAddress'?: Address;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['ucn'] = data['ucn'];
    this['county'] = data['county'];
    this['countyID'] = data['countyID'];
    this['division'] = data['division'];
    this['divisionID'] = data['divisionID'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['caseType'] = data['caseType'];
    this['caseTypeID'] = data['caseTypeID'];
    this['caseTypeRuleID'] = data['caseTypeRuleID'];
    this['caseSubType'] = data['caseSubType'];
    this['caseSubTypeID'] = data['caseSubTypeID'];
    this['courtCaseNumber'] = data['courtCaseNumber'];
    this['clientCaseNumber'] = data['clientCaseNumber'];
    this['caseTitle'] = data['caseTitle'];
    this['year'] = data['year'];
    this['sequenceNumber'] = data['sequenceNumber'];
    this['clientMatterNumber'] = data['clientMatterNumber'];
    this['isClassAction'] = data['isClassAction'];
    this['hasRelatedCases'] = data['hasRelatedCases'];
    this['demandForJuryTrial'] = data['demandForJuryTrial'];
    this['totalDefendants'] = data['totalDefendants'];
    this['causeOfActionCount'] = data['causeOfActionCount'];
    this['isMonetary'] = data['isMonetary'];
    this['isNonMonetary'] = data['isNonMonetary'];
    this['isInjunctiveRelief'] = data['isInjunctiveRelief'];
    this['isPunitive'] = data['isPunitive'];
    this['isComplexBusiness'] = data['isComplexBusiness'];
    this['subpoenaCount'] = data['subpoenaCount'];
    this['smallClaimsAmount'] = data['smallClaimsAmount'];
    this['relatedCaseNumber'] = data['relatedCaseNumber'];
    this['claimAmount'] = data['claimAmount'];
    this['beginDateOfService'] = data['beginDateOfService'];
    this['endDateOfService'] = data['endDateOfService'];
    this['dateOfAccident'] = data['dateOfAccident'];
    this['paymentToken'] = data['paymentToken'];
    this['summonsCount'] = data['summonsCount'];
    this['cvv'] = data['cvv'];
    this['judicialOfficerID'] = data['judicialOfficerID'];
    this['damagesCodeID'] = data['damagesCodeID'];
    this['divisionAddressID'] = data['divisionAddressID'];
    this['divisionAddress'] = data['divisionAddress'];
  }

  public static validationModel = {};
}

export class CaseDataDetailedResponse {
  /**  */
  'data'?: CaseData;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseDataRequest {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'county'?: string;

  /**  */
  'division'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  'caseSubType'?: string;

  /**  */
  'courtCaseNumber'?: string;

  /**  */
  'clientCaseNumbaer'?: string;

  /**  */
  'caseTitle'?: string;

  /**  */
  'cvv'?: string;

  /**  */
  'claimAmount'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['caseType'] = data['caseType'];
    this['caseSubType'] = data['caseSubType'];
    this['courtCaseNumber'] = data['courtCaseNumber'];
    this['clientCaseNumbaer'] = data['clientCaseNumbaer'];
    this['caseTitle'] = data['caseTitle'];
    this['cvv'] = data['cvv'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class Coversheet {
  /**  */
  'coversheetCourtType'?: string;

  /**  */
  'coversheetCaseType'?: string;

  /**  */
  'coversheetCaseSubType'?: string;

  /**  */
  'monetary'?: boolean;

  /**  */
  'nonMonetary'?: boolean;

  /**  */
  'punitive'?: boolean;

  /**  */
  'relief'?: boolean;

  /**  */
  'hasRelatedCases'?: boolean;

  /**  */
  'classAction'?: boolean;

  /**  */
  'juryTrial'?: boolean;

  /**  */
  'complexBusinessCourt'?: boolean;

  /**  */
  @Expose()
  'causesOfAction'?: number;

  /**  */
  @Expose()
  'numberOfDefendants'?: number;

  /**  */
  @Expose()
  'numberOfSubpoenas'?: number;

  /**  */
  @Expose()
  'claimAmount'?: number;

  /**  */
  'relatedCases'?: string;

  /**  */
  'hasDataFile'?: boolean;

  /**  */
  'dataFileName'?: string;

  constructor(data: undefined | any = {}) {
    this['coversheetCourtType'] = data['coversheetCourtType'];
    this['coversheetCaseType'] = data['coversheetCaseType'];
    this['coversheetCaseSubType'] = data['coversheetCaseSubType'];
    this['monetary'] = data['monetary'];
    this['nonMonetary'] = data['nonMonetary'];
    this['punitive'] = data['punitive'];
    this['relief'] = data['relief'];
    this['hasRelatedCases'] = data['hasRelatedCases'];
    this['classAction'] = data['classAction'];
    this['juryTrial'] = data['juryTrial'];
    this['complexBusinessCourt'] = data['complexBusinessCourt'];
    this['causesOfAction'] = data['causesOfAction'];
    this['numberOfDefendants'] = data['numberOfDefendants'];
    this['numberOfSubpoenas'] = data['numberOfSubpoenas'];
    this['claimAmount'] = data['claimAmount'];
    this['relatedCases'] = data['relatedCases'];
    this['hasDataFile'] = data['hasDataFile'];
    this['dataFileName'] = data['dataFileName'];
  }

  public static validationModel = {};
}

export class DFS {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadTypeKey'?: number;

  /**  */
  @Expose()
  'userID'?: string;

  /**  */
  'submittedBy'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'exhibits'?: Exhibits;

  /**  */
  'ucn'?: string;

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadTypeKey'] = data['uploadTypeKey'];
    this['userID'] = data['userID'];
    this['submittedBy'] = data['submittedBy'];
    this['attorneyID'] = data['attorneyID'];
    this['attorneyName'] = data['attorneyName'];
    this['filename'] = data['filename'];
    this['exhibits'] = data['exhibits'];
    this['ucn'] = data['ucn'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class Document {
  /**  */
  'documentTypeID'?: string;

  /**  */
  'documentType'?: string;

  /**  */
  'filename'?: string;

  /**  */
  @Expose()
  'filingCodeID'?: string;

  /**  */
  @Expose()
  'securityID'?: string;

  /**  */
  @Expose()
  'filingComponentID'?: string;

  /**  */
  'predictedType'?: string;

  /**  */
  'predictedTypeID'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'confidential'?: boolean;

  /**  */
  'documentGroup'?: string;

  /**  */
  'referenceNumber'?: string;

  /**  */
  'status'?: string;

  /**  */
  'downloadCopyUrl'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'statusUpdatedDate'?: Date;

  /**  */
  'statusUpdatedReason'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'dateServed'?: Date;

  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  @Expose()
  'size'?: number;

  /**  */
  'fees'?: Fee[];

  /**  */
  @Expose()
  'parentID'?: string;

  /**  */
  'parent'?: Document;

  constructor(data: undefined | any = {}) {
    this['documentTypeID'] = data['documentTypeID'];
    this['documentType'] = data['documentType'];
    this['filename'] = data['filename'];
    this['filingCodeID'] = data['filingCodeID'];
    this['securityID'] = data['securityID'];
    this['filingComponentID'] = data['filingComponentID'];
    this['predictedType'] = data['predictedType'];
    this['predictedTypeID'] = data['predictedTypeID'];
    this['submissionID'] = data['submissionID'];
    this['id'] = data['id'];
    this['confidential'] = data['confidential'];
    this['documentGroup'] = data['documentGroup'];
    this['referenceNumber'] = data['referenceNumber'];
    this['status'] = data['status'];
    this['downloadCopyUrl'] = data['downloadCopyUrl'];
    this['statusUpdatedDate'] = data['statusUpdatedDate'];
    this['statusUpdatedReason'] = data['statusUpdatedReason'];
    this['dateServed'] = data['dateServed'];
    this['description'] = data['description'];
    this['fee'] = data['fee'];
    this['size'] = data['size'];
    this['fees'] = data['fees'];
    this['parentID'] = data['parentID'];
    this['parent'] = data['parent'];
  }

  public static validationModel = {};
}

export class DocumentAPIResponse {
  /**  */
  'data'?: Document;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class DocumentDetailedResponse {
  /**  */
  'data'?: Document;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class DocumentIEnumerableAPIResponse {
  /**  */
  'data'?: Document[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class eFile {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadTypeKey'?: number;

  /**  */
  @Expose()
  'userID'?: string;

  /**  */
  'submittedBy'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'exhibits'?: Exhibits;

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadTypeKey'] = data['uploadTypeKey'];
    this['userID'] = data['userID'];
    this['submittedBy'] = data['submittedBy'];
    this['attorneyID'] = data['attorneyID'];
    this['attorneyName'] = data['attorneyName'];
    this['filename'] = data['filename'];
    this['exhibits'] = data['exhibits'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class EFileReport {
  /**  */
  'attorney'?: string;

  /**  */
  'county'?: string;

  /**  */
  'crT_CASE_NO'?: string;

  /**  */
  'datE_FILED'?: string;

  /**  */
  'division'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'finalfees'?: string;

  /**  */
  'no'?: string;

  /**  */
  'nO_DOCS_FILED'?: string;

  /**  */
  'parties'?: string;

  /**  */
  'portaL_REF'?: string;

  /**  */
  'service'?: string;

  /**  */
  'tracking'?: string;

  /**  */
  'type'?: string;

  /**  */
  'ucn'?: string;

  constructor(data: undefined | any = {}) {
    this['attorney'] = data['attorney'];
    this['county'] = data['county'];
    this['crT_CASE_NO'] = data['crT_CASE_NO'];
    this['datE_FILED'] = data['datE_FILED'];
    this['division'] = data['division'];
    this['filename'] = data['filename'];
    this['finalfees'] = data['finalfees'];
    this['no'] = data['no'];
    this['nO_DOCS_FILED'] = data['nO_DOCS_FILED'];
    this['parties'] = data['parties'];
    this['portaL_REF'] = data['portaL_REF'];
    this['service'] = data['service'];
    this['tracking'] = data['tracking'];
    this['type'] = data['type'];
    this['ucn'] = data['ucn'];
  }

  public static validationModel = {};
}

export class EFileReportListDetailedResponse {
  /**  */
  'data'?: EFileReport[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class EFileReportRequest {
  /**  */
  'fromDate'?: string;

  /**  */
  'toDate'?: string;

  /**  */
  @Expose()
  'searchBy'?: number;

  /**  */
  'contains'?: string;

  constructor(data: undefined | any = {}) {
    this['fromDate'] = data['fromDate'];
    this['toDate'] = data['toDate'];
    this['searchBy'] = data['searchBy'];
    this['contains'] = data['contains'];
  }

  public static validationModel = {};
}

export class Email {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'name'?: string;

  /**  */
  'source'?: string;

  /**  */
  'filingService'?: boolean;

  /**  */
  'acceptedService'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['emailAddress'] = data['emailAddress'];
    this['name'] = data['name'];
    this['source'] = data['source'];
    this['filingService'] = data['filingService'];
    this['acceptedService'] = data['acceptedService'];
  }

  public static validationModel = {};
}

export class EmailDetailedResponse {
  /**  */
  'data'?: Email;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class EmailListDetailedResponse {
  /**  */
  'data'?: Email[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class EmailReceipt {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'to'?: string;

  /**  */
  'bcc'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'sent'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['to'] = data['to'];
    this['bcc'] = data['bcc'];
    this['sent'] = data['sent'];
  }

  public static validationModel = {};
}

export class EmailReceiptAPIResponse {
  /**  */
  'data'?: EmailReceipt;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class EmailReceiptIEnumerableAPIResponse {
  /**  */
  'data'?: EmailReceipt[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class EmailReceiptListDetailedResponse {
  /**  */
  'data'?: EmailReceipt[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class EmailRequest {
  /**  */
  'emailAddress'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['emailAddress'] = data['emailAddress'];
    this['name'] = data['name'];
  }

  public static validationModel = {};
}

export class Exhibits {
  /**  */
  @Expose()
  'attachmentOptionKey'?: number;

  /**  */
  @Expose()
  'seperatorOptionKey'?: number;

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['attachmentOptionKey'] = data['attachmentOptionKey'];
    this['seperatorOptionKey'] = data['seperatorOptionKey'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class Fee {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'amount'?: number;

  /**  */
  @Expose()
  'quantity'?: number;

  /**  */
  'ePortalID'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['amount'] = data['amount'];
    this['quantity'] = data['quantity'];
    this['ePortalID'] = data['ePortalID'];
  }

  public static validationModel = {};
}

export class Firm {
  /**  */
  @Expose()
  'v1_ID'?: number;

  /**  */
  'name'?: string;

  /**  */
  'processPendingQueue'?: boolean;

  /**  */
  'monitorPendingQueue'?: boolean;

  /**  */
  'pendingQueueEmail'?: string;

  /**  */
  'useDocumentEmails'?: boolean;

  /**  */
  'usePortalEmails'?: boolean;

  /**  */
  'serveThroughPortal'?: boolean;

  /**  */
  'noService'?: boolean;

  /**  */
  'noPortalService'?: boolean;

  /**  */
  'controlNumberInFilename'?: boolean;

  /**  */
  'holdIncoming'?: boolean;

  /**  */
  'allowOCR'?: boolean;

  /**  */
  'autoFileExisting'?: boolean;

  /**  */
  'autoFileNew'?: boolean;

  /**  */
  'address'?: Address;

  /**  */
  'active'?: boolean;

  constructor(data: undefined | any = {}) {
    this['v1_ID'] = data['v1_ID'];
    this['name'] = data['name'];
    this['processPendingQueue'] = data['processPendingQueue'];
    this['monitorPendingQueue'] = data['monitorPendingQueue'];
    this['pendingQueueEmail'] = data['pendingQueueEmail'];
    this['useDocumentEmails'] = data['useDocumentEmails'];
    this['usePortalEmails'] = data['usePortalEmails'];
    this['serveThroughPortal'] = data['serveThroughPortal'];
    this['noService'] = data['noService'];
    this['noPortalService'] = data['noPortalService'];
    this['controlNumberInFilename'] = data['controlNumberInFilename'];
    this['holdIncoming'] = data['holdIncoming'];
    this['allowOCR'] = data['allowOCR'];
    this['autoFileExisting'] = data['autoFileExisting'];
    this['autoFileNew'] = data['autoFileNew'];
    this['address'] = data['address'];
    this['active'] = data['active'];
  }

  public static validationModel = {};
}

export class GuidListDetailedResponse {
  /**  */
  'data'?: string[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class IFileInfo {
  /**  */
  'filename'?: string;

  /**  */
  @Expose()
  'size'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'lastUpdatedDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['filename'] = data['filename'];
    this['size'] = data['size'];
    this['lastUpdatedDateTime'] = data['lastUpdatedDateTime'];
  }

  public static validationModel = {};
}

export class IFileInfoIEnumerableDetailedResponse {
  /**  */
  'data'?: IFileInfo[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class ImportRequest {
  /**  */
  'path'?: string;

  constructor(data: undefined | any = {}) {
    this['path'] = data['path'];
  }

  public static validationModel = {};
}

export class Int32ArrayNameValue {
  /**  */
  'name'?: string;

  /**  */
  'value'?: number[];

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['value'] = data['value'];
  }

  public static validationModel = {};
}

export class Int32NameValue {
  /**  */
  'name'?: string;

  /**  */
  @Expose()
  'value'?: number;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['value'] = data['value'];
  }

  public static validationModel = {};
}

export class IPortalConfiguration {
  /**  */
  'state'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'credentialHash'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'cvv'?: string;

  /**  */
  'displayName'?: string;

  /**  */
  'authCookieString'?: string;

  constructor(data: undefined | any = {}) {
    this['state'] = data['state'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['credentialHash'] = data['credentialHash'];
    this['paymentToken'] = data['paymentToken'];
    this['cvv'] = data['cvv'];
    this['displayName'] = data['displayName'];
    this['authCookieString'] = data['authCookieString'];
  }

  public static validationModel = {};
}

export class NewCase {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadTypeKey'?: number;

  /**  */
  @Expose()
  'userID'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'coversheet'?: Coversheet;

  /**  */
  'exhibits'?: Exhibits;

  /**  */
  'submittedBy'?: string;

  /**  */
  'documents'?: Document[];

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadTypeKey'] = data['uploadTypeKey'];
    this['userID'] = data['userID'];
    this['attorneyID'] = data['attorneyID'];
    this['attorneyName'] = data['attorneyName'];
    this['filename'] = data['filename'];
    this['coversheet'] = data['coversheet'];
    this['exhibits'] = data['exhibits'];
    this['submittedBy'] = data['submittedBy'];
    this['documents'] = data['documents'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class Order {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadTypeKey'?: number;

  /**  */
  @Expose()
  'userID'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'judicialOfficerID'?: string;

  /**  */
  'exhibits'?: Exhibits;

  /**  */
  'submittedBy'?: string;

  /**  */
  'documents'?: Document[];

  /**  */
  'filenames'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadTypeKey'] = data['uploadTypeKey'];
    this['userID'] = data['userID'];
    this['attorneyID'] = data['attorneyID'];
    this['attorneyName'] = data['attorneyName'];
    this['filename'] = data['filename'];
    this['judicialOfficerID'] = data['judicialOfficerID'];
    this['exhibits'] = data['exhibits'];
    this['submittedBy'] = data['submittedBy'];
    this['documents'] = data['documents'];
    this['filenames'] = data['filenames'];
  }

  public static validationModel = {};
}

export class Party {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'partyType'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'partyTypeCode'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'username'?: string;

  /**  */
  @Expose()
  'addressID'?: string;

  /**  */
  'address'?: Address;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['submissionID'] = data['submissionID'];
    this['name'] = data['name'];
    this['partyType'] = data['partyType'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['partyTypeCode'] = data['partyTypeCode'];
    this['emailAddress'] = data['emailAddress'];
    this['phoneNumber'] = data['phoneNumber'];
    this['barNumber'] = data['barNumber'];
    this['username'] = data['username'];
    this['addressID'] = data['addressID'];
    this['address'] = data['address'];
  }

  public static validationModel = {};
}

export class PartyDetailedResponse {
  /**  */
  'data'?: Party;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class PartyListDetailedResponse {
  /**  */
  'data'?: Party[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class PartyRequest {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'partyType'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'streetAddress'?: string;

  /**  */
  'unit'?: string;

  /**  */
  'city'?: string;

  /**  */
  'state'?: string;

  /**  */
  'postal'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['partyType'] = data['partyType'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['streetAddress'] = data['streetAddress'];
    this['unit'] = data['unit'];
    this['city'] = data['city'];
    this['state'] = data['state'];
    this['postal'] = data['postal'];
  }

  public static validationModel = {};
}

export class PortalConfiguration {
  /**  */
  'state'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'credentialHash'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'cvv'?: string;

  /**  */
  'displayName'?: string;

  /**  */
  'authCookieString'?: string;

  constructor(data: undefined | any = {}) {
    this['state'] = data['state'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['credentialHash'] = data['credentialHash'];
    this['paymentToken'] = data['paymentToken'];
    this['cvv'] = data['cvv'];
    this['displayName'] = data['displayName'];
    this['authCookieString'] = data['authCookieString'];
  }

  public static validationModel = {};
}

export class ProgressResult {
  /**  */
  @Expose()
  'total'?: number;

  /**  */
  @Expose()
  'progress'?: number;

  constructor(data: undefined | any = {}) {
    this['total'] = data['total'];
    this['progress'] = data['progress'];
  }

  public static validationModel = {};
}

export class QueueCount {
  /**  */
  @Expose()
  'existing'?: number;

  /**  */
  @Expose()
  'newCases'?: number;

  /**  */
  @Expose()
  'dfs'?: number;

  /**  */
  @Expose()
  'hold'?: number;

  /**  */
  @Expose()
  'orders'?: number;

  /**  */
  @Expose()
  'total'?: number;

  constructor(data: undefined | any = {}) {
    this['existing'] = data['existing'];
    this['newCases'] = data['newCases'];
    this['dfs'] = data['dfs'];
    this['hold'] = data['hold'];
    this['orders'] = data['orders'];
    this['total'] = data['total'];
  }

  public static validationModel = {};
}

export class QueueCountDetailedResponse {
  /**  */
  'data'?: QueueCount;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class QueuedSubmission {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'legacyID'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'statusID'?: string;

  /**  */
  'statusDescription'?: string;

  /**  */
  'statusReason'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['legacyID'] = data['legacyID'];
    this['filename'] = data['filename'];
    this['statusID'] = data['statusID'];
    this['statusDescription'] = data['statusDescription'];
    this['statusReason'] = data['statusReason'];
    this['uploadedDateTime'] = data['uploadedDateTime'];
  }

  public static validationModel = {};
}

export class QueuedSubmissionListAPIResponse {
  /**  */
  'data'?: QueuedSubmission[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class RejectedRequest {
  /**  */
  @Expose()
  @Type(() => Date)
  'rejectedDateTime'?: Date;

  /**  */
  'reason'?: string;

  constructor(data: undefined | any = {}) {
    this['rejectedDateTime'] = data['rejectedDateTime'];
    this['reason'] = data['reason'];
  }

  public static validationModel = {};
}

export class ReportingRequest {
  /**  */
  @Expose()
  'pageNumber'?: number;

  /**  */
  @Expose()
  'itemsPerPage'?: number;

  /**  */
  @Expose()
  @Type(() => Date)
  'startDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'endDate'?: Date;

  /**  */
  'keyword'?: string;

  /**  */
  '_filterKey'?: SubmissionReportFilterKeys;

  constructor(data: undefined | any = {}) {
    this['pageNumber'] = data['pageNumber'];
    this['itemsPerPage'] = data['itemsPerPage'];
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
    this['keyword'] = data['keyword'];
    this['_filterKey'] = data['_filterKey'];
  }

  public static validationModel = {};
}

export class ReportSubmission {
  /**  */
  'filename'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'matterNumber'?: string;

  /**  */
  'statusDescription'?: string;

  /**  */
  'attorneyName'?: string;

  /**  */
  'referenceNumber'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedDateTime'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'filedDateTime'?: Date;

  constructor(data: undefined | any = {}) {
    this['filename'] = data['filename'];
    this['caseNumber'] = data['caseNumber'];
    this['matterNumber'] = data['matterNumber'];
    this['statusDescription'] = data['statusDescription'];
    this['attorneyName'] = data['attorneyName'];
    this['referenceNumber'] = data['referenceNumber'];
    this['uploadedDateTime'] = data['uploadedDateTime'];
    this['filedDateTime'] = data['filedDateTime'];
  }

  public static validationModel = {};
}

export class ReportSubmissionPagedResult {
  /**  */
  'data'?: ReportSubmission[];

  /**  */
  @Expose()
  'totalCount'?: number;

  /**  */
  @Expose()
  'currentPage'?: number;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['totalCount'] = data['totalCount'];
    this['currentPage'] = data['currentPage'];
  }

  public static validationModel = {};
}

export class ServedRequest {
  /**  */
  @Expose()
  'submissionID'?: string;

  /**  */
  'portalServed'?: boolean;

  /**  */
  'toEmails'?: string[];

  constructor(data: undefined | any = {}) {
    this['submissionID'] = data['submissionID'];
    this['portalServed'] = data['portalServed'];
    this['toEmails'] = data['toEmails'];
  }

  public static validationModel = {};
}

export class Status {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'key'?: number;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['key'] = data['key'];
  }

  public static validationModel = {};
}

export class Stream {
  /**  */
  'canRead'?: boolean;

  /**  */
  'canSeek'?: boolean;

  /**  */
  'canTimeout'?: boolean;

  /**  */
  'canWrite'?: boolean;

  /**  */
  @Expose()
  'length'?: number;

  /**  */
  @Expose()
  'position'?: number;

  /**  */
  @Expose()
  'readTimeout'?: number;

  /**  */
  @Expose()
  'writeTimeout'?: number;

  constructor(data: undefined | any = {}) {
    this['canRead'] = data['canRead'];
    this['canSeek'] = data['canSeek'];
    this['canTimeout'] = data['canTimeout'];
    this['canWrite'] = data['canWrite'];
    this['length'] = data['length'];
    this['position'] = data['position'];
    this['readTimeout'] = data['readTimeout'];
    this['writeTimeout'] = data['writeTimeout'];
  }

  public static validationModel = {};
}

export class StringDetailedResponse {
  /**  */
  'data'?: string;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class Submission {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  @Expose()
  'fileID'?: number;

  /**  */
  'filename'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'emergency'?: boolean;

  /**  */
  'referenceNumber'?: string;

  /**  */
  @Expose()
  'priorityOrder'?: number;

  /**  */
  @Expose()
  'documentCount'?: number;

  /**  */
  'autoFile'?: boolean;

  /**  */
  'override'?: boolean;

  /**  */
  'feePay'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedDateTime'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'filedDateTime'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'acceptedDateTime'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'rejectedDateTime'?: Date;

  /**  */
  @Expose()
  'statusID'?: string;

  /**  */
  'status'?: Status;

  /**  */
  'updatedBy'?: string;

  /**  */
  @Expose()
  'updatedByID'?: string;

  /**  */
  'updatedMessage'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'updatedDate'?: Date;

  /**  */
  'caseData'?: CaseData;

  /**  */
  'attorney'?: Attorney;

  /**  */
  'emails'?: Email[];

  /**  */
  'parties'?: Party[];

  /**  */
  'documents'?: Document[];

  /**  */
  'addresses'?: Address[];

  /**  */
  'fees'?: Fee[];

  /**  */
  'filingPartyID'?: string;

  /**  */
  'filingAttorneyID'?: string;

  /**  */
  'generateCoverSheet'?: boolean;

  /**  */
  'submitterName'?: string;

  /**  */
  'portalConfiguration'?: IPortalConfiguration;

  /**  */
  @Expose()
  'uploadID'?: string;

  /**  */
  'upload'?: Upload;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['attorneyID'] = data['attorneyID'];
    this['fileID'] = data['fileID'];
    this['filename'] = data['filename'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['emergency'] = data['emergency'];
    this['referenceNumber'] = data['referenceNumber'];
    this['priorityOrder'] = data['priorityOrder'];
    this['documentCount'] = data['documentCount'];
    this['autoFile'] = data['autoFile'];
    this['override'] = data['override'];
    this['feePay'] = data['feePay'];
    this['uploadedDateTime'] = data['uploadedDateTime'];
    this['filedDateTime'] = data['filedDateTime'];
    this['acceptedDateTime'] = data['acceptedDateTime'];
    this['rejectedDateTime'] = data['rejectedDateTime'];
    this['statusID'] = data['statusID'];
    this['status'] = data['status'];
    this['updatedBy'] = data['updatedBy'];
    this['updatedByID'] = data['updatedByID'];
    this['updatedMessage'] = data['updatedMessage'];
    this['updatedDate'] = data['updatedDate'];
    this['caseData'] = data['caseData'];
    this['attorney'] = data['attorney'];
    this['emails'] = data['emails'];
    this['parties'] = data['parties'];
    this['documents'] = data['documents'];
    this['addresses'] = data['addresses'];
    this['fees'] = data['fees'];
    this['filingPartyID'] = data['filingPartyID'];
    this['filingAttorneyID'] = data['filingAttorneyID'];
    this['generateCoverSheet'] = data['generateCoverSheet'];
    this['submitterName'] = data['submitterName'];
    this['portalConfiguration'] = data['portalConfiguration'];
    this['uploadID'] = data['uploadID'];
    this['upload'] = data['upload'];
  }

  public static validationModel = {};
}

export class SubmissionAPIResponse {
  /**  */
  'data'?: Submission;

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class SubmissionDetailedResponse {
  /**  */
  'data'?: Submission;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class SubmissionIEnumerableAPIResponse {
  /**  */
  'data'?: Submission[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class SubmissionIEnumerableDetailedResponse {
  /**  */
  'data'?: Submission[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class SubmissionListAPIResponse {
  /**  */
  'data'?: Submission[];

  /**  */
  'successful'?: boolean;

  /**  */
  'message'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['message'] = data['message'];
  }

  public static validationModel = {};
}

export class SubmissionListDetailedResponse {
  /**  */
  'data'?: Submission[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class SubmissionPagedResult {
  /**  */
  'data'?: Submission[];

  /**  */
  @Expose()
  'totalCount'?: number;

  /**  */
  @Expose()
  'currentPage'?: number;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['totalCount'] = data['totalCount'];
    this['currentPage'] = data['currentPage'];
  }

  public static validationModel = {};
}

export class Upload {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'filename'?: string;

  /**  */
  'ip'?: string;

  /**  */
  @Expose()
  'totalDocuments'?: number;

  /**  */
  @Expose()
  'uploadedByID'?: string;

  /**  */
  'uploadedBy'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'uploadedDateTime'?: Date;

  /**  */
  'submitted'?: boolean;

  /**  */
  'cancelled'?: boolean;

  /**  */
  'cancelledBy'?: string;

  /**  */
  'fileHash'?: string;

  /**  */
  'uploadedFiles'?: UploadedFile[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['filename'] = data['filename'];
    this['ip'] = data['ip'];
    this['totalDocuments'] = data['totalDocuments'];
    this['uploadedByID'] = data['uploadedByID'];
    this['uploadedBy'] = data['uploadedBy'];
    this['uploadedDateTime'] = data['uploadedDateTime'];
    this['submitted'] = data['submitted'];
    this['cancelled'] = data['cancelled'];
    this['cancelledBy'] = data['cancelledBy'];
    this['fileHash'] = data['fileHash'];
    this['uploadedFiles'] = data['uploadedFiles'];
  }

  public static validationModel = {};
}

export class UploadedFile {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'uploadID'?: string;

  /**  */
  'upload'?: Upload;

  /**  */
  'filename'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['uploadID'] = data['uploadID'];
    this['upload'] = data['upload'];
    this['filename'] = data['filename'];
  }

  public static validationModel = {};
}

export class UploadPagedResult {
  /**  */
  'data'?: Upload[];

  /**  */
  @Expose()
  'totalCount'?: number;

  /**  */
  @Expose()
  'currentPage'?: number;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['totalCount'] = data['totalCount'];
    this['currentPage'] = data['currentPage'];
  }

  public static validationModel = {};
}

export class V1File {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'fileID'?: number;

  /**  */
  @Expose()
  'process'?: number;

  /**  */
  'filename'?: string;

  /**  */
  'partyNames'?: string;

  /**  */
  'uniformCaseNumber'?: string;

  /**  */
  'clerkCaseNumber'?: string;

  /**  */
  'portalReferenceNumber'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['fileID'] = data['fileID'];
    this['process'] = data['process'];
    this['filename'] = data['filename'];
    this['partyNames'] = data['partyNames'];
    this['uniformCaseNumber'] = data['uniformCaseNumber'];
    this['clerkCaseNumber'] = data['clerkCaseNumber'];
    this['portalReferenceNumber'] = data['portalReferenceNumber'];
  }

  public static validationModel = {};
}

export class V1FileDetailedResponse {
  /**  */
  'data'?: V1File;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class V1FileListDetailedResponse {
  /**  */
  'data'?: V1File[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export type SubmissionReportFilterKeys = 1 | 2 | 4 | 8 | 16 | 32 | 64;
