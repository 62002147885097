import { BehaviorSubject } from "rxjs";
import * as APIModels from "../models";
import { UserService } from "../services/ClientService";
import { authenticationService } from "../services/authentication.service";

const meSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("me")!));

function retrieveMe(): Promise<void> {
    return new Promise((resolve, reject) => {
        UserService.me().then(
            (resp) => {
                if (resp.successful && resp.data) {
                    localStorage.setItem("me", JSON.stringify(resp.data));
                    meSubject.next(resp.data);
                } else {
                    //authenticationService.logout();
                }
                resolve();
            },
            (error) => {
                //authenticationService.logout();
                resolve();
            }
        );
    });
}

function removeMe(): void {
    localStorage.removeItem("me");
    meSubject.next(null);
}

export const Globals = {
    retrieveMe,
    removeMe,
    me: meSubject.asObservable(),
    get currentUserValue(): APIModels.User {
        return meSubject.value;
    },
};
