import * as Services from "../models/swagger/generated-ereturn-service";
import { fileService } from "./file.service";

export const FileService = {
    upload: fileService.upload,
    get: fileService.get,
};

export const EReturnsService = {
    ereturn: Services.EReturnsService.ereturn,
    getSubmissionsToday: Services.EReturnsService.today,
};
