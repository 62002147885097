import { FileService, eFileService } from "../../services/SubmissionService";
import * as APIModels from "../../models";
import pdfIcon from "../../assets/images/file-icons/pdf.svg";
import zipIcon from "../../assets/images/file-icons/zip.svg";
import { useCallback, useRef, useState } from "react";
import { useEffect } from "react";
import { formatBytes } from "../../helpers/utility";
import { Globals } from "../../helpers/globals";
import AttorneySelect from "../shared/AttorneySelect";
import ConfirmationModal from "../../modals/ConfimationModal";
import { useDropzone } from "react-dropzone";
import { EReturnsService } from "../../models/swagger/generated-ereturn-service";

export default function FileManager() {
    const f: APIModels.FileInfo[] = [];
    const [files, setFiles] = useState(f);

    const [selectedFiles, setSelectedFiles] = useState(f);
    const iframeRef = useRef<HTMLEmbedElement>(null);
    const [refresh, setRefresh] = useState(false);
    const [hoverElement, setHoverElement] = useState(
        null as HTMLElement | null
    );

    const [eFileUpload, seteFileUpload] = useState(
        null as APIModels.eFile | null
    );

    const [modalShow, setModalShow] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const [selectedAttorneyId, setSelectedAttorneyId] = useState("");
    const [selectedAttorneyName, setSelectedAttorneyName] = useState("");
    const [selectedFirmId, setSelectedFirmId] = useState("");

    const [isCheckAll, setIsCheckAll] = useState(false);

    const currentUser = Globals.currentUserValue;

    const onAttorneySelected = (id: string, name: string) => {
        if (eFileUpload === null) {
            seteFileUpload({ attorneyID: id, attorneyName: name });
        } else {
            seteFileUpload({
                ...eFileUpload,
                attorneyID: id,
                attorneyName: name,
            });
        }
        setSelectedAttorneyId(id);
        setSelectedAttorneyName(name);
    };
    const onFirmSelected = (id: string, name: string) => {
        seteFileUpload({ ...eFileUpload, attorneyID: "", attorneyName: "" });
        setSelectedFirmId(id);
    };

    useEffect(() => {
        console.log("hit refresh");
        FileService.list().then(
            (resp) => {
                if (resp.successful === true && resp.data) {
                    console.log("refresh success");
                    console.log("files: " + resp.data);
                    setFiles(resp.data);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, [refresh]);

    const handleMouseOver = (
        event: React.MouseEvent<HTMLImageElement>,
        file: APIModels.FileInfo
    ) => {
        if (file.filename!.indexOf(".pdf") > 0) {
            setHoverElement(event.currentTarget);

            FileService.get({
                submissionId: currentUser.id + "/uploads",
                documentId: file.filename ?? "",
                source: "upload",
            }).then(
                (resp) => {
                    resp.json().then((json) => {
                        if (iframeRef && iframeRef.current)
                            iframeRef.current.src = json.url;
                    });
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const handleMouseOut = (event: React.MouseEvent<HTMLImageElement>) => {
        if (event.currentTarget == hoverElement) {
            setHoverElement(null);
        }
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            const filInfo = [...files];

            acceptedFiles.forEach((file: any) => {
                filInfo.push({ filename: file.name, size: file.size });

                FileService.upload({
                    file: file,
                    filename: file.name,
                }).then(
                    (resp) => {
                        setRefresh(!refresh);
                        console.log(resp);
                        resp.text().then((text) => console.log(text));
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            });

            setFiles(filInfo);
        },
        [files]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: true,
        noKeyboard: true,
        maxSize: 35000000,
        accept: "application/pdf",
        multiple: true,
    });

    const onFileUpload = (event: React.FormEvent) => {
        event.preventDefault();
        const input = event.target as HTMLInputElement;

        if (!input.files?.length) {
            return;
        }
        const filInfo = [...files];

        for (let i = 0; i < input.files.length; i++) {
            const f = input.files[i];

            filInfo.push({ filename: f.name, size: f.size });
            setFiles(filInfo);

            FileService.upload({ file: f, filename: f.name }).then(
                (resp) => {
                    setRefresh(!refresh);
                    input.value = "";
                },
                (error) => {
                    input.value = "";
                }
            );
        }
    };

    const onDelete = (file: APIModels.FileInfo) => {
        if (file.filename) {
            FileService.delete({ filename: file.filename }).then(
                (resp) => {
                    setRefresh(!refresh);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    const hoverPreview = () => {
        const rect = hoverElement!.getBoundingClientRect();
        return (
            <div
                style={{
                    position: "fixed",
                    top: rect.top,
                    left: rect.left,
                    height: "800px",
                    width: "800px",
                    backgroundColor: "white",
                    pointerEvents: "none",
                }}
            >
                <embed
                    ref={iframeRef}
                    type="application/pdf"
                    style={{ height: "800px", width: "800px" }}
                ></embed>
            </div>
        );
    };

    const handleSelectAll = () => {
        setIsCheckAll(!isCheckAll);

        if (isCheckAll) {
            setSelectedFiles([]);
        } else {
            setSelectedFiles(files);
        }
    };

    const onSelectFile = (index: number, file: APIModels.FileInfo) => {
        const matchingIndex = selectedFiles.findIndex(
            (v) => v.filename === file.filename
        );

        if (matchingIndex >= 0) {
            const filesUpdated = [...selectedFiles];
            filesUpdated.splice(matchingIndex, 1);
            setSelectedFiles(filesUpdated);
        } else {
            setSelectedFiles([...selectedFiles, file]);
        }

        return false;
    };

    const onEfile = () => {
        setErrorMessage("");

        if (selectedAttorneyId === "") {
            setModalShow(false);
            setErrorMessage("Please select attorney before eFiling.");
        } else if (selectedFiles.length === 0) {
            setModalShow(false);
            setErrorMessage(
                "Please select the file(s) you would like to eFile."
            );
        } else {
            const filesToSubmit = selectedFiles.map((v) => v.filename!);
            seteFileUpload({
                ...eFileUpload,
                filenames: filesToSubmit,
            });
            setModalShow(true);
        }
    };

    const onConfirm = () => {
        EReturnsService.ereturn({
            body: eFileUpload!,
        }).then(
            (resp) => {
                if (resp.successful === true && resp.data) {
                    setModalShow(false);
                    setSelectedFiles([]);
                } else {
                    console.log(resp.errorMessage);
                }

                setRefresh(!refresh);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    return (
        <div>
            <div className="card-body">
                <ConfirmationModal
                    show={modalShow}
                    selectedFiles={selectedFiles}
                    attorneyName={selectedAttorneyName}
                    onConfirm={onConfirm}
                    onCancel={() => setModalShow(false)}
                />
                <div className="row">
                    <div className="col-sm-12">
                        <div
                            {...getRootProps({
                                className: "dropzone",
                            })}
                            style={{ border: "none" }}
                        >
                            <input {...getInputProps()} />
                            <div className="text-center">
                                <i className="h1 text-muted ri-upload-cloud-2-line">
                                    ...
                                </i>
                                <h3 className="text-center">
                                    Drop files here or click button to upload.
                                </h3>
                            </div>

                            <div className="fileupload btn btn-success waves-effect waves-light mb-3">
                                <span>
                                    <i className="mdi mdi-cloud-upload me-1"></i>{" "}
                                    Upload Files
                                </span>
                                <input
                                    type="file"
                                    className="upload"
                                    onChange={onFileUpload}
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {" "}
                    <div
                        className="table-responsive"
                        style={{ overflow: "visible" }}
                    >
                        {hoverElement && hoverPreview()}
                        <table
                            className="table table-centered  table-nowrap mb-0"
                            style={{ zIndex: 0 }}
                        >
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">
                                        <input
                                            className="me-2"
                                            type="checkbox"
                                            checked={isCheckAll}
                                            onChange={handleSelectAll}
                                        />
                                        Select All
                                    </th>

                                    <th scope="col">File Name</th>
                                    <th scope="col">Date Modified</th>
                                    <th scope="col">Size</th>
                                    <th
                                        scope="col"
                                        className="text-center"
                                        style={{ width: 125 }}
                                    >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {files.map((file, i) => {
                                    return (
                                        <tr>
                                            <td>
                                                <input
                                                    key={i}
                                                    type="checkbox"
                                                    checked={
                                                        selectedFiles.filter(
                                                            (v) =>
                                                                v.filename ===
                                                                file.filename
                                                        ).length > 0
                                                    }
                                                    onChange={() =>
                                                        onSelectFile(i, file)
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <img
                                                    src={
                                                        file.filename &&
                                                        file.filename
                                                            .toLowerCase()
                                                            .endsWith(".pdf")
                                                            ? pdfIcon
                                                            : zipIcon
                                                    }
                                                    height="30"
                                                    alt="icon"
                                                    className="me-2"
                                                    onMouseEnter={(e) =>
                                                        handleMouseOver(e, file)
                                                    }
                                                    onMouseLeave={
                                                        handleMouseOut
                                                    }
                                                />
                                                <a
                                                    href="javascript:void(0);"
                                                    className="text-dark"
                                                >
                                                    {file.filename}
                                                </a>
                                            </td>
                                            <td className="font-13">
                                                {file.lastUpdatedDateTime}
                                            </td>
                                            <td>
                                                {formatBytes(file.size ?? 0)}
                                            </td>
                                            {file.lastUpdatedDateTime ? (
                                                <td>
                                                    {(selectedFiles.length ==
                                                        0 ||
                                                        selectedFiles.findIndex(
                                                            (v) =>
                                                                v.filename ===
                                                                file.filename
                                                        ) == 0) && (
                                                        <>
                                                            <ul
                                                                className="list-inline table-action m-0"
                                                                style={{
                                                                    display:
                                                                        "inline-block",
                                                                }}
                                                            >
                                                                <li className="list-inline-item">
                                                                    <a
                                                                        href="javascript:void(0);"
                                                                        className="action-icon px-1"
                                                                        onClick={() =>
                                                                            onDelete(
                                                                                file
                                                                            )
                                                                        }
                                                                    >
                                                                        {" "}
                                                                        <i className="mdi mdi-delete"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </>
                                                    )}
                                                </td>
                                            ) : (
                                                <td>
                                                    <div
                                                        className="spinner-border text-primary m-2"
                                                        role="status"
                                                    >
                                                        <span className="visually-hidden">
                                                            Loading...
                                                        </span>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="card-body">
                <div className="row">
                    <AttorneySelect
                        onAttorneySelected={onAttorneySelected}
                        onFirmSelected={onFirmSelected}
                    />
                    <div className="col-12 text-end mb-3">
                        {" "}
                        <button
                            type="button"
                            className="btn btn-primary mt-2"
                            onClick={onEfile}
                        >
                            eFile{" "}
                            {selectedFiles.length == 0
                                ? ""
                                : selectedFiles.length}{" "}
                            selected file(s).
                            <i className="mdi mdi-arrow-right"></i>{" "}
                        </button>
                        <div style={{ color: "red" }}>{errorMessage}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
