/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CasesService {
  /**
   *
   */
  static cases(
    params: {
      /** requestBody */
      body?: CourtCase;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourtCaseDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Cases';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CourtCaseDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /** requestBody */
      body?: CaseSearch;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourtCaseListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Cases/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CourtCaseListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static serviceList(
    params: {
      /**  */
      countyId?: string;
      /**  */
      ucn?: string;
      /**  */
      attorneyId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ServicePersonListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Cases/ServiceList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { countyId: params['countyId'], ucn: params['ucn'], attorneyId: params['attorneyId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(ServicePersonListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static byReferenceNumber(
    params: {
      /**  */
      attorneyId?: string;
      /**  */
      portalIdentifier?: string;
      /**  */
      referenceNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourtCaseDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Cases/ByReferenceNumber';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        attorneyId: params['attorneyId'],
        portalIdentifier: params['portalIdentifier'],
        referenceNumber: params['referenceNumber']
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CourtCaseDetailedResponse, response)), reject);
    });
  }
}

export class CaseSearch {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'state'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'courtIdentifier'?: string;

  /**  */
  'sequenceNumber'?: string;

  /**  */
  @Expose()
  'year'?: number;

  /**  */
  'partyIdentifier'?: string;

  /**  */
  'locationIdentifier'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'organization'?: string;

  /**  */
  'trackingNumber'?: string;

  /**  */
  'ucn'?: string;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['state'] = data['state'];
    this['countyID'] = data['countyID'];
    this['divisionID'] = data['divisionID'];
    this['courtIdentifier'] = data['courtIdentifier'];
    this['sequenceNumber'] = data['sequenceNumber'];
    this['year'] = data['year'];
    this['partyIdentifier'] = data['partyIdentifier'];
    this['locationIdentifier'] = data['locationIdentifier'];
    this['caseNumber'] = data['caseNumber'];
    this['firstName'] = data['firstName'];
    this['lastName'] = data['lastName'];
    this['organization'] = data['organization'];
    this['trackingNumber'] = data['trackingNumber'];
    this['ucn'] = data['ucn'];
  }

  public static validationModel = {};
}

export class ContactInformation {
  /**  */
  @Expose()
  'submissionAddressID'?: number;

  /**  */
  'fullAddress'?: string;

  /**  */
  'state'?: string;

  /**  */
  'streetAddress1'?: string;

  /**  */
  'streetAddress2'?: string;

  /**  */
  'zip'?: string;

  /**  */
  'city'?: string;

  /**  */
  'phoneNumber'?: string;

  /**  */
  'emailAddress'?: string;

  constructor(data: undefined | any = {}) {
    this['submissionAddressID'] = data['submissionAddressID'];
    this['fullAddress'] = data['fullAddress'];
    this['state'] = data['state'];
    this['streetAddress1'] = data['streetAddress1'];
    this['streetAddress2'] = data['streetAddress2'];
    this['zip'] = data['zip'];
    this['city'] = data['city'];
    this['phoneNumber'] = data['phoneNumber'];
    this['emailAddress'] = data['emailAddress'];
  }

  public static validationModel = {};
}

export class CourtCase {
  /**  */
  @Expose()
  'attorneyID'?: string;

  /**  */
  'trackingNumber'?: string;

  /**  */
  'ucn'?: string;

  /**  */
  @Expose()
  'caseID'?: number;

  /**  */
  'courtType'?: Court;

  /**  */
  'caseTitle'?: string;

  /**  */
  'caseNumber'?: string;

  /**  */
  'county'?: string;

  /**  */
  'circuit'?: string;

  /**  */
  'countyPortalID'?: string;

  /**  */
  'division'?: string;

  /**  */
  'divisionPortalID'?: string;

  /**  */
  'caseTypeCode'?: string;

  /**  */
  'caseSubTypeCode'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  'caseSubType'?: string;

  /**  */
  'officer'?: string;

  /**  */
  'defendantName'?: string;

  /**  */
  'portalStatus'?: string;

  /**  */
  'notes'?: string;

  /**  */
  'caseClosed'?: boolean;

  /**  */
  'referenceNumber'?: string;

  /**  */
  'matterNumber'?: string;

  /**  */
  'state'?: string;

  /**  */
  'parties'?: Party[];

  /**  */
  'judicialOfficerName'?: string;

  /**  */
  'judicialOfficerID'?: string;

  /**  */
  'damagesCode'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  @Expose()
  'caseSubTypeID'?: string;

  constructor(data: undefined | any = {}) {
    this['attorneyID'] = data['attorneyID'];
    this['trackingNumber'] = data['trackingNumber'];
    this['ucn'] = data['ucn'];
    this['caseID'] = data['caseID'];
    this['courtType'] = data['courtType'];
    this['caseTitle'] = data['caseTitle'];
    this['caseNumber'] = data['caseNumber'];
    this['county'] = data['county'];
    this['circuit'] = data['circuit'];
    this['countyPortalID'] = data['countyPortalID'];
    this['division'] = data['division'];
    this['divisionPortalID'] = data['divisionPortalID'];
    this['caseTypeCode'] = data['caseTypeCode'];
    this['caseSubTypeCode'] = data['caseSubTypeCode'];
    this['caseType'] = data['caseType'];
    this['caseSubType'] = data['caseSubType'];
    this['officer'] = data['officer'];
    this['defendantName'] = data['defendantName'];
    this['portalStatus'] = data['portalStatus'];
    this['notes'] = data['notes'];
    this['caseClosed'] = data['caseClosed'];
    this['referenceNumber'] = data['referenceNumber'];
    this['matterNumber'] = data['matterNumber'];
    this['state'] = data['state'];
    this['parties'] = data['parties'];
    this['judicialOfficerName'] = data['judicialOfficerName'];
    this['judicialOfficerID'] = data['judicialOfficerID'];
    this['damagesCode'] = data['damagesCode'];
    this['caseTypeID'] = data['caseTypeID'];
    this['caseSubTypeID'] = data['caseSubTypeID'];
  }

  public static validationModel = {};
}

export class CourtCaseDetailedResponse {
  /**  */
  'data'?: CourtCase;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CourtCaseListDetailedResponse {
  /**  */
  'data'?: CourtCase[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class Party {
  /**  */
  @Expose()
  'partyID'?: number;

  /**  */
  'userName'?: string;

  /**  */
  'barNumber'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'suffix'?: string;

  /**  */
  'organization'?: string;

  /**  */
  'guid'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  'representedPartyGUID'?: string;

  /**  */
  'contactInfo'?: ContactInformation;

  /**  */
  'partyType'?: string;

  /**  */
  'partyTypeCode'?: string;

  /**  */
  'emailAddress'?: string;

  /**  */
  'fullName'?: string;

  /**  */
  'interestedParty'?: Party;

  /**  */
  'routingNumber'?: string;

  /**  */
  'accountNumber'?: string;

  /**  */
  'accountName'?: string;

  /**  */
  @Expose()
  'efmeFilingFee'?: number;

  /**  */
  @Expose()
  'efmeFilingTaxAmount'?: number;

  /**  */
  'portalID'?: string;

  /**  */
  'attorneyID'?: string;

  constructor(data: undefined | any = {}) {
    this['partyID'] = data['partyID'];
    this['userName'] = data['userName'];
    this['barNumber'] = data['barNumber'];
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['suffix'] = data['suffix'];
    this['organization'] = data['organization'];
    this['guid'] = data['guid'];
    this['paymentToken'] = data['paymentToken'];
    this['representedPartyGUID'] = data['representedPartyGUID'];
    this['contactInfo'] = data['contactInfo'];
    this['partyType'] = data['partyType'];
    this['partyTypeCode'] = data['partyTypeCode'];
    this['emailAddress'] = data['emailAddress'];
    this['fullName'] = data['fullName'];
    this['interestedParty'] = data['interestedParty'];
    this['routingNumber'] = data['routingNumber'];
    this['accountNumber'] = data['accountNumber'];
    this['accountName'] = data['accountName'];
    this['efmeFilingFee'] = data['efmeFilingFee'];
    this['efmeFilingTaxAmount'] = data['efmeFilingTaxAmount'];
    this['portalID'] = data['portalID'];
    this['attorneyID'] = data['attorneyID'];
  }

  public static validationModel = {};
}

export class ServicePerson {
  /**  */
  'firstName'?: string;

  /**  */
  'middleName'?: string;

  /**  */
  'lastName'?: string;

  /**  */
  'email'?: string;

  /**  */
  'name'?: string;

  /**  */
  @Expose()
  'partyID'?: number;

  /**  */
  'contactInfo'?: ContactInformation;

  constructor(data: undefined | any = {}) {
    this['firstName'] = data['firstName'];
    this['middleName'] = data['middleName'];
    this['lastName'] = data['lastName'];
    this['email'] = data['email'];
    this['name'] = data['name'];
    this['partyID'] = data['partyID'];
    this['contactInfo'] = data['contactInfo'];
  }

  public static validationModel = {};
}

export class ServicePersonListDetailedResponse {
  /**  */
  'data'?: ServicePerson[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export type Court = 0 | 1 | 2 | 3 | 4 | 5;
