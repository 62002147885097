/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class CaseSubTypeService {
  /**
   *
   */
  static caseType(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseSubType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/caseType/{caseTypeID}';
      url = url.replace('{caseTypeID}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CaseSubType, response)), reject);
    });
  }
  /**
   *
   */
  static caseSubType(
    params: {
      /**  */
      caseSubTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseSubType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/{caseSubTypeId}';
      url = url.replace('{caseSubTypeId}', params['caseSubTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CaseSubType, response)), reject);
    });
  }
  /**
   *
   */
  static code(
    params: {
      /**  */
      divisionId: string;
      /**  */
      code: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/code/{divisionId}/{code}';
      url = url.replace('{divisionId}', params['divisionId'] + '');
      url = url.replace('{code}', params['code'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static text(
    params: {
      /**  */
      divisionId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/text/{divisionId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static ePortalId(
    params: {
      /**  */
      divisionId: string;
      /**  */
      eportalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/ePortalID/{divisionId}/{eportalId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');
      url = url.replace('{eportalId}', params['eportalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static division(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseSubType/division/{divisionId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
}

export class CaseTypeService {
  /**
   *
   */
  static caseType(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static division(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/division/{divisionId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static portal(
    params: {
      /**  */
      countyId: string;
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/portal/{countyId}/{divisionId}';
      url = url.replace('{countyId}', params['countyId'] + '');
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static sync(
    params: {
      /**  */
      countyId: string;
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeStagingListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/sync/{countyId}/{divisionId}';
      url = url.replace('{countyId}', params['countyId'] + '');
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeStagingListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static division1(
    params: {
      /**  */
      divisionId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/division/{divisionID}/{ePortalID}';
      url = url.replace('{divisionId}', params['divisionId'] + '');
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      divisionId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CaseType/division/{divisionID}/name';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseType, response)), reject);
    });
  }
}

export class CodesService {
  /**
   *
   */
  static crossreference(options: IRequestOptions = {}): Promise<CrossReferenceCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/crossreference';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CrossReferenceCode, response)), reject);
    });
  }
  /**
   *
   */
  static crossreference1(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CrossReferenceCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/crossreference/{CaseTypeID}';
      url = url.replace('{CaseTypeID}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CrossReferenceCode, response)), reject);
    });
  }
  /**
   *
   */
  static damage(options: IRequestOptions = {}): Promise<DamageCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/damage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(DamageCode, response)), reject);
    });
  }
  /**
   *
   */
  static damage1(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DamageCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/damage/{DivisionID}';
      url = url.replace('{DivisionID}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(DamageCode, response)), reject);
    });
  }
  /**
   *
   */
  static division(
    params: {
      /**  */
      divisionId: string;
      /**  */
      eportalid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DamageCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/damage/division/{divisionId}/{eportalid}';
      url = url.replace('{divisionId}', params['divisionId'] + '');
      url = url.replace('{eportalid}', params['eportalid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DamageCode, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      divisionId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DamageCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/damage/division/{divisionId}/name';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DamageCode, response)), reject);
    });
  }
  /**
   *
   */
  static filer(options: IRequestOptions = {}): Promise<FilerCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filer';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilerCode, response)), reject);
    });
  }
  /**
   *
   */
  static filer1(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilerCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filer/{CountyID}';
      url = url.replace('{CountyID}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilerCode, response)), reject);
    });
  }
  /**
   *
   */
  static filing(options: IRequestOptions = {}): Promise<FilingCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filing';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilingCode, response)), reject);
    });
  }
  /**
   *
   */
  static filing1(
    params: {
      /**  */
      caseTypeId: string;
      /**  */
      newCase?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilingCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filing/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { newCase: params['newCase'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilingCode, response)), reject);
    });
  }
  /**
   *
   */
  static name1(
    params: {
      /**  */
      countyId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilingCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filing/county/{countyID}/name';
      url = url.replace('{countyID}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FilingCode, response)), reject);
    });
  }
  /**
   *
   */
  static ePortalId(
    params: {
      /**  */
      countyId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilingCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filing/county/{countyID}/ePortalID/{ePortalID}';
      url = url.replace('{countyID}', params['countyId'] + '');
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(FilingCode, response)), reject);
    });
  }
  /**
   *
   */
  static filingcomponent(options: IRequestOptions = {}): Promise<FilingComponentCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filingcomponent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilingComponentCode, response)), reject);
    });
  }
  /**
   *
   */
  static county(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilingComponentCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filingcomponent/County/{CountyID}';
      url = url.replace('{CountyID}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilingComponentCode, response)), reject);
    });
  }
  /**
   *
   */
  static filingCode(
    params: {
      /**  */
      filingCodeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FilingComponentCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/filingcomponent/FilingCode/{FilingCodeID}';
      url = url.replace('{FilingCodeID}', params['filingCodeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(FilingComponentCode, response)), reject);
    });
  }
  /**
   *
   */
  static optionalservice(options: IRequestOptions = {}): Promise<OptionalServicesCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/optionalservice';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(OptionalServicesCode, response)), reject);
    });
  }
  /**
   *
   */
  static filingCode1(
    params: {
      /**  */
      filingCodeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionalServicesCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/optionalservice/FilingCode/{FilingCodeID}';
      url = url.replace('{FilingCodeID}', params['filingCodeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(OptionalServicesCode, response)), reject);
    });
  }
  /**
   *
   */
  static county1(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionalServicesCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/optionalservice/County/{CountyID}';
      url = url.replace('{CountyID}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(OptionalServicesCode, response)), reject);
    });
  }
  /**
   *
   */
  static ePortalId1(
    params: {
      /**  */
      filingCodeId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionalServicesCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/optionsalservice/FilingCode/{FilingCodeID}/ePortalID/{ePortalID}';
      url = url.replace('{FilingCodeID}', params['filingCodeId'] + '');
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(OptionalServicesCode, response)), reject);
    });
  }
  /**
   *
   */
  static remedy(options: IRequestOptions = {}): Promise<RemedyCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/remedy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(RemedyCode, response)), reject);
    });
  }
  /**
   *
   */
  static remedy1(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RemedyCode[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Codes/remedy/{divisionID}';
      url = url.replace('{DivisionID}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(RemedyCode, response)), reject);
    });
  }
}

export class CountyService {
  /**
   *
   */
  static county(options: IRequestOptions = {}): Promise<County[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(County, response)), reject);
    });
  }
  /**
   *
   */
  static state(
    params: {
      /**  */
      state: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<County[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/state/{state}';
      url = url.replace('{state}', params['state'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(County, response)), reject);
    });
  }
  /**
   *
   */
  static stateid(
    params: {
      /**  */
      stateId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<County[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/stateid/{stateId}';
      url = url.replace('{stateId}', params['stateId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(County, response)), reject);
    });
  }
  /**
   *
   */
  static county1(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<County> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/{countyId}';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(County, response)), reject);
    });
  }
  /**
   *
   */
  static judicialofficers(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JudicialOfficerListDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/{countyId}/judicialofficers';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(JudicialOfficerListDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static portalid(
    params: {
      /**  */
      stateId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<County> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/portalid/{stateId}/{ePortalId}';
      url = url.replace('{stateId}', params['stateId'] + '');
      url = url.replace('{ePortalId}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(County, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      stateId: string;
      /**  */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<County> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/County/name/{stateId}/{name}';
      url = url.replace('{stateId}', params['stateId'] + '');
      url = url.replace('{name}', params['name'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(County, response)), reject);
    });
  }
}

export class CourtTypeService {
  /**
   *
   */
  static courtType(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourtType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CourtType/{divisionId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CourtType, response)), reject);
    });
  }
}

export class CoversheetService {
  /**
   *
   */
  static courttypes(
    params: {
      /**  */
      state: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCourtType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/courttypes/{state}';
      url = url.replace('{state}', params['state'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CoversheetCourtType, response)), reject);
    });
  }
  /**
   *
   */
  static caseTypes(
    params: {
      /**  */
      courtTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseTypes/{courtTypeId}';
      url = url.replace('{courtTypeId}', params['courtTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CoversheetCaseType, response)), reject);
    });
  }
  /**
   *
   */
  static caseSubTypes(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseSubType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseSubTypes/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CoversheetCaseSubType, response)), reject);
    });
  }
  /**
   *
   */
  static list(
    params: {
      /**  */
      courtTypeId: string;
      /**  */
      caseTypeId: string;
      /**  */
      caseSubTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeCoverSheetDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/list/{courtTypeId}/{caseTypeId}/{caseSubTypeId}';
      url = url.replace('{courtTypeId}', params['courtTypeId'] + '');
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');
      url = url.replace('{caseSubTypeId}', params['caseSubTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeCoverSheetDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static mapped(
    params: {
      /** requestBody */
      body?: MappedCoversheetRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CaseTypeCoverSheetDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/mapped';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CaseTypeCoverSheetDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseType(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseTypeDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseType';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CoversheetCaseTypeDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseType1(
    params: {
      /** requestBody */
      body?: CoversheetCaseType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseTypeDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseType';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CoversheetCaseTypeDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseType2(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: CoversheetCaseType;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseTypeDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseType/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CoversheetCaseTypeDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static caseType3(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CoversheetCaseTypeDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Coversheet/caseType/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CoversheetCaseTypeDetailedResponse, response)), reject);
    });
  }
}

export class DivisionService {
  /**
   *
   */
  static county(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Division[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Division/county/{countyId}';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Division, response)), reject);
    });
  }
  /**
   *
   */
  static division(
    params: {
      /**  */
      divisionId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Division> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Division/{divisionId}';
      url = url.replace('{divisionId}', params['divisionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Division, response)), reject);
    });
  }
  /**
   *
   */
  static division1(
    params: {
      /**  */
      countyId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Division> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Division/{countyId}/{ePortalId}';
      url = url.replace('{countyId}', params['countyId'] + '');
      url = url.replace('{ePortalId}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Division, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      countyId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Division> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Division/{countyId}/name';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(Division, response)), reject);
    });
  }
}

export class DocumentTypeService {
  /**
   *
   */
  static documentType(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IDocumentType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentType/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IDocumentType, response)), reject);
    });
  }
  /**
   *
   */
  static county(
    params: {
      /**  */
      countyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IDocumentType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentType/county/{countyId}';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IDocumentType, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      caseTypeId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentType/caseType/{caseTypeID}/name';
      url = url.replace('{caseTypeID}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentType, response)), reject);
    });
  }
  /**
   *
   */
  static ePortalId(
    params: {
      /**  */
      caseTypeId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentType> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/DocumentType/caseType/{caseTypeID}/ePortalID/{ePortalID}';
      url = url.replace('{caseTypeID}', params['caseTypeId'] + '');
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(DocumentType, response)), reject);
    });
  }
}

export class FeeService {
  /**
   *
   */
  static fee(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IFee[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Fee/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IFee, response)), reject);
    });
  }
}

export class JudicialOfficerService {
  /**
   *
   */
  static judicialofficer(
    params: {
      /**  */
      countyId: string;
      /**  */
      name?: string;
      /**  */
      division?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JudicialOfficerDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/JudicialOfficer/judicialofficer/{countyId}';
      url = url.replace('{countyId}', params['countyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'], division: params['division'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(JudicialOfficerDetailedResponse, response)), reject);
    });
  }
  /**
   *
   */
  static judicialofficer1(
    params: {
      /** requestBody */
      body?: JudicialOfficer;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JudicialOfficerDetailedResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/JudicialOfficer/judicialofficer';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(JudicialOfficerDetailedResponse, response)), reject);
    });
  }
}

export class PartyTypeService {
  /**
   *
   */
  static partyType(
    params: {
      /**  */
      caseTypeId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<IPartyType[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PartyType/{caseTypeId}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(IPartyType, response)), reject);
    });
  }
  /**
   *
   */
  static name(
    params: {
      /**  */
      caseTypeId: string;
      /**  */
      name?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyTypeCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PartyType/caseType/{caseTypeId}/name';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { name: params['name'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyTypeCode, response)), reject);
    });
  }
  /**
   *
   */
  static ePortalId(
    params: {
      /**  */
      caseTypeId: string;
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PartyTypeCode> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/PartyType/caseType/{caseTypeId}/ePortalID/{ePortalID}';
      url = url.replace('{caseTypeId}', params['caseTypeId'] + '');
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PartyTypeCode, response)), reject);
    });
  }
}

export class StateService {
  /**
   *
   */
  static state(options: IRequestOptions = {}): Promise<State[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/State';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(State, response)), reject);
    });
  }
  /**
   *
   */
  static state1(
    params: {
      /**  */
      name: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/State/{name}';
      url = url.replace('{name}', params['name'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(State, response)), reject);
    });
  }
  /**
   *
   */
  static state2(
    params: {
      /**  */
      ePortalId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<State> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/State/{ePortalID}';
      url = url.replace('{ePortalID}', params['ePortalId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(State, response)), reject);
    });
  }
}

export class CaseData {
  /**  */
  'ucn'?: string;

  /**  */
  'county'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'division'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'efmeCaseType'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  @Expose()
  'caseTypeRuleID'?: string;

  /**  */
  'caseSubType'?: string;

  /**  */
  @Expose()
  'caseSubTypeID'?: string;

  /**  */
  'courtCaseNumber'?: string;

  /**  */
  'clientCaseNumber'?: string;

  /**  */
  'caseTitle'?: string;

  /**  */
  @Expose()
  'year'?: number;

  /**  */
  'sequenceNumber'?: string;

  /**  */
  'clientMatterNumber'?: string;

  /**  */
  'isClassAction'?: boolean;

  /**  */
  'hasRelatedCases'?: boolean;

  /**  */
  'demandForJuryTrial'?: boolean;

  /**  */
  @Expose()
  'totalDefendants'?: number;

  /**  */
  @Expose()
  'causeOfActionCount'?: number;

  /**  */
  'isMonetary'?: boolean;

  /**  */
  'isNonMonetary'?: boolean;

  /**  */
  'isInjunctiveRelief'?: boolean;

  /**  */
  'isPunitive'?: boolean;

  /**  */
  'isComplexBusiness'?: boolean;

  /**  */
  @Expose()
  'subpoenaCount'?: number;

  /**  */
  @Expose()
  'smallClaimsAmount'?: number;

  /**  */
  'relatedCaseNumber'?: string;

  /**  */
  'claimAmount'?: string;

  /**  */
  'beginDateOfService'?: string;

  /**  */
  'endDateOfService'?: string;

  /**  */
  'dateOfAccident'?: string;

  /**  */
  'paymentToken'?: string;

  /**  */
  @Expose()
  'summonsCount'?: number;

  /**  */
  'cvv'?: string;

  /**  */
  'judicialOfficerID'?: string;

  /**  */
  @Expose()
  'damagesCodeID'?: string;

  constructor(data: undefined | any = {}) {
    this['ucn'] = data['ucn'];
    this['county'] = data['county'];
    this['countyID'] = data['countyID'];
    this['division'] = data['division'];
    this['divisionID'] = data['divisionID'];
    this['efmeCaseType'] = data['efmeCaseType'];
    this['caseType'] = data['caseType'];
    this['caseTypeID'] = data['caseTypeID'];
    this['caseTypeRuleID'] = data['caseTypeRuleID'];
    this['caseSubType'] = data['caseSubType'];
    this['caseSubTypeID'] = data['caseSubTypeID'];
    this['courtCaseNumber'] = data['courtCaseNumber'];
    this['clientCaseNumber'] = data['clientCaseNumber'];
    this['caseTitle'] = data['caseTitle'];
    this['year'] = data['year'];
    this['sequenceNumber'] = data['sequenceNumber'];
    this['clientMatterNumber'] = data['clientMatterNumber'];
    this['isClassAction'] = data['isClassAction'];
    this['hasRelatedCases'] = data['hasRelatedCases'];
    this['demandForJuryTrial'] = data['demandForJuryTrial'];
    this['totalDefendants'] = data['totalDefendants'];
    this['causeOfActionCount'] = data['causeOfActionCount'];
    this['isMonetary'] = data['isMonetary'];
    this['isNonMonetary'] = data['isNonMonetary'];
    this['isInjunctiveRelief'] = data['isInjunctiveRelief'];
    this['isPunitive'] = data['isPunitive'];
    this['isComplexBusiness'] = data['isComplexBusiness'];
    this['subpoenaCount'] = data['subpoenaCount'];
    this['smallClaimsAmount'] = data['smallClaimsAmount'];
    this['relatedCaseNumber'] = data['relatedCaseNumber'];
    this['claimAmount'] = data['claimAmount'];
    this['beginDateOfService'] = data['beginDateOfService'];
    this['endDateOfService'] = data['endDateOfService'];
    this['dateOfAccident'] = data['dateOfAccident'];
    this['paymentToken'] = data['paymentToken'];
    this['summonsCount'] = data['summonsCount'];
    this['cvv'] = data['cvv'];
    this['judicialOfficerID'] = data['judicialOfficerID'];
    this['damagesCodeID'] = data['damagesCodeID'];
  }

  public static validationModel = {};
}

export class CaseSubType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'code'?: string;

  /**  */
  'localNodeID'?: string;

  /**  */
  'useAsDefault'?: boolean;

  /**  */
  'active'?: boolean;

  /**  */
  'initial'?: boolean;

  /**  */
  @Expose()
  'caseTypeID': string;

  /**  */
  @Expose()
  'matchingRuleID'?: string;

  /**  */
  'caseType'?: CaseType;

  /**  */
  'caseTypePortalID'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['ePortalID'] = data['ePortalID'];
    this['name'] = data['name'];
    this['code'] = data['code'];
    this['localNodeID'] = data['localNodeID'];
    this['useAsDefault'] = data['useAsDefault'];
    this['active'] = data['active'];
    this['initial'] = data['initial'];
    this['caseTypeID'] = data['caseTypeID'];
    this['matchingRuleID'] = data['matchingRuleID'];
    this['caseType'] = data['caseType'];
    this['caseTypePortalID'] = data['caseTypePortalID'];
  }

  public static validationModel = {
    ePortalID: { maxLength: 255 },
    name: { maxLength: 255 },
    code: { maxLength: 255 },
    localNodeID: { maxLength: 255 },
    caseTypeID: { required: true }
  };
}

export class CaseType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'caseTypeCode'?: string;

  /**  */
  'localNodeID'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'description'?: string;

  /**  */
  'useAsDefault'?: boolean;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'initial'?: boolean;

  /**  */
  'caseTypeCategoryName'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'caseSubTypes'?: CaseSubType[];

  /**  */
  @Expose()
  'divisionID': string;

  /**  */
  'division'?: Division;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['caseTypeCode'] = data['caseTypeCode'];
    this['localNodeID'] = data['localNodeID'];
    this['ePortalID'] = data['ePortalID'];
    this['description'] = data['description'];
    this['useAsDefault'] = data['useAsDefault'];
    this['fee'] = data['fee'];
    this['initial'] = data['initial'];
    this['caseTypeCategoryName'] = data['caseTypeCategoryName'];
    this['active'] = data['active'];
    this['caseSubTypes'] = data['caseSubTypes'];
    this['divisionID'] = data['divisionID'];
    this['division'] = data['division'];
  }

  public static validationModel = {
    caseTypeCode: { maxLength: 255 },
    localNodeID: { maxLength: 255 },
    ePortalID: { maxLength: 255 },
    caseTypeCategoryName: { maxLength: 511 },
    divisionID: { required: true }
  };
}

export class CaseTypeCoverSheet {
  /**  */
  'caseType'?: string;

  /**  */
  'formFieldId'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'newCaseType'?: string;

  /**  */
  'templateName'?: string;

  constructor(data: undefined | any = {}) {
    this['caseType'] = data['caseType'];
    this['formFieldId'] = data['formFieldId'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['newCaseType'] = data['newCaseType'];
    this['templateName'] = data['templateName'];
  }

  public static validationModel = {};
}

export class CaseTypeCoverSheetDetailedResponse {
  /**  */
  'data'?: CaseTypeCoverSheet;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseTypeListDetailedResponse {
  /**  */
  'data'?: CaseType[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CaseTypeStaging {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'caseTypeCode'?: string;

  /**  */
  'localNodeID'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'description'?: string;

  /**  */
  'useAsDefault'?: boolean;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'initial'?: boolean;

  /**  */
  'caseTypeCategoryName'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  @Expose()
  'divisionID': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['caseTypeCode'] = data['caseTypeCode'];
    this['localNodeID'] = data['localNodeID'];
    this['ePortalID'] = data['ePortalID'];
    this['description'] = data['description'];
    this['useAsDefault'] = data['useAsDefault'];
    this['fee'] = data['fee'];
    this['initial'] = data['initial'];
    this['caseTypeCategoryName'] = data['caseTypeCategoryName'];
    this['active'] = data['active'];
    this['divisionID'] = data['divisionID'];
  }

  public static validationModel = {
    caseTypeCode: { maxLength: 255 },
    localNodeID: { maxLength: 255 },
    ePortalID: { maxLength: 255 },
    caseTypeCategoryName: { maxLength: 511 },
    divisionID: { required: true }
  };
}

export class CaseTypeStagingListDetailedResponse {
  /**  */
  'data'?: CaseTypeStaging[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class County {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'portalIdentifier'?: string;

  /**  */
  'initialFiling'?: boolean;

  /**  */
  'subsequentFiling'?: boolean;

  /**  */
  'allowNonIndexed'?: boolean;

  /**  */
  'allowedCardTypes'?: string;

  /**  */
  'protectedCaseTypes'?: string;

  /**  */
  'generateCoverSheet'?: boolean;

  /**  */
  'circuit'?: string;

  /**  */
  @Expose()
  'stateID': string;

  /**  */
  'state'?: State;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['portalIdentifier'] = data['portalIdentifier'];
    this['initialFiling'] = data['initialFiling'];
    this['subsequentFiling'] = data['subsequentFiling'];
    this['allowNonIndexed'] = data['allowNonIndexed'];
    this['allowedCardTypes'] = data['allowedCardTypes'];
    this['protectedCaseTypes'] = data['protectedCaseTypes'];
    this['generateCoverSheet'] = data['generateCoverSheet'];
    this['circuit'] = data['circuit'];
    this['stateID'] = data['stateID'];
    this['state'] = data['state'];
  }

  public static validationModel = {
    name: { maxLength: 127 },
    ePortalID: { maxLength: 31 },
    circuit: { maxLength: 255 },
    stateID: { required: true }
  };
}

export class CourtType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'code'?: string;

  /**  */
  'name'?: string;

  /**  */
  'division'?: Division;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['divisionID'] = data['divisionID'];
    this['code'] = data['code'];
    this['name'] = data['name'];
    this['division'] = data['division'];
  }

  public static validationModel = {};
}

export class CoversheetCaseSubType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'documentMappingValue'?: string;

  /**  */
  'ePortalValue'?: string;

  /**  */
  @Expose()
  'coversheetCaseTypeID'?: string;

  /**  */
  'coversheetCaseType'?: CoversheetCaseType;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['documentMappingValue'] = data['documentMappingValue'];
    this['ePortalValue'] = data['ePortalValue'];
    this['coversheetCaseTypeID'] = data['coversheetCaseTypeID'];
    this['coversheetCaseType'] = data['coversheetCaseType'];
  }

  public static validationModel = {};
}

export class CoversheetCaseType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'documentMappingValue'?: string;

  /**  */
  'ePortalValue'?: string;

  /**  */
  @Expose()
  'coversheetCourtTypeID'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['documentMappingValue'] = data['documentMappingValue'];
    this['ePortalValue'] = data['ePortalValue'];
    this['coversheetCourtTypeID'] = data['coversheetCourtTypeID'];
  }

  public static validationModel = {};
}

export class CoversheetCaseTypeDetailedResponse {
  /**  */
  'data'?: CoversheetCaseType;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class CoversheetCourtType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  @Expose()
  'minAmount'?: number;

  /**  */
  @Expose()
  'maxAmount'?: number;

  /**  */
  'templateFilename'?: string;

  /**  */
  @Expose()
  'stateID'?: string;

  /**  */
  'state'?: State;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['minAmount'] = data['minAmount'];
    this['maxAmount'] = data['maxAmount'];
    this['templateFilename'] = data['templateFilename'];
    this['stateID'] = data['stateID'];
    this['state'] = data['state'];
  }

  public static validationModel = {};
}

export class CrossReferenceCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'name'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  'isRequired'?: boolean;

  /**  */
  'isDefault'?: boolean;

  /**  */
  'validationRegex'?: string;

  /**  */
  'customValidationMessage'?: string;

  /**  */
  'caseType'?: CaseType;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['ePortalID'] = data['ePortalID'];
    this['name'] = data['name'];
    this['caseTypeID'] = data['caseTypeID'];
    this['isRequired'] = data['isRequired'];
    this['isDefault'] = data['isDefault'];
    this['validationRegex'] = data['validationRegex'];
    this['customValidationMessage'] = data['customValidationMessage'];
    this['caseType'] = data['caseType'];
  }

  public static validationModel = {
    ePortalID: { maxLength: 127 },
    name: { maxLength: 1023 },
    validationRegex: { maxLength: 127 },
    customValidationMessage: { maxLength: 1023 }
  };
}

export class DamageCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'division'?: Division;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['divisionID'] = data['divisionID'];
    this['division'] = data['division'];
  }

  public static validationModel = {
    name: { maxLength: 255 },
    ePortalID: { maxLength: 63 }
  };
}

export class Division {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'remedyInitial'?: boolean;

  /**  */
  'claimAmountInitial'?: boolean;

  /**  */
  'caseTypes'?: CaseType[];

  /**  */
  @Expose()
  'countyID': string;

  /**  */
  'county'?: County;

  /**  */
  'courtTypes'?: CourtType[];

  /**  */
  'claimAmountSupported'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['remedyInitial'] = data['remedyInitial'];
    this['claimAmountInitial'] = data['claimAmountInitial'];
    this['caseTypes'] = data['caseTypes'];
    this['countyID'] = data['countyID'];
    this['county'] = data['county'];
    this['courtTypes'] = data['courtTypes'];
    this['claimAmountSupported'] = data['claimAmountSupported'];
  }

  public static validationModel = {
    name: { maxLength: 128 },
    ePortalID: { maxLength: 31 },
    countyID: { required: true }
  };
}

export class DocumentType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'documentCategory'?: string;

  /**  */
  @Expose()
  'stateID'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'subType'?: boolean;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  @Expose()
  'feeAmount'?: number;

  /**  */
  'localNodeID'?: string;

  /**  */
  'county'?: County;

  /**  */
  'division'?: Division;

  /**  */
  'state'?: State;

  /**  */
  'caseType'?: CaseType;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['documentCategory'] = data['documentCategory'];
    this['stateID'] = data['stateID'];
    this['active'] = data['active'];
    this['subType'] = data['subType'];
    this['countyID'] = data['countyID'];
    this['divisionID'] = data['divisionID'];
    this['caseTypeID'] = data['caseTypeID'];
    this['feeAmount'] = data['feeAmount'];
    this['localNodeID'] = data['localNodeID'];
    this['county'] = data['county'];
    this['division'] = data['division'];
    this['state'] = data['state'];
    this['caseType'] = data['caseType'];
  }

  public static validationModel = {
    name: { maxLength: 255 },
    ePortalID: { maxLength: 63 },
    documentCategory: { maxLength: 63 },
    localNodeID: { maxLength: 15 }
  };
}

export class FilerCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'description'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'defaultValue'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'county'?: County;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['ePortalID'] = data['ePortalID'];
    this['defaultValue'] = data['defaultValue'];
    this['countyID'] = data['countyID'];
    this['county'] = data['county'];
  }

  public static validationModel = {
    description: { maxLength: 255 },
    ePortalID: { maxLength: 63 },
    defaultValue: { maxLength: 63 }
  };
}

export class FilingCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'name'?: string;

  /**  */
  'fee'?: string;

  /**  */
  'caseCategory'?: string;

  /**  */
  'caseType'?: string;

  /**  */
  'filingType'?: string;

  /**  */
  'efspCode'?: string;

  /**  */
  'forCourtUseOnly'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['countyID'] = data['countyID'];
    this['ePortalID'] = data['ePortalID'];
    this['name'] = data['name'];
    this['fee'] = data['fee'];
    this['caseCategory'] = data['caseCategory'];
    this['caseType'] = data['caseType'];
    this['filingType'] = data['filingType'];
    this['efspCode'] = data['efspCode'];
    this['forCourtUseOnly'] = data['forCourtUseOnly'];
  }

  public static validationModel = {
    ePortalID: { maxLength: 31 },
    name: { maxLength: 127 },
    fee: { maxLength: 15 },
    caseCategory: { maxLength: 50 },
    caseType: { maxLength: 50 },
    filingType: { maxLength: 50 },
    efspCode: { maxLength: 50 }
  };
}

export class FilingComponentCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  @Expose()
  'filingCodeID'?: string;

  /**  */
  'allowMultiple'?: boolean;

  /**  */
  'isRequired'?: boolean;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'filingCode'?: FilingCode;

  /**  */
  'county'?: County;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['filingCodeID'] = data['filingCodeID'];
    this['allowMultiple'] = data['allowMultiple'];
    this['isRequired'] = data['isRequired'];
    this['countyID'] = data['countyID'];
    this['filingCode'] = data['filingCode'];
    this['county'] = data['county'];
  }

  public static validationModel = {
    name: { maxLength: 255 },
    ePortalID: { maxLength: 63 }
  };
}

export class IDocumentType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'documentCategory'?: string;

  /**  */
  'active'?: boolean;

  /**  */
  'subType'?: boolean;

  /**  */
  @Expose()
  'feeAmount'?: number;

  /**  */
  'localNodeID'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['documentCategory'] = data['documentCategory'];
    this['active'] = data['active'];
    this['subType'] = data['subType'];
    this['feeAmount'] = data['feeAmount'];
    this['localNodeID'] = data['localNodeID'];
  }

  public static validationModel = {};
}

export class IFee {
  /**  */
  'description'?: string;

  /**  */
  @Expose()
  'amount'?: number;

  /**  */
  @Expose()
  'quantity'?: number;

  /**  */
  'ePortalID'?: string;

  constructor(data: undefined | any = {}) {
    this['description'] = data['description'];
    this['amount'] = data['amount'];
    this['quantity'] = data['quantity'];
    this['ePortalID'] = data['ePortalID'];
  }

  public static validationModel = {};
}

export class IPartyType {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'description'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'isForNewCases'?: boolean;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'isRequired'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['description'] = data['description'];
    this['ePortalID'] = data['ePortalID'];
    this['isForNewCases'] = data['isForNewCases'];
    this['fee'] = data['fee'];
    this['isRequired'] = data['isRequired'];
  }

  public static validationModel = {};
}

export class JudicialOfficer {
  /**  */
  'name'?: string;

  /**  */
  'division'?: string;

  /**  */
  'ePortalID'?: string;

  constructor(data: undefined | any = {}) {
    this['name'] = data['name'];
    this['division'] = data['division'];
    this['ePortalID'] = data['ePortalID'];
  }

  public static validationModel = {};
}

export class JudicialOfficerDetailedResponse {
  /**  */
  'data'?: JudicialOfficer;

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class JudicialOfficerListDetailedResponse {
  /**  */
  'data'?: JudicialOfficer[];

  /**  */
  'successful'?: boolean;

  /**  */
  'errorMessage'?: string;

  constructor(data: undefined | any = {}) {
    this['data'] = data['data'];
    this['successful'] = data['successful'];
    this['errorMessage'] = data['errorMessage'];
  }

  public static validationModel = {};
}

export class MappedCoversheetRequest {
  /**  */
  'caseData'?: CaseData;

  /**  */
  @Expose()
  'claimAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['caseData'] = data['caseData'];
    this['claimAmount'] = data['claimAmount'];
  }

  public static validationModel = {};
}

export class OptionalServicesCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  @Expose()
  'filingCodeID'?: string;

  /**  */
  'feeDescription'?: string;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  'multipler'?: boolean;

  /**  */
  @Expose()
  'displayOrder'?: number;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'county'?: County;

  /**  */
  'filingCode'?: FilingCode;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['filingCodeID'] = data['filingCodeID'];
    this['feeDescription'] = data['feeDescription'];
    this['fee'] = data['fee'];
    this['multipler'] = data['multipler'];
    this['displayOrder'] = data['displayOrder'];
    this['countyID'] = data['countyID'];
    this['county'] = data['county'];
    this['filingCode'] = data['filingCode'];
  }

  public static validationModel = {
    name: { maxLength: 255 },
    ePortalID: { maxLength: 63 },
    feeDescription: { maxLength: 255 }
  };
}

export class PartyTypeCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'description'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  'isForNewCases'?: boolean;

  /**  */
  @Expose()
  'fee'?: number;

  /**  */
  @Expose()
  'countyID'?: string;

  /**  */
  'isRequired'?: boolean;

  /**  */
  @Expose()
  'caseTypeID'?: string;

  /**  */
  'county'?: County;

  /**  */
  'caseType'?: CaseType;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['description'] = data['description'];
    this['ePortalID'] = data['ePortalID'];
    this['isForNewCases'] = data['isForNewCases'];
    this['fee'] = data['fee'];
    this['countyID'] = data['countyID'];
    this['isRequired'] = data['isRequired'];
    this['caseTypeID'] = data['caseTypeID'];
    this['county'] = data['county'];
    this['caseType'] = data['caseType'];
    this['name'] = data['name'];
  }

  public static validationModel = {
    description: { maxLength: 255 },
    ePortalID: { maxLength: 63 }
  };
}

export class RemedyCode {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'ePortalID'?: string;

  /**  */
  @Expose()
  'divisionID'?: string;

  /**  */
  'division'?: Division;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['ePortalID'] = data['ePortalID'];
    this['divisionID'] = data['divisionID'];
    this['division'] = data['division'];
  }

  public static validationModel = {
    name: { maxLength: 63 }
  };
}

export class State {
  /**  */
  @Expose()
  'id'?: string;

  /**  */
  'name'?: string;

  /**  */
  'abbreviation'?: string;

  /**  */
  'saveDocumentTypes'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['abbreviation'] = data['abbreviation'];
    this['saveDocumentTypes'] = data['saveDocumentTypes'];
  }

  public static validationModel = {};
}
