import { useState, useEffect, useCallback } from "react";
import Select, { ActionMeta } from "react-select";
import { ClientService } from "../../services/ClientService";
import { authenticationService } from "../../services/authentication.service";

type NameValueOptionType = { label: string; value: string };

export default function AttorneySelect({
    onAttorneySelected,
    onFirmSelected,
}: {
    onAttorneySelected: (id: string, name: string) => void;
    onFirmSelected: (id: string, name: string) => void;
}) {
    let options: NameValueOptionType[] = [];

    const [firmId, setFirmId] = useState("");
    const [firmOptions, setFirmOptions] = useState(options);

    const [attorneyId, setAttorneyId] = useState("");
    const [attorneyOptions, setAttorneyOptions] = useState(options);

    useEffect(() => {
        ClientService.myFirms().then(
            (resp) => {
                if (resp.successful && resp.data) {
                    options = resp.data.map((v, i) => {
                        return { value: v.id!, label: v.name! };
                    });
                    setFirmOptions(options);
                    if (options.length == 1) {
                        setFirmId(options[0].value);
                    }
                } else {
                    //TODO: Show error
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    useEffect(() => {
        if (firmId != "") {
            ClientService.firmAttorneys({ firmId: firmId! }).then(
                (resp) => {
                    if (resp.successful && resp.data) {
                        options = resp.data.map((v, i) => {
                            return {
                                value: v.id!,
                                label: `${v.lastName!}, ${v.firstName!}`,
                            };
                        });

                        setAttorneyOptions(options);
                        if (options.length == 1) {
                            onAttorneySelected(
                                options[0].value,
                                options[0].label
                            );
                        }
                    } else {
                        //TODO: Show error
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [firmId]);

    const filterOption = (
        option: NameValueOptionType,
        inputValue: string
    ): boolean =>
        (
            option.label
                .toString()
                .toLowerCase()
                .match(inputValue.toLowerCase()) || []
        ).length > 0;

    const onFirmChange = (
        value: NameValueOptionType | null,
        actionMeta: ActionMeta<NameValueOptionType>
    ) => {
        setFirmId(value!.value);
        setAttorneyId("");
        onFirmSelected(value!.value, value!.label);
    };
    const onAttorneyChange = (
        value: NameValueOptionType | null,
        actionMeta: ActionMeta<NameValueOptionType>
    ) => {
        setAttorneyId(value!.value);
        onAttorneySelected(value!.value, value!.label);
    };

    return (
        <div className="row">
            <div className="col-6">
                <label htmlFor="selFirm" className="form-label">
                    Firm
                </label>
                {firmOptions.length === 1 ? (
                    <Select
                        id="selFirm"
                        name="selFirm"
                        value={firmOptions.filter((c) => c.value === firmId)}
                        options={firmOptions}
                        onChange={onFirmChange}
                    />
                ) : (
                    <Select
                        id="selFirm"
                        name="selFirm"
                        value={firmOptions.filter((c) => c.value === firmId)}
                        filterOption={filterOption}
                        options={firmOptions}
                        onChange={onFirmChange}
                    />
                )}
            </div>
            <div className="col-6">
                <label htmlFor="selAttorney" className="form-label">
                    Attorney Filing on Behalf Of
                </label>
                {attorneyOptions.length === 1 ? (
                    <Select
                        id="selAttorney"
                        name="selAttorney"
                        value={attorneyOptions[0]}
                        options={attorneyOptions}
                        onChange={onAttorneyChange}
                    />
                ) : (
                    <Select
                        id="selAttorney"
                        name="selAttorney"
                        value={attorneyOptions.filter(
                            (c) => c.value === attorneyId
                        )}
                        filterOption={filterOption}
                        options={attorneyOptions}
                        onChange={onAttorneyChange}
                    />
                )}
            </div>
        </div>
    );
}
