import { useState, useRef } from "react";
import { Form, Spinner } from "react-bootstrap";
import { authenticationService } from "../services/authentication.service";
import { UserService } from "../services/ClientService";

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleSubmit = (event: React.FormEvent) => {
        if (loading === false) {
            UserService.forgotPassword({ body: { userName: username } }).then(
                (resp) => {
                    if (resp.successful) {
                        window.location.href = "/reset-password";
                    } else {
                        setError(resp.message ?? "");
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
            setLoading(true);
        }
        event.preventDefault();
        return false;
    };

    return (
        <div className="loading auth-fluid-pages pb-0">
            <div className="auth-fluid">
                <div className="auth-fluid-right">
                    <div className="auth-user-testimonial">
                        <h3 className="mb-3 text-white">
                            EFILEMADEEASY IS AN INCREDIBLY EASY EFILE SERVICE.
                        </h3>
                        <p className="lead fw-normal">
                            <i className="mdi mdi-format-quote-open"></i>{" "}
                            eFileMadeEasy is a unique, one of a kind service,
                            that eFiles with the Florida E-Portal and eServes
                            your service list with the required service by
                            email. With a few mouse clicks your document is
                            completely prepared, filed and served. No more back
                            and forth between programs, selecting files,
                            scanning documents multiple times, typing in email
                            addresses, and the other time wasting steps required
                            to eFile. Save time, reduce mistakes, and do what
                            you do best practice law.
                            <i className="mdi mdi-format-quote-close"></i>
                        </p>
                        <h5 className="text-white">- eFileMadeEasy</h5>
                    </div>
                </div>
                <div className="auth-fluid-form-box">
                    <div className="align-items-center d-flex h-100">
                        <div className="card-body">
                            <div className="auth-brand text-center text-lg-start">
                                <div className="auth-logo">
                                    <a
                                        href="index.html"
                                        className="logo logo-dark text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                width="256"
                                                src="https://www.efilemadeeasy.com/wp-content/uploads/2019/06/headlogo.png"
                                                alt="eFileMadeEasy"
                                            />
                                        </span>
                                    </a>
                                    <a
                                        href="index.html"
                                        className="logo logo-light text-center"
                                    >
                                        <span className="logo-lg">
                                            <img
                                                src="../assets/images/logo-light.png"
                                                alt=""
                                                height="22"
                                            />
                                        </span>
                                    </a>
                                </div>
                            </div>
                            <h4 className="mt-0">Sign In</h4>
                            <p className="text-muted mb-4">
                                Enter your username below and a link to reset
                                your password will be sent to you.
                            </p>
                            <Form action="#" onSubmit={handleSubmit}>
                                <div className="mb-2">
                                    <label
                                        htmlFor="username"
                                        className="form-label"
                                    >
                                        Username
                                    </label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="username"
                                        required={true}
                                        placeholder="Enter your username"
                                        onChange={(event: React.FormEvent) => {
                                            const usernameInput =
                                                event.currentTarget as HTMLInputElement;
                                            setUsername(usernameInput.value);
                                        }}
                                    />
                                </div>

                                <div className="d-grid text-center">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        {loading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                &nbsp; Loading...
                                            </>
                                        )}
                                        {!loading && <>Log In</>}
                                    </button>
                                </div>
                                <div className="d-grid text-center">
                                    {error}
                                </div>
                            </Form>
                            <footer className="footer footer-alt">
                                <p className="text-muted">
                                    Don't have an account?{" "}
                                    <a
                                        href="/register"
                                        className="text-primary fw-medium ms-1"
                                    >
                                        Send Verification Code
                                    </a>
                                </p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
