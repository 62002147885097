import { useEffect } from "react";
import { useState } from "react";
import * as APIModels from "../models";
import { Modal, Button } from "react-bootstrap";
//import { PortalCredentials } from "../../../../models";
//import eFile from "../models/index";

//import { StringDetailedResponse } from "../../../../models/swagger/generated-submission-service";
//import { PortalConfigurationService } from "../../../../services/ClientService";

export type ConfirmationModalProps = {
    show: boolean;
    selectedFiles?: APIModels.FileInfo[];
    attorneyName: string;
    onCancel: () => void;
    onConfirm: () => void;
};

export default function ConfirmationModal({
    show,
    selectedFiles,
    attorneyName,
    onCancel,
    onConfirm,
}: ConfirmationModalProps) {
    useEffect(() => {
        /*
        if (show === true) {
            PortalConfigurationService.getCredentials({
                portalConfigId: portalConfigId ?? "",
            }).then(
                (resp) => {
                    if (resp.successful === true) {
                        setData(resp.data as PortalCredentials);
                    } else {
                        console.log("Error");
                    }
                },
                (error: any) => {
                    console.log(error);
                }
            );
        }
        */
    }, [show]);

    const closeWindow = () => {
        // setData([] as PortalCredentials);
        onCancel();
    };

    const confirm = () => {
        // setData([] as PortalCredentials);
        onConfirm();
    };

    /*
    1                            
    {upload.filenames?.map((v) => (
                                    <li>{v}</li>
                                ))}

                                
    */

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Summary
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Please confirm the information below is correct before
                    submitting.
                </p>
                <div className="row">
                    <div className="col-6">
                        <div className="mb-2">
                            <label
                                htmlFor="upoadFilename"
                                className="form-label"
                            >
                                Filenames
                            </label>
                            <ul>
                                <p>
                                    {" "}
                                    {selectedFiles?.map((v) => (
                                        <li>{v.filename}</li>
                                    ))}
                                </p>
                            </ul>
                        </div>
                        <div className="mb-2">
                            <label
                                htmlFor="attorneyName"
                                className="form-label"
                            >
                                Attorney Filing on Behalf Of
                            </label>
                            <input
                                type="name"
                                className="form-control"
                                id="attorneyName"
                                value={attorneyName}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        The filing attorney has the responsibility to ensure
                        that any confidential information in the filled
                        documents compiles with all state rules,statues, and
                        codes. By clicking the Submit button below you are
                        acknowledging that you have redacted or prepared the
                        attached document(s) in accordance with all state
                        confidentiality requirements. eReturnsMadeEasy does not
                        check or redact any information.
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    className="btn-danger"
                    onClick={closeWindow}
                >
                    Cancel
                </Button>
                <Button className="btn-primary " onClick={confirm}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
