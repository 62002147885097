import {
    useNavigate,
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import RegisterPage from "../pages/RegisterPage";
import VerifyNewUserPage from "../pages/VerifyNewUserPage";
import ConfirmationPage from "../pages/ConfirmationPage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import ResetPasswordLoginPage from "../pages/ResetPasswordLoginPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";

export default function UnAuthLayout() {
    const history = useNavigate();
    const pathname = window.location.pathname;
    if (pathname === "/") history("/login");

    return (
        <Routes>
            <Route path="/verify" element={<VerifyNewUserPage />}></Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route path="/register" element={<RegisterPage />}></Route>
            <Route
                path="/register-success"
                element={<ConfirmationPage />}
            ></Route>
            <Route
                path="/new-password-login"
                element={<ResetPasswordLoginPage />}
            ></Route>
            <Route
                path="/forgot-password"
                element={<ForgotPasswordPage />}
            ></Route>
            <Route
                path="/reset-password"
                element={<ResetPasswordPage />}
            ></Route>
            <Route path="/" element={<Navigate to="/login" />}></Route>
        </Routes>
    );
}
