export const Endpoints = {
    // "https://localhost:44392",
    ClientServices:
        "https://pf890cf6r9.execute-api.us-east-1.amazonaws.com/Prod", //"https://localhost:44392", //"https://localhost:44392/", //https://pf890cf6r9.execute-api.us-east-1.amazonaws.com/Prod/,

    SubmssionServices:
        "https://8zn1p5xk6i.execute-api.us-east-1.amazonaws.com/Prod",
    //"https://8zn1p5xk6i.execute-api.us-east-1.amazonaws.com/Prod", //"https://localhost:44321",
    DataServices: "https://psk3wquggl.execute-api.us-east-1.amazonaws.com/Prod", //"https://localhost:44301", //https://psk3wquggl.execute-api.us-east-1.amazonaws.com/Prod
    CaseRecords: "https://chy1if65v6.execute-api.us-east-1.amazonaws.com/Prod", // "https://localhost:44315/", //https://chy1if65v6.execute-api.us-east-1.amazonaws.com/Prod
    FileManager: "https://0bfaib1c51.execute-api.us-east-1.amazonaws.com/Prod", //"https://pwys7gjceh.execute-api.us-east-1.amazonaws.com/Prod", //"https://0bfaib1c51.execute-api.us-east-1.amazonaws.com/Prod", //"https://43bqx0uhk6.execute-api.us-east-1.amazonaws.com/Prod",
    EReturnService:
        "https://rzf1275eci.execute-api.us-east-1.amazonaws.com/Prod", //"https://localhost:44346",
};
